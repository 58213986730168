import React from 'react';
import { InterimReportsDownloadPageRoutingMap } from './interim-reports-download.map';
import { PageHeader } from '@keplerco/core';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { PDFReport } from '../../../components/general/pdf-report/pdf-report';

export function InterimReportDownloadsPage(): JSX.Element {
  return (
    <InterimReportsDownloadPageRoutingMap>
      {() => {
        return (
          <PageLoaderLayer path={PagePath.analysisInterimDownload}>
            <div className="stack wrapper" style={{ alignItems: 'flex-start' }}>
              <PageHeader
                breadcrumbs={[
                  {
                    name: 'Skills analysis',
                    url: `${PagePath.analysisBase}`,
                  },
                  {
                    name: 'Download PDF Report',
                    url: `${PagePath.analysisBase}${PagePath.analysisInterimDownload}`,
                  },
                ]}
              />

              <h1 className="heading1" style={{ marginTop: 30 }}>
                Download PDF Report
              </h1>
              <h6 className="subtitle" style={{ marginBottom: 30 }}>
                If you want to download a previous report from your learning, you can do that here without having to finish this year's assessment
              </h6>

              <PDFReport path={PagePath.analysisInterimDownload} />
            </div>
          </PageLoaderLayer>
        );
      }}
    </InterimReportsDownloadPageRoutingMap>
  );
}
