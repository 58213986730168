import { ListIcon, MapIcon, PageHeader, Panel, ToggleButtonGroup } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { FetchType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../overmind';
import { LearnerSkillDetail } from '../../../models/view/courses';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { CoreSkillsMap } from './core-skills-map';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { LearningJourneyView } from '../../../enums/learning-journey-view';
import { SkillLevelsExplainerPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/skill-levels-explainer-panel/skill-levels-explainer-panel.layout';
import { useDataSyncReaction } from '../../../library/hooks/useDataSyncReaction';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import styles from './core-skills-map.module.css';

export function CoreSkillsMapPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const navigate = useKeplerNavigate();

  const [skills, setSkills] = useState<LearnerSkillDetail[]>();
  const [open, setOpen] = useState<boolean>(false);

  async function getData(loader = false) {
    if (loader) actions.startLoader({ path: PagePath.learningJourneyCoreSkillsMap, type: FetchType.PageFetching });
    const response = await actions.getCoursesLearnerSkills();
    setSkills(response);
    if (loader) actions.stopLoader(PagePath.learningJourneyCoreSkillsMap);
  }

  useEffect(() => {
    getData(true);
  }, []);

  useDataSyncReaction(async () => {
    getData();
  });

  async function onClickViewType(viewType: LearningJourneyView) {
    actions.startLoader({ path: PagePath.learningJourneyCoreSkillsMap, type: FetchType.PageFetching });
    actions.setLearningJourneyView(viewType);
    navigate(viewType === LearningJourneyView.Map ? `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}` : PagePath.learningJourneyBase);
    actions.stopLoader(PagePath.learningJourneyCoreSkillsMap);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningJourneyCoreSkillsMap} background={'hsla(224, 52%, 10%, 1)'}>
        <div className={styles.page}>
          <div className={styles.header} style={{ position: `${!skills?.length ? 'relative' : 'absolute'}` }}>
            <PageHeader
              breadcrumbs={[
                {
                  name: 'Learning Journey',
                  url: PagePath.learningJourneyBase,
                },
                {
                  name: 'Core Skills Map',
                  url: `${PagePath.learningJourneyBase}${PagePath.learningJourneyCoreSkillsMap}`,
                },
              ]}
              title="Core Skills Map"
              forceDark
              onClickHelp={companyVariables.useLevels ? () => setOpen(true) : undefined}
              type="actions"
            >
              <div className="headerActions">
                <ToggleButtonGroup
                  buttons={[
                    { label: LearningJourneyView.Map, icon: <MapIcon /> },
                    { label: LearningJourneyView.List, icon: <ListIcon /> },
                  ]}
                  defaultButtonLabel={LearningJourneyView.Map}
                  onClick={buttonLabel => onClickViewType(buttonLabel as LearningJourneyView)}
                />
              </div>
            </PageHeader>
          </div>

          {!skills?.length ? <EmptyState title="No skills yet" badgeUrl={themedAssetUrl('graphics/empty-state-clouds.graphic.svg')} color="contrast-text" /> : <CoreSkillsMap skills={skills} />}
        </div>
      </PageLoaderLayer>

      {companyVariables.useLevels && (
        <Panel open={open} onClose={() => setOpen(false)}>
          <SkillLevelsExplainerPanelLayout onClose={() => setOpen(false)} />
        </Panel>
      )}
    </React.Fragment>
  );
}
