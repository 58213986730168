/* eslint-disable indent */
import React, { useState } from 'react';
import { TagInput } from '../../../../../../components/inputs/tags/tag-input';
import { FocusPanelLoaderLayer } from '../../../../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { TagType } from '../../../../../../enums/tag';
import { BaseSearchRequest } from '../../../../../../models';
import { TagListItem, TagAssigneeRequest, Assignee } from '../../../../../../models/tag';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { IManagePersonTagsFocusPanelProps } from '../manage-person-panels.models';
import { PagePath } from '../../../../../../navigation/navigation.enums';
import { Anchor, PanelHeader } from '@keplerco/core';

export function ManagePersonTagsFocusPanel(props: IManagePersonTagsFocusPanelProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const [search, setSearch] = useState('');

  async function onClickRemoveTagHandler(tagName: string) {
    const assignees: TagAssigneeRequest[] = [
      {
        entitySlug: props.personSlug,
        tagType: TagType.User,
      },
    ];

    await actions.removeAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }
  function getTagAssignees(): Assignee[] {
    if (!props.personSlug) return [];
    return [
      {
        entitySlug: props.personSlug,
        tagType: TagType.User,
      },
    ];
  }

  async function onClickCreateTagHandler(tagName: string, assignees: Assignee[], source: string) {
    if (source === 'input') {
      tagName = search;

      assignees = !props.personSlug
        ? []
        : [
            {
              entitySlug: props.personSlug,
              tagType: TagType.User,
            },
          ];
    }

    await actions.addAssignees({ tagName, assignees, companySlug: companyVariables.slug! });
  }

  async function searchUserTags(query: string): Promise<TagListItem[]> {
    setSearch(query);

    const searchTagParams: BaseSearchRequest = {
      page: 1,
      pageSize: 15,
      search: query,
      sortAscending: true,
      sortField: 0,
      companySlug: companyVariables.slug!,
    };

    const paginatedResult = await actions.searchTags(searchTagParams);

    if (paginatedResult && paginatedResult.tags) {
      return paginatedResult.tags;
    }

    return [];
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationPeople}>
      <div className="panelContent">
        <PanelHeader title="Tag Person" divider />

        <div>
          <TagInput getAssignees={getTagAssignees} onRemoveTag={onClickRemoveTagHandler} onCreateTag={onClickCreateTagHandler} initialTags={props.tags} onTagChange={props.setTags} searchTags={searchUserTags} />
        </div>

        <footer className="panelFooter">
          <Anchor onClick={props.onCancel}>Cancel</Anchor>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}
