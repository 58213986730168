import React from 'react';
import { useAppState } from '../../../overmind';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { CompletionStatus } from '../../../enums/completion-status';
import { TechnicalSkillsChips, technicalSkillStatus } from './landing.helpers';
import { PagePath } from '../../../navigation/navigation.enums';
import { AssessmentType } from '../../../enums/assessment-type';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { Chip, ChipWrapper } from '@keplerco/core';
import styles from './landing.module.css';
import KeplerNavlink from '../../../navigation/guards/kepler-navlink';

export function AnalysisLandingPage(): JSX.Element {
  const subdomain = getSubdomain();
  const { skillAssessmentConfig } = useAppState();

  const technicalStatus = skillAssessmentConfig?.selfReviewCompletionStatus;
  const behavioralStatus = skillAssessmentConfig?.behavioralCompletionStatus;
  const peerReviewStatus = skillAssessmentConfig?.selfPeerReviewCompletionStatus;
  const careerPathSelectionStatus = skillAssessmentConfig?.careerPathSelectionCompletionStatus;
  const careerPathsStatus = skillAssessmentConfig?.careerPathCompletionStatus;

  function PeerEndorsementCard() {
    const linkTo =
      skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired
        ? `${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`
        : technicalStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed
          ? `${PagePath.analysisBase}`
          : `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`;

    return (
      <KeplerNavlink to={linkTo} className={classNames(styles.card, { done: technicalStatus === CompletionStatus.Completed && peerReviewStatus === CompletionStatus.Completed })} style={{ borderLeft: 'solid 4px var(--blue)' }}>
        <ChipWrapper>
          <TechnicalSkillsChips hasPeerReview={peerReviewStatus === CompletionStatus.NotStarted} peerReviewStatus={skillAssessmentConfig?.selfPeerReviewCompletionStatus} technicalStatus={technicalStatus} />
        </ChipWrapper>
        <h3 className="heading3">Role-based skills analysis</h3>
        <span className="caption2">{technicalSkillStatus(technicalStatus, true, peerReviewStatus)}</span>
      </KeplerNavlink>
    );
  }

  function QuestionnaireCard() {
    return (
      <KeplerNavlink to={`${PagePath.analysisBase}${PagePath.analysisBehavioural}`} className={classNames(styles.card, { [styles.completed]: behavioralStatus === CompletionStatus.Completed })} style={{ borderLeft: 'solid 4px var(--grape)' }}>
        <ChipWrapper>
          {behavioralStatus === CompletionStatus.NotStarted && <Chip label="Not Started" variant="tiny" backgroundColour="secondary" colour="background" />}
          {behavioralStatus === CompletionStatus.InProgress && <Chip label="In Progress" variant="tiny" backgroundColour="borders" colour="text" />}
          {behavioralStatus === CompletionStatus.Completed && <Chip label="Complete" variant="tiny" backgroundColour="apple" colour="contrast-text" />}
        </ChipWrapper>
        <h3 className="heading3">Behavioural skills analysis</h3>
        <span className="caption2">These skills determine how well you interact with others.</span>
      </KeplerNavlink>
    );
  }

  function FieldsOfPracticeCard() {
    return (
      <React.Fragment>
        <KeplerNavlink to={`${PagePath.analysisBase}${PagePath.analysisCareerPaths}`} className={classNames(styles.card, { [styles.completed]: careerPathSelectionStatus === CompletionStatus.Completed })} style={{ borderLeft: 'solid 4px var(--lime)' }}>
          <ChipWrapper>
            <Chip
              label={careerPathSelectionStatus === CompletionStatus.Completed ? 'Completed' : 'Not Started'}
              variant="tiny"
              backgroundColour={careerPathSelectionStatus === CompletionStatus.Completed ? 'g' : 'default'}
              colour={careerPathSelectionStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
            />
          </ChipWrapper>
          <h3 className="heading3">Choose {subdomain.includes('lpi') ? 'your role' : 'a career pathway'}</h3>
          <span className="caption2">Let us know where you are heading with your career</span>
        </KeplerNavlink>

        {careerPathSelectionStatus === CompletionStatus.Completed && (
          <KeplerNavlink
            to={`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`}
            className={classNames(styles.card, { [styles.completed]: careerPathSelectionStatus === CompletionStatus.Completed && careerPathsStatus === CompletionStatus.Completed })}
            style={{ borderLeft: 'solid 4px var(--yellow)' }}
          >
            <ChipWrapper>
              <Chip
                label={careerPathsStatus === CompletionStatus.Completed ? 'Completed' : careerPathsStatus === CompletionStatus.InProgress ? 'In Progress' : 'Not Started'}
                variant="tiny"
                backgroundColour={careerPathsStatus === CompletionStatus.Completed ? 'g' : careerPathsStatus === CompletionStatus.InProgress ? 'borders' : 'default'}
                colour={careerPathsStatus === CompletionStatus.Completed ? 'contrast-text' : 'text'}
              />
            </ChipWrapper>
            <h3 className="heading3">{skillAssessmentConfig?.assessmentName ?? 'Skills assessment'}</h3>
            <span className="caption2">Rate yourself based on your career skills.</span>
          </KeplerNavlink>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.image}>
        <object data={themedAssetUrl('graphics/pie-graph.graphic.svg')} type="image/svg+xml">
          <img src={themedAssetUrl('graphics/pie-graph.graphic.svg')} alt="graph" />
        </object>
      </div>

      <div className={styles.content}>
        <ChipWrapper>
          <Chip label={subdomain.includes('lpi') ? 'Capability map' : 'Skills analysis'} backgroundColour="primary" colour="contrast-text" />
        </ChipWrapper>

        <h1 className="heading1">{subdomain.includes('lpi') ? 'Rate your skills' : 'Identify your strengths'}</h1>

        <h6 className="subtitle">Your analysis will give us the insights we need to build your personalised development plan.</h6>

        <div className={styles.cards}>
          {skillAssessmentConfig?.assessmentType === AssessmentType.PeerEndorsement && <PeerEndorsementCard />}

          {skillAssessmentConfig?.assessmentType === AssessmentType.Questionnaire && <QuestionnaireCard />}

          {skillAssessmentConfig?.assessmentType === AssessmentType.FieldsOfPractice && <FieldsOfPracticeCard />}
        </div>
      </div>
    </div>
  );
}
