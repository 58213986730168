import React, { useEffect, useState } from 'react';
import { ExportFileIcon } from '../../../design/icons/export-file.icon';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { PDFReportModalLayout } from './pdf-report-modal.layout';
import { deriveDateRange } from '../../../overmind/derived-props/date-range';
import * as base from '../../../overmind/actions/base';
import { FetchType } from '../../../enums';
import { KeyValue } from '../../../models';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { Button, Modal } from '@keplerco/core';
import { DaterangeOutput } from '../../inputs/daterange/daterange.models';
import { Daterange } from '../../inputs/daterange/daterange.component';
import { IPdfReportProps } from './pdf-report.models';
import { Profile } from '../../../models/profile';
import { GlobalDateRangeFilter } from '../../../overmind/actions/functions/daterange';

function toLocalISOString(date: Date | undefined): string | undefined {
  if (!date) return undefined;
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split('Z')[0];
}

export function PDFReport({ path }: IPdfReportProps) {
  const actions = useAppActions();
  const { user } = useAppState();

  const isCPD = getSubdomain().includes('cpd');
  const isLPI = getSubdomain().includes('lpi');

  const [pdfReports, setPDFReports] = useState<KeyValue[]>();
  const [profile, setProfile] = useState<Profile>();
  const [dateRange, setDateRange] = useState<GlobalDateRangeFilter>();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    async function getData() {
      setPDFReports(await actions.getPDFReports());
      setProfile(await actions.getProfile());
    }

    getData();
  }, []);

  useEffect(() => {
    if (!dateRange) return;
    if (isCPD && !profile?.cpdRegistrationNumber) return setOpen(true);
    downloadReport();
  }, [dateRange]);

  function onDaterangeChangeHandler(dateRangeOutput: DaterangeOutput) {
    setDateRange(deriveDateRange(dateRangeOutput));
  }

  function onClickSubmit() {
    downloadReport();
    setOpen(false);
  }

  function downloadReport() {
    actions.startLoader({ path: path, type: FetchType.Sending });
    const url = base.apiURL(`pdfreport`, `${isLPI ? `progress-report` : `download-pdf`}${base.params({ startDate: toLocalISOString(dateRange?.from || undefined), endDate: toLocalISOString(dateRange?.to || undefined) })}`);
    window.location.href = url;
    setTimeout(() => actions.stopLoader(path), 1000);
  }

  return (
    <React.Fragment>
      {user?.downloadLearningProgressPdf && (
        <React.Fragment>
          {isCPD || isLPI ? (
            <Daterange title="Select a period:" applyButtonText="Download" onDaterangeChange={onDaterangeChangeHandler}>
              <Button type="button" square theme="dark">
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <ExportFileIcon tone="primary" />
                  {isCPD ? `CPD Report` : `Progress Report`}
                </div>
              </Button>
            </Daterange>
          ) : (
            <React.Fragment>
              {pdfReports?.map(report => (
                <Daterange key={report.slug} title="Select a period:" applyButtonText="Download" onDaterangeChange={onDaterangeChangeHandler}>
                  <Button type="button" square theme={path === PagePath.dashboard ? 'link-text' : 'dark'}>
                    <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                      {path !== PagePath.dashboard && <ExportFileIcon tone="primary" />}
                      {report.name}
                    </div>
                  </Button>
                </Daterange>
              ))}
            </React.Fragment>
          )}

          <Modal open={open} onClose={() => setOpen(false)}>
            {open && !!dateRange?.to && <PDFReportModalLayout path={path} from={dateRange.from?.toDateString()} to={dateRange.to?.toDateString()} profile={profile} onClick={onClickSubmit} />}
          </Modal>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
