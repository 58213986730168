import React from 'react';
import { sortByUrgencyIndication } from '../your-skills.helpers';
import { GridItemLayout, GridLayout, kebabCase } from '@keplerco/core';
import { IYourSkillsModalProps } from './your-skills-modal.models';
import { EntitySkillScoresCard } from '../../../components/cards/entity-skill-scores-card/entity-skill-scores-card';
import { PagePath } from '../../../navigation/navigation.enums';

export function YourSkillsModalLayout({ skillSubType }: IYourSkillsModalProps): JSX.Element {
  return (
    <div className="modalContent">
      <header style={{ marginBottom: 30 }}>
        <h2 className="heading2" style={{ textAlign: 'center' }} id={kebabCase(`${skillSubType.name}-display-modal`)}>
          {skillSubType.name}
        </h2>

        <h6 className="subtitle">Here is your skills score breakdown that demonstrates which skills to focus on first in your personalised learning journey.</h6>
      </header>

      <GridLayout columnCount={3}>
        {skillSubType.skills
          .sort((a, b) => (!!a.urgencyIndication && !!b.urgencyIndication ? sortByUrgencyIndication(a.urgencyIndication, b.urgencyIndication) : (a.score?.percentage ?? 0) - (b.score?.percentage ?? 0)))
          .map(skill => {
            return (
              <GridItemLayout key={skill.slug}>
                <EntitySkillScoresCard path={PagePath.yourSkills} name={skill.name} overallScore={skill.score} comparisonScore={skill.comparisonScore} urgencyIndication={skill.urgencyIndication} />
              </GridItemLayout>
            );
          })}
      </GridLayout>
    </div>
  );
}
