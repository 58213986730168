import React, { useEffect, useState } from 'react';
import { AddTwoToneIcon, Anchor, Button, PageHeader, Pager, Searchfield, Table, TableColumn, TableRow } from '@keplerco/core';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { SortField, OrganizationLevelType } from '../../../enums';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { CompanyEntitySearchParams } from '../../../models/overmind/search-params';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../overmind';
import styles from './all-roles.module.css';
import { CompanyRoleListResponse } from '../../../models/overmind/company-entity';

export function AllRoles(): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { user, permissions, companyVariables } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<CompanyRoleListResponse>();
  const [request, setRequest] = useState<CompanyEntitySearchParams>({
    search: undefined,
    sortAscending: true,
    sortField: SortField.Name,
    pageSize: 6,
    page: 1,
    organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
    companySlug: companyVariables.slug,
    departmentSlug: undefined,
    teamSlug: undefined,
    learnerSlug: undefined,
    searchGlobally: false,
  });

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await actions.getCompanyRoles(request);
      setData(response);

      setLoading(false);
    }

    getData();
  }, [request]);

  return (
    <div className="wrapper stack" style={{ gap: 30 }}>
      <PageHeader breadcrumbs={[{ name: 'Role & Skill Management', url: window.location.pathname }]} title="All roles" subtitle="A library of all the roles within your company" />
      <div className={styles.companyRolesContainer}>
        <div className={styles.companyRolesActions}>
          <Searchfield onInput={search => setRequest({ ...request, search, page: 1 })} />

          <div className={styles.companyRolesActionsGroup}>
            {(user?.isSystemAdmin || organizationLevel?.organizationLevel === OrganizationLevelType.Company) && (
              <Button fullWidthMobile type="button" square theme="dark" onClick={() => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizard.replace(':companySlug', companyVariables.slug!)}`)}>
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <AddTwoToneIcon />
                  Create role
                </div>
              </Button>
            )}
          </div>
        </div>

        {loading || !!data?.entities.length ? (
          <React.Fragment>
            {loading ? (
              <SkeletonLoader height="300px" />
            ) : (
              <Table currentSortBy="role" currentSortDirection={request.sortAscending ? 'Ascending' : 'Descending'} onSort={() => setRequest(currentState => ({ ...currentState, sortAscending: !currentState.sortAscending }))}>
                {data?.entities.map(role => (
                  <TableRow key={role.slug} id={role.slug!}>
                    <TableColumn sortable id="role" label="Role">
                      <Anchor hovertype="opacity" textTransform="none" onClick={() => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizardUpdate.replace(':companySlug', companyVariables.slug!).replace(':roleSlug', role.slug!)}`)}>
                        {role.name}
                      </Anchor>
                    </TableColumn>

                    <TableColumn id="description" label="Description">
                      {role.description}
                    </TableColumn>
                  </TableRow>
                ))}
              </Table>
            )}
            {!!data && data.totalPages > 1 && (
              <PagerConnector defaultPageNumber={request.page} pageCount={data?.totalPages ?? 0} onPageChange={page => setRequest(currentState => ({ ...currentState, page }))}>
                {connector => <Pager {...connector} isLoading={loading} />}
              </PagerConnector>
            )}
          </React.Fragment>
        ) : (
          <EmptyState title="No roles found" />
        )}
      </div>
    </div>
  );
}
