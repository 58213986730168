import React, { useEffect, useState } from 'react';
import { DropdownItem, capitalCase } from '@keplerco/core';
import { MappingType, OrganizationLevelType } from '../../../../../enums';
import { UniqueEntity } from '../../../../../models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { ICourseMappingCourseConditionProps } from '../course-mapping-course.models';
import { CompanyEntitySearchParams } from '../../../../../models/overmind/search-params';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { LANGUAGES } from '../../../../../library/consts/languages';
import { DisplayList } from '../../../../../components/lists/display-list';
import { ListItem } from '../../../../../components/lists/list-item';

function getMappingTypeString(type: MappingType): string {
  switch (type) {
    case MappingType.Language: {
      return 'language';
    }

    case MappingType.Team: {
      return 'team';
    }

    case MappingType.Department: {
      return 'department';
    }

    case MappingType.Role: {
      return 'role';
    }

    default: {
      return 'entity';
    }
  }
}

export function CourseMappingCourseCondition({ type, entities, onClickAddEntity, onClickRemoveEntity }: ICourseMappingCourseConditionProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  function onClickEntityItem(entity: UniqueEntity) {
    setSelectedEntityItems(currentState => {
      const nextState = structuredClone(currentState);
      const index = nextState.indexOf(entity.slug!);
      if (index === -1) {
        nextState.push(entity.slug!);
        onClickAddEntity(type, entity);
      } else {
        nextState.splice(index, 1);
        onClickRemoveEntity(type, entity.slug!);
      }
      return nextState;
    });
  }

  const [selectedEntityItems, setSelectedEntityItems] = useState<string[]>([]);
  const [entityItems, setEntityItems] = useState<DropdownItem[]>([]);

  useEffect(() => {
    async function fetchEntityItems() {
      const selectedEntities = entities?.map(entity => entity.slug!) ?? [];
      setSelectedEntityItems(selectedEntities);

      switch (type) {
        case MappingType.Role: {
          const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

          const rolesRequest: CompanyEntitySearchParams = {
            organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
            companySlug: companyVariables.slug,
            departmentSlug: undefined,
            teamSlug: undefined,
            learnerSlug: undefined,
            searchGlobally: false,
            page: 1,
            pageSize: 99999, // ridiculously large pageSize to fetch ALL roles
            search: undefined,
            sortAscending: undefined,
          };

          const roles = await actions.getCompanyRoles(rolesRequest);
          setEntityItems(
            roles?.entities.map(role => ({
              value: role.slug!,
              label: role.name,
              onClick: () => onClickEntityItem({ slug: role.slug, name: role.name }),
            })) ?? []
          );
          break;
        }
        case MappingType.Department: {
          const departments = await actions.getLearningManagementDepartments(companyVariables.slug!);
          setEntityItems(
            departments?.map(department => ({
              value: department.slug,
              label: department.name,
              onClick: () => onClickEntityItem({ slug: department.slug, name: department.name }),
            })) ?? []
          );
          break;
        }

        case MappingType.Team: {
          const teams = await actions.getLearningManagementTeams(companyVariables.slug!);
          setEntityItems(
            teams?.map(team => ({
              value: team.slug!,
              label: team.teamName,
              onClick: () => onClickEntityItem({ slug: team.slug, name: team.teamName }),
            })) ?? []
          );
          break;
        }

        case MappingType.Language: {
          setEntityItems(
            LANGUAGES.map(language => ({
              value: language,
              onClick: () => onClickEntityItem({ slug: language, name: language }),
            })) ?? []
          );
          break;
        }

        default: {
          break;
        }
      }
    }

    fetchEntityItems();
  }, [type, entities]);

  return (
    <EntitiesCardWidget title={`${capitalCase(getMappingTypeString(type))}s`}>
      <DisplayList dropdownValue={selectedEntityItems} dropdownItems={entityItems}>
        {entities?.map(entity => (
          <ListItem key={entity.slug} onClick={() => onClickRemoveEntity(type, entity.slug!)}>
            <div className="caption1" style={{ color: 'var(--accent-2)' }}>
              {entity.name}
            </div>
          </ListItem>
        ))}
      </DisplayList>
    </EntitiesCardWidget>
  );
}
