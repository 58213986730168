import React from 'react';
import { LoaderIcon } from '@keplerco/core';
import styles from './loader.module.css';

export function Loader(): JSX.Element {
  return (
    <div className={styles.background}>
      <LoaderIcon />
    </div>
  );
}
