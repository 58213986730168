import React, { Fragment, useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { OrganizationLevelType } from '../../../enums';
import { extractHighestOrganizationLevel } from '../../../library/helpers/permissions/extract-highest-organization-level';
import { Mode } from '../../../navigation/navigation.enums';
import { IPermissionsEntityDropdownWidgetProps, PermissionsEntity } from './permissions-entity-dropdown.models';
import { DropdownSelect, DropdownSelectItem } from '@keplerco/core';

export function PermissionsEntityDropdownWidget(props: IPermissionsEntityDropdownWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { mode, user, permissions, companyVariables } = useAppState();

  const [menuItems, setMenuItems] = useState<DropdownSelectItem[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<string>('');

  async function generatePermissionsEntities(companySlug: string | undefined): Promise<PermissionsEntity[] | undefined> {
    if (!companySlug) return undefined;
    const departments = await actions.getAnalyticsDepartments(companySlug);
    return departments?.map(department => ({
      organizationLevel: OrganizationLevelType.Department,
      entity: department,
    }));
  }

  function generateMenuItems(permissionsEntities: PermissionsEntity[], organizationLevel: OrganizationLevelType, slug: string | undefined) {
    let items: DropdownSelectItem[] = [];

    if (user?.isSystemAdmin || organizationLevel === OrganizationLevelType.Company) {
      const allEntity: PermissionsEntity = {
        organizationLevel: OrganizationLevelType.Company,
        entity: { name: 'All', slug },
      };

      const allItem: DropdownSelectItem = {
        value: 'All',
        onClick: () => handleEntitySelect(allEntity),
      };

      items.push(allItem);
    }

    items = items.concat(
      permissionsEntities.map(permissionsEntity => ({
        value: permissionsEntity.entity.name,
        onClick: () => handleEntitySelect(permissionsEntity),
        selected: selectedEntity === permissionsEntity.entity.name,
      }))
    );

    setMenuItems(items);
    if (!selectedEntity && !!items[0].onClick) items[0].onClick();
  }

  function handleEntitySelect(entity: PermissionsEntity) {
    setSelectedEntity(entity.entity.name);
    props.onClick(entity);
  }

  useEffect(() => {
    async function getData() {
      // role based
      if (mode === Mode.PlatformManagement) {
        const entities = await generatePermissionsEntities(companyVariables.slug);
        if (!entities) return;

        generateMenuItems(entities, OrganizationLevelType.Department, companyVariables.slug);
        return;
      }

      // permissions based
      if (!permissions) return;
      const organizationLevel = extractHighestOrganizationLevel(permissions?.analytics.organizationLevels);
      if (!organizationLevel) return;

      // company
      if (organizationLevel.organizationLevel === OrganizationLevelType.Company) {
        const entities = await generatePermissionsEntities(organizationLevel.entities[0].slug);
        if (!entities) return;

        generateMenuItems(entities, OrganizationLevelType.Company, organizationLevel?.entities[0].slug);
        return;
      }

      // department
      if (organizationLevel.organizationLevel === OrganizationLevelType.Department) {
        generateMenuItems(
          organizationLevel.entities.map(entity => ({
            organizationLevel: OrganizationLevelType.Department,
            entity,
          })),
          OrganizationLevelType.Department,
          undefined
        );
        return;
      }

      // team
      if (organizationLevel.organizationLevel === OrganizationLevelType.Team) {
        generateMenuItems(
          organizationLevel.entities.map(entity => ({
            organizationLevel: OrganizationLevelType.Team,
            entity,
          })),
          OrganizationLevelType.Team,
          undefined
        );
        return;
      }
    }

    getData();
  }, [mode, companyVariables.slug, permissions]);

  return <Fragment>{menuItems.length > 1 && <DropdownSelect label="Filter By" value={selectedEntity} items={menuItems} />}</Fragment>;
}
