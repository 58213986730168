import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationPriority, NotificationType } from '../../../../notifications/notifications.models';
import { EmptyState } from '../../../../components/general/empty-state/empty-state';
import { FetchType } from '../../../../enums';
import { getChipColour, getDataType } from '../../../../library/helpers/csv-data-types';
import { useAppActions, useAppState } from '../../../../overmind';
import { PagerConnector } from '../../../../components/general/pager-connector/pager-connector';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../../navigation/navigation.enums';
import { Button, Chip, PageHeader, Pager, Searchfield, Table, TableColumn, TableRow, skillScoreRounding, useMatchScreenWidth } from '@keplerco/core';
import styles from './report-template.module.css';
import { DownloadIcon } from '../../../../design/icons/download.icon';

const worker = new Worker(new URL('./report-template.worker.ts', import.meta.url));

export function AnalyticsReportTemplateLayout(props: { path: PagePath.analyticsReportTemplate | PagePath.analyticsReportTemplateName; slug: string; name: string }): JSX.Element {
  const navigate = useNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageSize] = useState<number>(10);
  const [data, setData] = useState<{ body: Array<[string, string][]>; header: Array<string> }>();
  const maxPage = useRef<number>();
  const records = useRef<[number, number]>();
  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    worker.onmessage = (event: MessageEvent<IMessageEventParams>) => {
      if (event.data.type === 'resolve-initiation') {
        records.current = [event.data.payload.filteredRecords, event.data.payload.totalRecords];
        maxPage.current = event.data.payload.pages;
        setData({ body: event.data.payload.data, header: event.data.payload.headers });
        actions.stopLoader(props.path);
        setIsLoading(false);
      }

      if (event.data.type === 'initiation-error') actions.stopLoader(props.path);

      if (event.data.type === 'error-download') {
        actions.addNotification({
          title: `Couldn't download CSV`,
          message: `Something went wrong with our system and we were not able to download your CSV`,
          active: true,
          priority: NotificationPriority.Toast,
          type: NotificationType.Error,
          id: `download-error-report-templates-${Date.now()}`,
        });
      }

      if (event.data.type === 'resolve-download') {
        const anchor = window.document.createElement('a');
        anchor.setAttribute('href', event.data.payload.url);

        const date = new Date().toDateString().split(' ').join('-').toLowerCase();

        if (!!query) anchor.setAttribute('download', `${encodeURIComponent(query.toLowerCase().trim())}-${props.name.toLowerCase()}-${date}.csv`);
        else anchor.setAttribute('download', `${props.name.toLowerCase()}-${date}.csv`);

        setTimeout(() => anchor.click());
      }
    };
  }, []);

  useEffect(() => {
    async function getData() {
      if (!data) actions.startLoader({ path: props.path, type: FetchType.PageFetching });
      setIsLoading(true);
      worker.postMessage({ type: 'initiation', payload: { urlBase: process.env.REACT_APP_KEPLER_API_ENDPOINT, templateId: props.slug, page, pageSize, query, companySlug: companyVariables.slug } });
    }

    getData();
  }, [props, page, pageSize, query]);

  const hasData = !!data && !!data.body.length && !!data.header.length;

  return (
    <PageLoaderLayer path={props.path}>
      <div className="pageWrapper wrapper">
        <PageHeader
          breadcrumbs={[
            {
              name: 'Analytics',
              url: `${PagePath.analyticsBase}`,
            },
            {
              name: 'Report templates',
              url: `${PagePath.analyticsBase}${PagePath.analyticsReportTemplates.replace(':companySlug', companyVariables.slug!)}`,
            },
            {
              name: props.name,
              url: window.location.href,
            },
          ]}
          title={props.name}
        />

        <div className={styles.actions}>
          <Searchfield loading={isLoading} value={query} placeholder="Search..." onInput={value => setQuery(value)} />

          {hasData && (
            <div className={styles.buttons}>
              <Button square theme="dark" type="button" onClick={() => worker.postMessage({ type: 'download', payload: { urlBase: process.env.REACT_APP_KEPLER_API_ENDPOINT, templateId: props.slug, companySlug: companyVariables.slug } })}>
                <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                  <DownloadIcon />
                  CSV
                </div>
              </Button>

              {!!query && !isMobile && (
                <Button square theme="dark" type="button" onClick={() => worker.postMessage({ type: 'download', payload: { query, urlBase: process.env.REACT_APP_KEPLER_API_ENDPOINT, templateId: props.slug, companySlug: companyVariables.slug } })}>
                  <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                    <DownloadIcon />
                    Filtered CSV
                  </div>
                </Button>
              )}
            </div>
          )}
        </div>

        {hasData && (
          <React.Fragment>
            <Table
              footerContent={
                <React.Fragment>
                  {!!maxPage.current && maxPage.current > 1 && (
                    <footer style={{ padding: '15px 0' }}>
                      <PagerConnector pageCount={maxPage.current} onPageChange={pageNumber => setPage(pageNumber)}>
                        {connector => {
                          return <Pager {...connector} />;
                        }}
                      </PagerConnector>
                    </footer>
                  )}
                </React.Fragment>
              }
            >
              {data.body.map((row, index) => {
                return (
                  <TableRow id={`${index}_${page}`} key={`${index}_${page}`}>
                    {data.header.map((header, headerIndex) => {
                      const rowCol = row[headerIndex];
                      const [headerName, dataValue] = rowCol ?? [];

                      const dataType = getDataType(dataValue);

                      if (dataType !== 'null' && dataType !== 'chip') {
                        return (
                          <TableColumn label={header} key={`${headerName}_${dataValue}`} id={header}>
                            {dataType === 'date' && <span>{new Date(dataValue).toLocaleDateString('en-UK', { dateStyle: 'medium' })}</span>}
                            {dataType === 'number' && <span>{skillScoreRounding(parseFloat(dataValue)).toLocaleString('en-UK')}</span>}
                            {dataType === 'string' && <span>{dataValue}</span>}
                            {dataType === 'url' && (
                              <a href={dataValue} target="_blank" rel="noopener noreferrer">
                                View
                              </a>
                            )}
                          </TableColumn>
                        );
                      }

                      if (dataType === 'chip') {
                        const [text, background] = getChipColour(dataValue);

                        return (
                          <TableColumn label={header} key={`${headerName}_${dataValue}`} id={header}>
                            <Chip label={dataValue} variant="tiny" backgroundColour={background} colour={text} />
                          </TableColumn>
                        );
                      }

                      return (
                        <TableColumn label={header} key={`${headerName}_${dataValue}`} id={header}>
                          <span style={{ opacity: 0.75 }}>-</span>
                        </TableColumn>
                      );
                    })}
                  </TableRow>
                );
              })}
            </Table>
          </React.Fragment>
        )}

        {!hasData && (
          <EmptyState
            title={!!query ? `No matches found` : 'No data found'}
            subtitle={!!query ? `No records match your search for "${query}"` : 'It seems like this template is empty'}
            buttons={[
              {
                text: !!query ? 'Clear search' : 'View All templates',
                onClick: () => {
                  if (!query) navigate(`${PagePath.analyticsBase}${PagePath.analyticsReportTemplates.replace(':companySlug', companyVariables.slug!)}`);
                  else setQuery(void 0);
                },
              },
            ]}
          />
        )}
      </div>
    </PageLoaderLayer>
  );
}
