import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAppActions, useAppState } from '../../../../overmind';
import { QuestionnaireName } from '../../../../models/questionnaire';
import { AssessmentQuestionnairesSkeleton } from './assessment-questionnaires.skeleton';
import { SkillAssessment } from '../../../../models';
import { IAssessmentQuestionnaireWidgetProps } from './assessment-questionnaires.models';
import { useIsInViewport } from '../../../../library/hooks/useIsInViewport';
import { Anchor, CheckboxCard, ListLayout, Searchfield } from '@keplerco/core';

export function AssessmentQuestionnaireWidget(props: IAssessmentQuestionnaireWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const errorRef = useRef<HTMLDivElement>(null);

  const [questionnaires, setQuestionnaires] = useState<QuestionnaireName[]>();
  const [selectedQuestionnaireSlugs, setSelectedQuestionnaireSlugs] = useState<string[]>([]);
  // const [questionnaireSlug, setQuestionnaireSlug] = useState<string | undefined>(props.assessmentSlug);
  const [skillAssessment, setSkillAssessment] = useState<SkillAssessment | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const inViewport = useIsInViewport(errorRef);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      const names = await actions.getQuestionnaireNames(companyVariables.slug!);

      if (!!props.assessmentSlug) {
        const skillAssessment = await actions.getSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: props.assessmentSlug });
        if (!!skillAssessment) {
          setSelectedQuestionnaireSlugs(skillAssessment.questionnaireSlugs);
          setSkillAssessment(skillAssessment);
        }
      }

      setQuestionnaires(names);

      setIsLoading(false);
    }

    if (!questionnaires) getData();
  }, [questionnaires]);

  function toggleQuestionnaireSelection(slug: string) {
    setErrorMessage(void 0);
    if (selectedQuestionnaireSlugs.includes(slug)) {
      setSelectedQuestionnaireSlugs(prevSlugs => prevSlugs.filter(s => s !== slug));
    } else {
      setSelectedQuestionnaireSlugs(prevSlugs => [...prevSlugs, slug]);
    }
  }

  async function saveQuestionnaires() {
    if (selectedQuestionnaireSlugs.length > 0 && !!skillAssessment) {
      setErrorMessage(void 0);

      await actions.updateSkillAssessment({
        ...skillAssessment,
        companySlug: companyVariables.slug!,
        questionnaireSlugs: selectedQuestionnaireSlugs,
      });

      props.onStepComplete();
    } else setErrorMessage(`Please select at least one questionnaire to continue`);
  }

  useEffect(() => {
    if (!!errorMessage && !inViewport) setTimeout(() => errorRef.current?.scrollIntoView());
  }, [errorMessage]);

  if (!questionnaires || isLoading) return <AssessmentQuestionnairesSkeleton />;

  return (
    <Fragment>
      <div style={{ marginBottom: 15 }}>
        <Searchfield value={searchQuery} onInput={value => setSearchQuery(value)} />
      </div>

      <ListLayout>
        {questionnaires
          .filter(questionnaire => {
            if (!!searchQuery) {
              return questionnaire.name === searchQuery || questionnaire.skillSubTypeName === searchQuery;
            }

            return true;
          })
          .map(questionnaire => {
            const isSelected = selectedQuestionnaireSlugs.includes(questionnaire.slug);

            return (
              <CheckboxCard id={questionnaire.slug} key={questionnaire.slug} checked={isSelected} onChange={() => toggleQuestionnaireSelection(questionnaire.slug)}>
                <div className="card" key={questionnaire.slug}>
                  <div className="body1" style={{ color: 'var(--accent-3)' }}>
                    {questionnaire.name}
                  </div>

                  <div className="caption1">{questionnaire.skillSubTypeDescription}</div>
                </div>
              </CheckboxCard>
            );
          })}
      </ListLayout>

      <div className="fieldErrorMessage" ref={errorRef} style={{ marginTop: 5, visibility: !!errorMessage ? 'visible' : 'hidden' }}>
        {errorMessage}
      </div>

      <footer style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 30 }}>
        <Anchor arrow onClick={() => saveQuestionnaires()}>
          Next
        </Anchor>
      </footer>
    </Fragment>
  );
}
