import { Context } from '../..';
import { Department, Team } from '../../../models';
import { CourseMappingRequest, CourseMappingResponse, CourseMappingsResponse, CourseMappingsSearchParams, MappedCoursesResponse, MappedCoursesSearchParams } from '../../../models/overmind/learning-management';
import * as base from '../base';

// filters
export async function getLearningManagementDepartments(context: Context, companySlug: string): Promise<Department[] | undefined> {
  const request: base.IRequest = { url: base.apiURL('courses', 'departments', companySlug), authenticated: true };
  const response: base.IResponse<Department[]> = await base.request(request);
  return response.data;
}

export async function getLearningManagementTeams(context: Context, companySlug: string): Promise<Team[] | undefined> {
  const request: base.IRequest = { url: base.apiURL('courses', 'teams', companySlug), authenticated: true };
  const response: base.IResponse<Team[]> = await base.request(request);
  return response.data;
}

// course mapping
export async function getCourseMappings(context: Context, searchParams: CourseMappingsSearchParams): Promise<CourseMappingsResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(`courses`, `search-courses${base.params(searchParams)}`), authenticated: true };
  const response: base.IResponse<CourseMappingsResponse> = await base.request(request);
  return response.data;
}

export async function getCourseMapping(context: Context, params: { companySlug: string; courseSlug: string }): Promise<CourseMappingResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(`courses`, params.companySlug, params.courseSlug), authenticated: true };
  const response: base.IResponse<CourseMappingResponse> = await base.request(request);
  return response.data;
}

export async function saveCourseMapping(context: Context, params: { companySlug: string; courseSlug: string; request: CourseMappingRequest }): Promise<void> {
  const url = base.apiURL(`courses`, params.companySlug, params.courseSlug);
  const request: base.IRequest = { url, authenticated: true, method: 'PUT', body: JSON.stringify(params.request) };
  await base.request(request);
}

// mapped courses
export async function getMappedCourses(context: Context, searchParams: MappedCoursesSearchParams): Promise<MappedCoursesResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(`courses`, `mapping${base.params(searchParams)}`), authenticated: true };
  const response: base.IResponse<MappedCoursesResponse> = await base.request(request);
  return response.data;
}