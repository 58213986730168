import { ScoreComparison } from '../../../enums/score-comparison';
import { UniqueEntity } from '../../../models';
import { ReducerAction } from '../../../models/reducer-state';
import { Country } from '../../onboarding/onboarding.models';
import { ICompanyFormValues } from './manage-company-focus-panel.model';

interface CompanyState {
  companyFormValues: ICompanyFormValues | undefined;
  industries: Array<UniqueEntity> | undefined;
  countries: Array<Country> | undefined;
  availableIndustryOptions: Array<UniqueEntity> | undefined;
  availableCountryOptions: Array<UniqueEntity> | undefined;
  selectedCountryOptions: Array<UniqueEntity> | undefined;
  selectedIndustry: UniqueEntity | undefined;
  industryIdError: boolean;
  countryIdsError: boolean;
  scoreComparison: ScoreComparison;
}

export const initialState: CompanyState = {
  industryIdError: false,
  countryIdsError: false,
  scoreComparison: ScoreComparison.None,
  companyFormValues: undefined,
  industries: undefined,
  countries: undefined,
  availableIndustryOptions: undefined,
  availableCountryOptions: undefined,
  selectedCountryOptions: undefined,
  selectedIndustry: undefined,
};

export enum CompanyActionTypes {
  SetCompanyFormValues,
  SetIndustries,
  SetCountries,
  SetAvailableIndustryOptions,
  SetAvailableCountryOptions,
  SetSelectedIndustry,
  SetSelectedCountryOptions,
  SetIndustryIdError,
  SetCountryIdsError,
  SetScoreComparison,
}

type CompanyActions =
  | ReducerAction<CompanyActionTypes.SetCompanyFormValues, { payload: ICompanyFormValues }>
  | ReducerAction<CompanyActionTypes.SetIndustries, { payload: Array<UniqueEntity> }>
  | ReducerAction<CompanyActionTypes.SetCountries, { payload: Array<Country> }>
  | ReducerAction<CompanyActionTypes.SetAvailableIndustryOptions, { payload: Array<UniqueEntity> | undefined }>
  | ReducerAction<CompanyActionTypes.SetAvailableCountryOptions, { payload: Array<UniqueEntity> | undefined }>
  | ReducerAction<CompanyActionTypes.SetSelectedIndustry, { payload: UniqueEntity | undefined }>
  | ReducerAction<CompanyActionTypes.SetSelectedCountryOptions, { payload: Array<UniqueEntity> }>
  | ReducerAction<CompanyActionTypes.SetIndustryIdError, { payload: boolean }>
  | ReducerAction<CompanyActionTypes.SetCountryIdsError, { payload: boolean }>
  | ReducerAction<CompanyActionTypes.SetScoreComparison, { payload: ScoreComparison }>;

export function reducer(state: CompanyState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.SetCompanyFormValues: {
      return { ...state, companyFormValues: action.payload };
    }

    case CompanyActionTypes.SetIndustries: {
      return { ...state, industries: action.payload };
    }

    case CompanyActionTypes.SetCountries: {
      return { ...state, countries: action.payload };
    }

    case CompanyActionTypes.SetAvailableIndustryOptions: {
      return { ...state, availableIndustryOptions: action.payload };
    }

    case CompanyActionTypes.SetAvailableCountryOptions: {
      return { ...state, availableCountryOptions: action.payload };
    }

    case CompanyActionTypes.SetSelectedIndustry: {
      return { ...state, selectedIndustry: action.payload };
    }

    case CompanyActionTypes.SetSelectedCountryOptions: {
      return { ...state, selectedCountryOptions: action.payload };
    }

    case CompanyActionTypes.SetIndustryIdError: {
      return { ...state, industryIdError: action.payload };
    }

    case CompanyActionTypes.SetCountryIdsError: {
      return { ...state, countryIdsError: action.payload };
    }

    case CompanyActionTypes.SetScoreComparison: {
      return { ...state, scoreComparison: action.payload };
    }

    default: {
      return state;
    }
  }
}

