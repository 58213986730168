import React, { useEffect, useState } from 'react';
import { RoleFitSearchParams } from '../../../../models/overmind/search-params';
import { useAppActions, useAppState } from '../../../../overmind';
import { EntityScores } from '../../../../models/overmind/analytics';
import { CompanyVariables } from '../../../../models/view/company-variables';
import { RoleFitContent } from '../role-fit-content/role-fit-content';
import { DataPoint } from '../../../../components/charts/recharts.models';
import { removeDuplicateScores } from '../../../../components/charts/recharts.helpers';

function transformScores(scores: EntityScores[], companyVariables: CompanyVariables): DataPoint[] {
  const uniqueScores = removeDuplicateScores(scores, 'name');

  return uniqueScores
    .filter(score => !!score.scores[0].score)
    .map(personScore => {
      const personScoresObj: DataPoint = { name: personScore.name };

      personScore.scores.forEach(score => {
        personScoresObj[score.name.toLowerCase().includes('expected') ? 'Expected Proficiency' : 'Actual Proficiency'] = !!score?.score ? (companyVariables.useLevels ? score?.score.level : score?.score.percentage) : undefined;
      }); // These two need to be hard-coded for now (Harry's suggestion)

      return personScoresObj;
    });
}

export function RoleFitAverageWidget({ roleSlug, peopleSlugs }: { roleSlug: string | undefined; peopleSlugs: string[] }): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);

  useEffect(() => {
    async function getData() {
      if (!roleSlug || !peopleSlugs?.length) return;

      setLoading(true);

      const searchParams: RoleFitSearchParams = {
        companySlug: companyVariables.slug!,
        roleSlug,
        learnerSlugs: peopleSlugs,
      };
      const response = await actions.analyticsGetRoleFitAverage(searchParams);
      setDataPoints(transformScores(response ?? [], companyVariables));
      setLoading(false);
    }

    getData();
  }, [roleSlug, peopleSlugs]);

  return <RoleFitContent title="Top employee role-fit" roleSlug={roleSlug} peopleSlugs={peopleSlugs} data={dataPoints} isLoading={loading} fill={['baby-blue', 'bright-pink']} />;
}
