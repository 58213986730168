import { Context } from '../..';
import * as base from '../base';
import { Schedule, ScheduleSearchResponse } from '../../../models/view/schedule';
import { BaseSearchRequest } from '../../../models';
import { ScheduleAction } from '../../../enums/schedule-action';

export async function createScheduleAction(context: Context, body: Schedule): Promise<Schedule | undefined> {
  const request: base.IRequest = { url: base.apiURL(`ScheduleAction?companySlug=${context.state.companyVariables.slug}`), authenticated: true, method: 'POST', body: JSON.stringify(body) };
  const response: base.IResponse<Schedule> = await base.request(request);

  return response.data;
}

export async function updateScheduleAction(context: Context, body: Schedule): Promise<Schedule | undefined> {
  const request: base.IRequest = { url: base.apiURL(`ScheduleAction?companySlug=${context.state.companyVariables.slug}`), authenticated: true, method: 'PUT', body: JSON.stringify(body) };
  const response: base.IResponse<Schedule> = await base.request(request);

  return response.data;
}

export async function searchScheduledActions(context: Context, body: BaseSearchRequest): Promise<ScheduleSearchResponse | undefined> {
  const params = new URLSearchParams(Object.entries(body));

  const request: base.IRequest = { url: base.apiURL(`ScheduleAction?${params.toString()}`), authenticated: true, method: 'GET' };
  const response: base.IResponse<ScheduleSearchResponse> = await base.request(request);

  return response.data;
}

export async function getScheduledAction(context: Context, params: { attachedEntity: number; scheduleType: ScheduleAction }): Promise<Schedule | undefined> {
  const request: base.IRequest = { url: base.apiURL(`ScheduleAction`, params.scheduleType.toString(), params.attachedEntity.toString()), authenticated: true, method: 'GET' };
  const response: base.IResponse<Schedule> = await base.request(request);

  return response.data;
}

export async function deleteScheduledAction(context: Context, scheduleActionId: string | number): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(`ScheduleAction`, scheduleActionId.toString()), authenticated: true, method: 'DELETE' };
  await base.request(request);
}

