import { BaseRagColours } from '@keplerco/core';
import { UrgencyIndication } from '../../enums/analytics';
import { filteredColours } from '../../library/consts/filtered-colours';
import { SkillScoresSkillResponse } from '../../models/overmind/analytics';
import { YourSkillsSunburstSlice } from './your-skills.models';

export function getSunBurstData(skills: SkillScoresSkillResponse[]): YourSkillsSunburstSlice[] {
  const sunBurstData: YourSkillsSunburstSlice[] = [];

  skills.forEach((skill: SkillScoresSkillResponse, index: number) => {
    const data: YourSkillsSunburstSlice = {
      name: skill.name,
      value: skill.score?.percentage ?? 0,
      score: skill.score,
      comparisonScore: skill.comparisonScore,
      color: filteredColours[index % filteredColours.length],
      urgencyIndication: skill.urgencyIndication,

      usesExpectedProficiency: !!skill.urgencyIndication,
      skillsGap: 0,
    };

    sunBurstData.push(data);
  });

  return sunBurstData;
}

export function getUrgencyIndicationRAGColour(urgencyIndication: UrgencyIndication): BaseRagColours {
  if (urgencyIndication === UrgencyIndication.BelowExpectation) return 'r';
  if (urgencyIndication === UrgencyIndication.MeetsExpectation) return 'a';
  return 'g';
}

function getUrgencyIndicationImportance(urgencyIndication: UrgencyIndication): number {
  if (urgencyIndication === UrgencyIndication.BelowExpectation) return 1;
  if (urgencyIndication === UrgencyIndication.MeetsExpectation) return 2;
  return 3;
}

export function sortByUrgencyIndication(urgencyIndicationA: UrgencyIndication, urgencyIndicationB: UrgencyIndication): number {
  return getUrgencyIndicationImportance(urgencyIndicationA) - getUrgencyIndicationImportance(urgencyIndicationB);
}
