import React, { useEffect, useState } from 'react';
import { UrgencyIndication } from '../../enums/analytics';
import { getUrgencyIndicationRAGColour } from './your-skills.helpers';
import { BaseRagColours, Chip } from '@keplerco/core';

export function UrgencyIndicationChip({ urgencyIndication }: { urgencyIndication: UrgencyIndication }): JSX.Element {
  const [colour, setColour] = useState<BaseRagColours>('g');

  useEffect(() => {
    setColour(getUrgencyIndicationRAGColour(urgencyIndication));
  }, [urgencyIndication]);

  return <Chip label={urgencyIndication} variant="tiny" backgroundColour={colour} colour="white" />;
}
