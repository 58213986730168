/* eslint-disable indent */
import { Anchor, CascadingPanel, CascadingPanels, ChevronIcon, Chip, IChip, ListItemLayout, ListLayout, Toggle, useCascadingPanelsConnector, useMatchScreenWidth } from '@keplerco/core';
import React, { Fragment, useEffect, useState } from 'react';
import { ListItem } from '../../../../../components/lists/list-item';
import classNames from 'classnames';
import { EmptyState } from '../../../../../components/general/empty-state/empty-state';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { EntitiesCardWidget } from '../../../../../components/cards/entities-card.widget';
import { ImportSkillLayout } from '../../../../administration/roles/manage-role/import-skill.layout';
import { SelectSkillsLayout } from './select-skills.layout';
import { useAppActions } from '../../../../../overmind';
import { AssessmentPreferencesResponse } from '../../../../../models/overmind/assessment-preferences';
import styles from './skills-step.module.css';
import { PeopleStepSkeleton } from '../people-step/people-step.skeleton';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';

enum ErrorMessage {
  NoSkills = 'No skills selected',
  ManagerReviewSkills = 'All selected non-managers must have at least one skill assigned.',
  PeerReviewSkills = 'All selected people must have at least one skill assigned.',
  SelfReviewSkills = 'All selected people must have at least one skill assigned.',
}

enum CascadingFocusPanelIds {
  SelectSkills = 'SelectSkills',
  ImportSkills = 'ImportSkills',
}

export function SkillsStepLayout({
  skills,
  setSkills,
  assessmentSlug,
  preferences,
  roleSlug,
  initialPeople,
  people,
  setPeople,
  completeStep,
  goToPreviousStep,
  applyToAll,
  setApplyToAll,
}: {
  skills: CompanySkillListItemResponse[];
  setSkills: (skills: CompanySkillListItemResponse[]) => void;
  assessmentSlug: string;
  applyToAll: boolean;
  setApplyToAll: (applyToAll: boolean) => void;
  preferences: AssessmentPreferencesResponse;
  roleSlug: string | undefined;
  initialPeople: IPerson[];
  people: IPerson[];
  setPeople: (people: IPerson[]) => void;
  completeStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element {
  const actions = useAppActions();

  const [loading, setLoading] = useState<boolean>();
  const [peopleToEdit, setPeopleToEdit] = useState<IPerson[]>(people);
  const [personToEdit, setPersonToEdit] = useState<IPerson>();
  const [sync, setSync] = useState<string>(crypto.randomUUID());

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();

  const { openPanelIds, next, previous } = useCascadingPanelsConnector();

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    setPeopleToEdit(people);
  }, [people]);

  function generateMessage(people: IPerson[]): ErrorMessage | undefined {
    let errorMessage = undefined;

    if (applyToAll) {
      errorMessage = !skills.length ? ErrorMessage.NoSkills : undefined;
      setErrorMessage(errorMessage);
      return errorMessage;
    }

    if (preferences.allowManagerReview) {
      if (people.some(person => !person.manager && !person.skills.length)) {
        errorMessage = ErrorMessage.ManagerReviewSkills;
      }
    }

    if (preferences.allowPeerReview) {
      if (people.some(person => !person.skills.length)) {
        errorMessage = ErrorMessage.PeerReviewSkills;
      }
    }

    if (preferences.allowSelfReview) {
      if (people.some(person => !person.skills.length)) {
        errorMessage = ErrorMessage.SelfReviewSkills;
      }
    }

    setErrorMessage(errorMessage);
    return errorMessage;
  }

  useEffect(() => {
    if (!errorMessage) return;
    generateMessage(peopleToEdit);
  }, [preferences, peopleToEdit, applyToAll, skills]);

  function generateChips(person: IPerson): IChip[] {
    let chips: IChip[] = [
      {
        label: 'Manager',
        backgroundColour: 'baby-blue',
      },
      {
        label: `${applyToAll ? skills.length : person.skills.length} skill(s)`,
        backgroundColour: applyToAll ? 'default' : 'grape',
      },
    ];

    if (!person.manager || (!preferences.allowManagerReview && !preferences.allowPeerReview)) chips = chips.filter(chip => !chip.label.includes('Manager'));
    if (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager) chips = chips.filter(chip => !chip.label.includes('skill'));

    return chips;
  }

  async function onNextHandler() {
    let tempPeopleToEdit = peopleToEdit;
    if (applyToAll) {
      const nextPeopleToEdit: IPerson[] = structuredClone(peopleToEdit);
      nextPeopleToEdit.forEach(personToEdit => (personToEdit.skills = skills));
      tempPeopleToEdit = nextPeopleToEdit;
      setPeopleToEdit(nextPeopleToEdit);
      setPeople(nextPeopleToEdit);
      if (!!generateMessage(nextPeopleToEdit)) return;
    } else {
      setPeople(peopleToEdit);
      if (!!generateMessage(peopleToEdit)) return;
    }

    setLoading(true);

    // TODO: refactor APIs to simplify FE code
    const peopleAdded: IPerson[] = tempPeopleToEdit.filter(person => !initialPeople.some(initialPerson => initialPerson.id === person.id));
    const peopleRemoved: IPerson[] = [];
    const peopleUpdated: IPerson[] = [];
    initialPeople.forEach(initialPerson => (!tempPeopleToEdit.some(person => person.id === initialPerson.id) ? peopleRemoved.push(initialPerson) : peopleUpdated.push(initialPerson)));

    const skillsToAdd: { skillId: number; userId: string }[] = [];
    const skillsToRemove: { skillId: number; userId: string }[] = [];

    peopleAdded.forEach(personAdded => {
      personAdded.skills.forEach(skill => skillsToAdd.push({ skillId: skill.companyEntityId!, userId: personAdded.id }));
    });
    peopleRemoved.forEach(personRemoved => {
      personRemoved.skills.forEach(skill => skillsToRemove.push({ skillId: skill.companyEntityId!, userId: personRemoved.id }));
    });
    peopleUpdated.forEach(initialPerson => {
      const currentPerson = tempPeopleToEdit.find(person => person.id === initialPerson.id);
      if (!currentPerson) return;

      // remove skills on the initial person that aren't on the current person
      initialPerson.skills.forEach(initialSkill => {
        if (!currentPerson.skills.some(currentSkill => currentSkill.companyEntityId === initialSkill.companyEntityId)) {
          skillsToRemove.push({ skillId: initialSkill.companyEntityId!, userId: initialPerson.id });
        }
      });

      // add skills on the current person that aren't on the initial person
      currentPerson.skills.forEach(currentSkill => {
        if (!initialPerson.skills.some(initialSkill => initialSkill.companyEntityId === currentSkill.companyEntityId)) {
          skillsToAdd.push({ skillId: currentSkill.companyEntityId!, userId: initialPerson.id });
        }
      });
    });

    for (const skillToAdd of skillsToAdd) {
      await actions.addSkillToFocusArea({
        ...skillToAdd,
        assessmentSlug,
      });
    }

    for (const skillToRemove of skillsToRemove) {
      await actions.removeSkillFromFocusArea({
        ...skillToRemove,
        assessmentSlug,
      });
    }

    completeStep();

    setLoading(false);
  }

  if (loading) return <PeopleStepSkeleton />;

  return (
    <div className={styles.divider}>
      <EntitiesCardWidget title="Skills" description="Select skills for the assignment">
        <div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          <ListItem onClick={!applyToAll ? undefined : () => next(CascadingFocusPanelIds.SelectSkills)}>
            <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: `auto ${isMobile ? '1fr' : 'auto 1fr auto'} auto` }}>
              <div onClick={event => event.stopPropagation()}>
                <Toggle id="applyToAll" name="applyToAll" toggleForegroundColour="white" value={applyToAll} onChange={() => setApplyToAll(!applyToAll)} />
              </div>

              {isMobile ? (
                <div>
                  <div className="body1">Apply same skills to all</div>

                  <div className="body2" style={{ color: 'var(--text_1)' }}>
                    Choose particular skills and apply it to the group of people
                  </div>

                  <div style={{ marginTop: 5 }}>
                    <Chip label={`${skills.length} skill(s)`} backgroundColour={!applyToAll ? 'default' : 'grape'} />
                  </div>
                </div>
              ) : (
                <Fragment>
                  <div className="body1">Apply same skills to all</div>

                  <div className="body2" style={{ color: 'var(--text_1)' }}>
                    Choose particular skills and apply it to the group of people
                  </div>

                  <div>
                    <Chip label={`${skills.length} skill(s)`} backgroundColour={!applyToAll ? 'default' : 'grape'} />
                  </div>
                </Fragment>
              )}

              <ChevronIcon tone={!applyToAll ? 'default' : 'primary'} />
            </div>
          </ListItem>

          <div>
            <div className="heading5">Assigned People & Skills</div>

            <div style={{ marginTop: 15, height: 400, overflowY: 'auto' }}>
              {!!peopleToEdit.length ? (
                <ListLayout>
                  {peopleToEdit.map(person => (
                    <ListItemLayout
                      key={person.id}
                      disabled={applyToAll || (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager)}
                      onClick={() => {
                        setPersonToEdit(person);
                        next(CascadingFocusPanelIds.SelectSkills);
                      }}
                    >
                      <Fragment>
                        {isMobile ? (
                          <div className="card" style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: '1fr auto' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                              <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                                {person.firstName} {person.lastName}
                              </div>
                              <div className="caption2" style={{ color: 'var(--default)' }}>
                                {person.department.name} | {person.team.name}
                              </div>
                              <div className="caption2">{person.email}</div>
                              <div style={{ marginTop: 10, display: 'flex', gap: 15 }}>
                                {generateChips(person).map(chip => (
                                  <Chip key={chip.label} {...chip} />
                                ))}
                              </div>
                            </div>

                            <ChevronIcon tone={applyToAll || (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager) ? 'default' : 'primary'} />
                          </div>
                        ) : (
                          <div className="card" style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: '1fr 1fr 195px auto' }}>
                            <div>
                              <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                                {person.firstName} {person.lastName}
                              </div>
                              <div className="caption2" style={{ color: 'var(--default)' }}>
                                {person.department.name} | {person.team.name}
                              </div>
                            </div>

                            <div className="caption2">{person.email}</div>

                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                              {generateChips(person).map(chip => (
                                <Chip key={chip.label} {...chip} />
                              ))}
                            </div>
                            <ChevronIcon tone={applyToAll || (preferences.allowManagerReview && !preferences.allowPeerReview && !preferences.allowSelfReview && person.manager) ? 'default' : 'primary'} />
                          </div>
                        )}
                      </Fragment>
                    </ListItemLayout>
                  ))}
                </ListLayout>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <EmptyState />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classNames('formErrorMessage', { invisible: !errorMessage })}>{errorMessage}&nbsp;</div>
      </EntitiesCardWidget>

      <footer className={classNames('card', styles.divider)} style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', background: 'var(--background)' }}>
        <Anchor arrow reverse onClick={goToPreviousStep}>
          Back
        </Anchor>

        <Anchor arrow onClick={onNextHandler}>
          Next
        </Anchor>
      </footer>

      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={id => {
          previous();
          switch (id) {
            case CascadingFocusPanelIds.SelectSkills: {
              setPersonToEdit(undefined);
              break;
            }

            case CascadingFocusPanelIds.ImportSkills: {
              setSync(crypto.randomUUID());
              break;
            }
          }
        }}
      >
        <CascadingPanel id={CascadingFocusPanelIds.SelectSkills}>
          <SelectSkillsLayout
            roleSlugs={!!roleSlug ? [roleSlug] : personToEdit?.roleSlugs}
            personToEdit={personToEdit}
            skills={personToEdit?.skills ?? skills}
            sync={sync}
            onBack={() => {
              previous();
              setPersonToEdit(undefined);
            }}
            onAssign={selectedSkills => {
              !!personToEdit
                ? setPeopleToEdit(currentState => {
                  const nextState: IPerson[] = structuredClone(currentState);
                  const nextPerson = nextState.find(person => person.id === personToEdit.id);
                  if (!!nextPerson) nextPerson.skills = selectedSkills;
                  return nextState;
                })
                : setSkills(selectedSkills);
              previous();
              setPersonToEdit(undefined);
            }}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingFocusPanelIds.ImportSkills}>
          <ImportSkillLayout
            supertitle="Assessments"
            onClose={() => {
              previous();
              setSync(crypto.randomUUID());
            }}
          />
        </CascadingPanel>
      </CascadingPanels>
    </div>
  );
}
