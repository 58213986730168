import React, { useMemo } from 'react';
import { ICoreSkillsMapProps, Planet } from './core-skills-map.models';
import { CoreSkillsMapPlanet } from './core-skills-map-planet/core-skills-map-planet';
import sun from './sun.graphic.svg';
import { generateRandomNumber } from './core-skills-map.helpers';
import styles from './core-skills-map.module.css';

export function CoreSkillsMap({ skills }: ICoreSkillsMapProps): JSX.Element {
  const planets: Planet[] = useMemo(() => {
    return skills.map((skill, index) => {
      const imageIndex = index % 60;
      const isEven = index % 2 === 0;

      return {
        ...skill,
        src: `/planets/planet_${(imageIndex + 1).toString().padStart(2, '0')}.svg`,
        width: generateRandomNumber(120, 190),
        offset: isEven ? generateRandomNumber(18, 38) : -generateRandomNumber(18, 38),
      };
    });
  }, [skills.length]);

  return (
    <React.Fragment>
      <div className={styles.sun}>
        <object data={sun} type="image/svg+xml">
          <img src={sun} alt="The Sun" />
        </object>
      </div>

      <div className={styles.background}>
        {planets.map(planet => (
          <CoreSkillsMapPlanet key={planet.slug} planet={planet} />
        ))}
      </div>
    </React.Fragment>
  );
}
