import React, { useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { DailyActivityWidget, LeaderboardWidget } from '../../../widgets';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { PageHeader } from '@keplerco/core';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';
import classNames from 'classnames';
import analyticsStyles from '../analytics.module.css';

export function AnalyticsEngagementPage(): JSX.Element {
  const actions = useAppActions();

  const { dateRangeOutput } = useAppState();
  const [entity, setEntity] = useState<PermissionsEntity>();

  return (
    <div className={classNames('pageWrapper wrapper', analyticsStyles.page)}>
      <PageHeader
        breadcrumbs={[
          { name: 'Analytics', url: PagePath.analyticsBase },
          { name: 'Engagement', url: window.location.pathname },
        ]}
        title="Engagement"
        type="actions"
        divider
      >
        <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

          <Daterange
            defaultValue={dateRangeOutput}
            onDaterangeChange={result => {
              actions.setGlobalDateRange(result);
            }}
          />
        </div>
      </PageHeader>

      <DailyActivityWidget entity={entity} />

      <CourseEngagementWidget entity={entity} isLearner={false} />

      <LeaderboardWidget entity={entity} />
    </div>
  );
}
