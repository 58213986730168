import { Context } from '../..';
import * as base from '../base';
import { LearnerCareerPath } from '../../../models/view/learner-career-path';
import { handleErrorToErrorPage } from '../functions';
import { ErrorSignalTypes } from '../../../enums';
import { LearnerCareerPathSkillSet } from '../../../models';

const CONTROLLER = `learnercareerpath`;

export async function getLearnerCareerPaths(context: Context): Promise<LearnerCareerPath[] | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, context.state.skillAssessmentConfig?.assessmentSlug) };
  const response: base.IResponse<LearnerCareerPath[]> = await base.request(request);

  return response.data;
}

export async function saveLearnerCareerPaths(context: Context, payload: LearnerCareerPath[]): Promise<void> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, context.state.skillAssessmentConfig?.assessmentSlug), method: 'POST', body: JSON.stringify(payload) };
  await base.request(request);
}

export async function getLearnerCareerPathSkillSets(context: Context): Promise<LearnerCareerPathSkillSet[] | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skill-sets`, context.state.skillAssessmentConfig?.assessmentSlug) };
  const response: base.IResponse<LearnerCareerPathSkillSet[]> = await base.request(request);

  return response.data;
}

export async function saveLearnerCareerPathSkillSets(context: Context, payload: LearnerCareerPathSkillSet): Promise<LearnerCareerPathSkillSet | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skill-sets`, context.state.skillAssessmentConfig?.assessmentSlug), method: 'POST', body: JSON.stringify(payload) };
  const response: base.IResponse<LearnerCareerPathSkillSet> = await base.request(request);

  return response.data;
}

export async function getLearnerCareerPathSkillSet(context: Context, payload: string): Promise<LearnerCareerPathSkillSet | undefined> {
  if (!context.state.skillAssessmentConfig?.assessmentSlug) {
    handleErrorToErrorPage(context, `/error/${ErrorSignalTypes.System}/404`, window.location.pathname);
    return undefined;
  }
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skill-sets`, context.state.skillAssessmentConfig?.assessmentSlug, payload), authenticated: true };
  const response: base.IResponse<LearnerCareerPathSkillSet> = await base.request(request);

  return response.data;
}
