import { EntityScores } from '../../models/overmind/analytics';

//TODO: BE to review data
export function removeDuplicateScores(scores: EntityScores[], key: keyof EntityScores): EntityScores[] {
  const uniqueItems = new Set();
  return scores.filter(item => {
    const keyValue = item[key];
    if (uniqueItems.has(keyValue)) return false;
    else {
      uniqueItems.add(keyValue);
      return true;
    }
  });
}

