import { PageHeader } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { PageLoaderLayer } from '../../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import Stepper from '../../../../../components/general/stepper/stepper';
import { Step } from '../../../../../components/general/stepper/stepper.models';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import SummaryStepLayout from '../summary-step/summary-step.layout';
import { EntityType, OrganizationLevelType, SortField } from '../../../../../enums';
import { PeopleStepLayout } from '../people-step/people-step.layout';
import { DetailsStepLayout } from '../details-step/details-step-layout';
import { SkillsStepLayout } from '../skills-step/skills-step.layout';
import { CompanyRoleListItemResponse, CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';
import { AIGenerateRoleRequest } from '../../../../../overmind/actions';
import { CompanyEntitySearchParams, CompanyRoleAssigneesSearchParams } from '../../../../../models/overmind/search-params';
import { CompanyRoleAssignee } from '../people-step/people-step.models';
import { scrollToTop } from '../../../../../library/helpers/scroll-to-top';
import { Proficiency } from '../../../../../models/proficiency';

export function KeplerAIStepperPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [seniorities, setSeniorities] = useState<Proficiency[]>([]);
  const [roleSlug, setRoleSlug] = useState<string>();
  const [role, setRole] = useState<AIGenerateRoleRequest>();
  const [selectedRole, setSelectedRole] = useState<CompanyRoleListItemResponse>();
  const [skills, setSkills] = useState<CompanySkillListItemResponse[]>([]);
  const [people, setPeople] = useState<CompanyRoleAssignee[]>([]);

  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [allowedSteps, setAllowedSteps] = useState([1]);
  const [steps, setSteps] = useState<Step[]>([]);
  const totalSteps = 4;

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const seniorityLevelsResponse = await actions.getSeniorityLevels(companyVariables.slug!);
      setSeniorities(seniorityLevelsResponse ?? []);
      setLoading(false);
    }

    getData();
  }, []);

  useEffect(() => {
    async function getRole() {
      if (!roleSlug) return;
      const response = await actions.getCompanyRole({ companySlug: companyVariables.slug!, roleSlug });
      setSelectedRole(response);
    }

    async function getPeople() {
      if (!roleSlug) return;
      const request: CompanyRoleAssigneesSearchParams = {
        search: undefined,
        sortAscending: true,
        sortField: SortField.Name,
        pageSize: 99999, // TODO: high pageSize to fetch ALL people
        page: 1,
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        roleSlug: roleSlug,
      };
      const response = await actions.getCompanyRoleAssignees(request);
      // TODO: fix model so we don't need to do all the below
      setPeople(
        response?.assignees.map(assignee => {
          return {
            selected: true,
            name: assignee.name,
            email: assignee.email ?? '',
            department: assignee.department ?? '',
            team: assignee.team ?? '',
            entitySlug: assignee.slug ?? '',
            organizationLevel: OrganizationLevelType.Company,
          };
        }) ?? []
      );
    }

    async function getData() {
      setLoading(true);
      await getRole();
      await getPeople();
      setLoading(false);
    }

    getData();
  }, [roleSlug, currentStepIndex]);

  useEffect(() => {
    setSteps([
      {
        stepNumber: 1,
        label: 'Details',
        content: (
          <DetailsStepLayout
            path={PagePath.roleSkillManagementWizardKeplerAI}
            seniorities={seniorities}
            roleSlug={roleSlug}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            completeStep={data => {
              setRoleSlug(data.roleSlug);
              setRole(data.role);
              setSkills(data.skills ?? []);
              completeStep(1);
            }}
          />
        ),
        isCompleted: !!roleSlug,
        disabled: true,
      },
      {
        stepNumber: 2,
        label: 'Skills',
        content: (
          <SkillsStepLayout
            path={PagePath.roleSkillManagementWizardKeplerAI}
            parentLoading={loading}
            roleSlug={roleSlug}
            role={role!}
            skills={skills}
            completeStep={async () => {
              // TODO: should this be moved to 'useEffect' with 'getRole' and 'getPeople'? or should they be moved to their respective 'completeStep' functions?
              setLoading(true);

              const request: CompanyEntitySearchParams = {
                search: undefined,
                sortAscending: true,
                pageSize: 99999, // ridiculously high pageSize to fetch ALL skills
                page: 1,
                organizationLevel: OrganizationLevelType.Company,
                companySlug: companyVariables.slug,
                departmentSlug: undefined,
                teamSlug: undefined,
                learnerSlug: undefined,
                searchGlobally: false,
                relatedType: EntityType.Role,
                roleSlug,
              };
              const response = await actions.getCompanySkills(request);
              setSkills(response?.entities ?? []);

              setLoading(false);

              completeStep(2);
            }}
            goToPreviousStep={goToPreviousStep}
          />
        ),
        isCompleted: !!skills.length,
      },
      {
        stepNumber: 3,
        label: 'People',
        content: <PeopleStepLayout parentLoading={loading} roleSlug={roleSlug} assignedPeople={people} goToPreviousStep={goToPreviousStep} completeStep={() => completeStep(3)} />,
        isCompleted: !!people?.length,
      },
      {
        stepNumber: 4,
        label: 'Summary',
        content: <SummaryStepLayout path={PagePath.roleSkillManagementWizardKeplerAI} role={selectedRole} skills={skills} people={people} goToStep={setCurrentStepIndex} completeStep={() => completeStep(4)} />,
        isCompleted: false,
      },
    ]);
  }, [seniorities, roleSlug, role, selectedRole, skills, people]);

  useEffect(() => {
    const completedSteps = steps.filter(step => step.isCompleted).map(step => step.stepNumber);

    setAllowedSteps([1, ...completedSteps, completedSteps[completedSteps.length - 1] + 1]);
  }, [steps]);

  function completeStep(stepNumber: number) {
    setSteps(currentSteps => currentSteps.map(step => (step.stepNumber === stepNumber ? { ...step, isCompleted: true } : step)));
    allowStep(stepNumber + 1);
    goToNextStep();
    scrollToTop();
  }

  function allowStep(stepNumber: number) {
    setAllowedSteps(currentAllowedSteps => {
      if (!currentAllowedSteps.includes(stepNumber)) {
        return [...currentAllowedSteps, stepNumber];
      }
      return currentAllowedSteps;
    });
  }

  function goToNextStep() {
    if (currentStepIndex >= totalSteps) return;
    setCurrentStepIndex(currentIndex => currentIndex + 1);
    scrollToTop();
  }

  function goToPreviousStep() {
    setCurrentStepIndex(currentIndex => (currentIndex > 1 ? currentIndex - 1 : currentIndex));
    scrollToTop();
  }

  return (
    <PageLoaderLayer path={PagePath.roleSkillManagementWizardKeplerAI}>
      <div className="wrapper stack" style={{ paddingBottom: 30, gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Role & Skill Management', url: `${PagePath.roleSkillManagementBase}` },
            { name: 'Role Wizard', url: `${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
            { name: 'AI role generator', url: window.location.href },
          ]}
          title="Role wizard"
          subtitle="Define job roles, assign essential skills, and set performance standards."
          divider
        />
        {!!steps.length && <Stepper steps={steps} currentStepIndex={currentStepIndex} goToStep={setCurrentStepIndex} allowedSteps={allowedSteps} />}
      </div>
    </PageLoaderLayer>
  );
}
