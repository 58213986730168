import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { IFullscreenModalProps } from './fullscreen-modal.models';
import { themedAssetUrl } from '../../../../library/helpers/themed-asset-url';
import { ReturnIcon } from '../return.icon';
import { ExpandIcon } from '../expand.icon';
import { CollapseIcon } from '../collapse.icon';
import { IconButton, XIcon, useMediaQuery } from '@keplerco/core';
import styles from './fullscreen-modal.module.css';

function isInsideClick(element: HTMLDialogElement, event: React.MouseEvent<HTMLDialogElement, MouseEvent>): boolean {
  const content = element.getBoundingClientRect();
  const verticalInside = content.top <= event.clientY && content.bottom >= event.clientY;
  const horizontalInside = content.left <= event.clientX && content.right >= event.clientX;
  return verticalInside && horizontalInside;
}

const BODY_CLASS_LIST = 'modalOpen';

export function FullscreenModal(props: PropsWithChildren<IFullscreenModalProps>) {
  const { children, show, superTitle, title, onClose, onNext, onReturn } = props;

  const dialogRef = useRef<HTMLDialogElement>(null);
  const isTouch = useMediaQuery('screen and (pointer: coarse)');
  const [isFullscreen, setIsFullscreen] = useState<boolean>(isTouch);

  function openDialog() {
    const dialog = dialogRef.current;
    if (!dialog) return;

    document.body.classList.add(BODY_CLASS_LIST);
    dialog.showModal();
  }

  useEffect(() => {
    if (show) openDialog();
  }, [show]);

  useEffect(() => {
    setIsFullscreen(isTouch);
  }, [isTouch]);

  function closeDialog() {
    const dialog = dialogRef.current;
    if (!dialog) return;

    document.body.classList.remove(BODY_CLASS_LIST);
    onClose();
    dialog.close();
  }

  if (show)
    return ReactDOM.createPortal(
      <dialog
        ref={dialogRef}
        className={classNames(styles.dialog, { [styles.isFullscreen]: isFullscreen })}
        onClick={event => {
          const dialog = dialogRef.current;
          if (!dialog || isInsideClick(dialog, event)) return;
          closeDialog();
        }}
      >
        <header className={classNames(styles.header, styles.bar)}>
          <div style={{ display: 'flex' }}>
            {!isTouch ? (
              <IconButton iconType="fill" padding={25} onClick={() => setIsFullscreen(currentState => !currentState)}>
                {!isFullscreen ? <ExpandIcon /> : <CollapseIcon />}
              </IconButton>
            ) : (
              <React.Fragment>
                {!!onReturn && (
                  <IconButton iconType="fill" padding={25} onClick={onReturn}>
                    <ReturnIcon />
                  </IconButton>
                )}
              </React.Fragment>
            )}

            <div className={styles.titleGroup} style={{ paddingLeft: isTouch && !onReturn ? 10 : 0 }}>
              {!!superTitle && <span className="caption1">{superTitle}</span>}
              <h6 className={classNames('subtitle', styles.title)}>{title}</h6>
            </div>
          </div>

          <IconButton iconType="fill" padding={25} onClick={closeDialog}>
            <XIcon />
          </IconButton>
        </header>

        <section style={{ height: `calc(100% - ${!isTouch || !!onNext ? 140 : 70}px)`, position: 'relative', width: '100%' }}>{children}</section>

        {(!isTouch || !!onNext) && (
          <footer className={classNames(styles.footer, styles.bar)}>
            {!isTouch && (
              <div className={styles.logo}>
                <span className="caption2">Powered by</span>
                <img style={{ height: '100%' }} src={themedAssetUrl('logos/kepler-logo.svg')} />
              </div>
            )}

            {!!onNext && (
              <button className={styles.button} onClick={onNext}>
                Next
              </button>
            )}
          </footer>
        )}
      </dialog>,
      window.document.body
    );

  return <React.Fragment />;
}
