import { Context } from '../..';
import { Reflection } from '../../../models/view/reflection';
import { ReflectionList } from '../../../models/view/reflection-list';
import { ReflectionListItem } from '../../../models/view/reflection-list-item';
import * as base from '../base';

export async function searchReflectionList(context: Context, searchTerm: string): Promise<ReflectionListItem[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(`reflection`, `search-reflection-list?searchTerm=${searchTerm}`), authenticated: true };
  const response: base.IResponse<ReflectionListItem[]> = await base.request(request);

  return response.data;
}

export async function getReflectionList(context: Context, skillSlug: string): Promise<ReflectionList | undefined> {
  const request: base.IRequest = { url: base.apiURL(`reflection`, `get-reflection-list`, skillSlug), authenticated: true };
  const response: base.IResponse<ReflectionList> = await base.request(request);

  return response.data;
}

export async function getReflection(context: Context, reflectionId: number): Promise<Reflection | undefined> {
  const request: base.IRequest = { url: base.apiURL(`reflection`, `get-reflection/${reflectionId}`), authenticated: true };
  const response: base.IResponse<Reflection> = await base.request(request);

  return response.data;
}

export async function saveReflection(context: Context, reflectionViewModel: Reflection): Promise<Reflection | undefined> {
  const request: base.IRequest = { url: base.apiURL(`reflection`, `save-reflection`), authenticated: true, method: 'POST', body: JSON.stringify(reflectionViewModel) };
  const response: base.IResponse<Reflection> = await base.request(request);

  return response.data;
}
