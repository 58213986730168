import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { DaterangeDateOptionTypes, DaterangeOutput, IDaterangeDialogProps } from './daterange.models';
import classNames from 'classnames';
import { Datepicker } from '../datepicker/datepicker';
import { computeDateRanges } from './daterange.data';
import styles from './daterange.module.css';
import { Anchor, Button, CloseButton } from '@keplerco/core';

// TODO: refactor to use Modal component
export function DaterangeDialog(props: IDaterangeDialogProps): JSX.Element {
  const { defaultValue, today, activeItem, dateLimitStart, dateLimitEnd, isOpen, onClickActiveItem, onChangeDateLimitStart, onChangeDateLimitEnd, onDaterangeChange, onClose, title, applyButtonText } = props;

  const ranges = useRef(computeDateRanges(today.current));

  function apply(): void {
    const range = [dateLimitStart, dateLimitEnd] as any;

    const data: DaterangeOutput = {
      type: activeItem,
      daterangeIfApplicable: activeItem === DaterangeDateOptionTypes.CustomRange ? range : void 0,
    };

    onDaterangeChange(data);
    onClose();
  }

  return ReactDOM.createPortal(
    <dialog open={isOpen} className={styles.dialog}>
      <div className={styles.dialogContent}>
        <CloseButton onClick={onClose} />

        <h6 className="heading5" style={{ textAlign: 'center' }}>
          {title ?? 'Sort by date'}
        </h6>

        <ul className={styles.list}>
          {/* Up to Today */}
          <li className={classNames(styles.listItem, { [styles.checked]: activeItem === DaterangeDateOptionTypes.UpToToday })} onClick={() => onClickActiveItem(DaterangeDateOptionTypes.UpToToday)}>
            <div className={classNames(styles.radioButton, { [styles.checked]: activeItem === DaterangeDateOptionTypes.UpToToday })} />

            <div className={styles.radioButtonContent}>
              <div className="body1">Up to Today</div>
              <div className="caption2" style={{ color: 'var(--accent-3)' }}>
                Everything up to {ranges.current.today.value}
              </div>
            </div>
          </li>

          {/* This Week */}
          <li className={classNames(styles.listItem, { [styles.checked]: activeItem === DaterangeDateOptionTypes.ThisWeek })} onClick={() => onClickActiveItem(DaterangeDateOptionTypes.ThisWeek)}>
            <div className={classNames(styles.radioButton, { [styles.checked]: activeItem === DaterangeDateOptionTypes.ThisWeek })} />

            <div className={styles.radioButtonContent}>
              <div className="body1">This Week</div>
              <div className="caption2" style={{ color: 'var(--accent-3)' }}>
                {ranges.current.thisWeek.start} - {ranges.current.thisWeek.end}
              </div>
            </div>
          </li>

          {/* This Month */}
          <li className={classNames(styles.listItem, { [styles.checked]: activeItem === DaterangeDateOptionTypes.ThisMonth })} onClick={() => onClickActiveItem(DaterangeDateOptionTypes.ThisMonth)}>
            <div className={classNames(styles.radioButton, { [styles.checked]: activeItem === DaterangeDateOptionTypes.ThisMonth })} />

            <div className={styles.radioButtonContent}>
              <div className="body1">This Month</div>
              <div className="caption2" style={{ color: 'var(--accent-3)' }}>
                {ranges.current.thisMonth.start} - {ranges.current.thisMonth.end}
              </div>
            </div>
          </li>

          {/* This Year */}
          <li className={classNames(styles.listItem, { [styles.checked]: activeItem === DaterangeDateOptionTypes.ThisYear })} onClick={() => onClickActiveItem(DaterangeDateOptionTypes.ThisYear)}>
            <div className={classNames(styles.radioButton, { [styles.checked]: activeItem === DaterangeDateOptionTypes.ThisYear })} />

            <div className={styles.radioButtonContent}>
              <div className="body1">This Year</div>
              <div className="caption2" style={{ color: 'var(--accent-3)' }}>
                {ranges.current.thisYear.start} - {ranges.current.thisYear.end}
              </div>
            </div>
          </li>

          {/* Year to Date */}
          <li className={classNames(styles.listItem, { [styles.checked]: activeItem === DaterangeDateOptionTypes.YearToDate })} onClick={() => onClickActiveItem(DaterangeDateOptionTypes.YearToDate)}>
            <div className={classNames(styles.radioButton, { [styles.checked]: activeItem === DaterangeDateOptionTypes.YearToDate })} />

            <div className={styles.radioButtonContent}>
              <div className="body1">Year to Date</div>
              <div className="caption2" style={{ color: 'var(--accent-3)' }}>
                {ranges.current.yearToDate.start} - {ranges.current.yearToDate.end}
              </div>
            </div>
          </li>

          {/* Custom Range */}
          <li className={classNames(styles.listItem, styles.custom, { [styles.checked]: activeItem === DaterangeDateOptionTypes.CustomRange })} onClick={() => onClickActiveItem(DaterangeDateOptionTypes.CustomRange)}>
            <div className={classNames(styles.radioButton, { [styles.checked]: activeItem === DaterangeDateOptionTypes.CustomRange })} />

            <div className={styles.customContent}>
              <div className="body1">Custom Range</div>
              <output className={styles.customOutput}>
                <Datepicker fixed label="Date from" defaultDate={dateLimitStart} onDateSelected={onChangeDateLimitStart} maxDate={dateLimitEnd} />
                <Datepicker fixed label="Date to" defaultDate={dateLimitEnd} onDateSelected={onChangeDateLimitEnd} minDate={dateLimitStart} maxDate={today.current} />
              </output>
            </div>
          </li>
        </ul>

        <footer className={styles.footer}>
          <Anchor
            onClick={() => {
              onClickActiveItem(defaultValue.type ?? DaterangeDateOptionTypes.UpToToday);
              onClose();
            }}
          >
            Cancel
          </Anchor>

          <Button type="button" arrow={false} filled onClick={() => apply()}>
            {applyButtonText ?? 'Apply'}
          </Button>
        </footer>
      </div>

      <div className={styles.background} onClick={onClose} />
    </dialog>,
    window.document.body
  );
}
