import React from 'react';
import { PagePath } from '../../../navigation/navigation.enums';
import { ChevronIcon, Chip, ChipWrapper, ListItemLayout, ListLayout, PageHeader } from '@keplerco/core';
import { useAppState } from '../../../overmind';
import { PeerIcon } from './peer.icon';
import { QuestionnaireIcon } from './questionnaire.icon';
import { FOPIcon } from './fop.icon';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import KeplerNavlink from '../../../navigation/guards/kepler-navlink';

export function AssessmentsWizardPage(): JSX.Element {
  const { companyVariables } = useAppState();

  return (
    <PageLoaderLayer path={PagePath.assessmentManagementWizard}>
      <div className="wrapper stack" style={{ paddingBottom: 30, gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Assessment Management', url: `${PagePath.assessmentManagementBase}` },
            { name: 'Wizard', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
          ]}
          title="Wizard"
          subtitle="Select from Peer Assessment, Questionnaire-Based Assessment, or Fields of Practice Assessment to accurately evaluate skill levels"
          divider
        />

        <ListLayout gap={30}>
          <ListItemLayout>
            <KeplerNavlink style={{ textDecoration: 'none' }} to={`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardPeer.replace(':companySlug', companyVariables.slug!)}`} className="card action-card interactive">
              <PeerIcon />

              <div className="action-card-content">
                <h3 className="subtitle">Peer Assessment</h3>

                <p className="body2">Allow a group of people to endorse each other's skills. Ideal for teams that share similar skill sets, and work in collaboration with one another.</p>

                <p className="body2">This assessment includes the ability for an employee to be assessed by their line manager.</p>

                <ChipWrapper>
                  <Chip label="Peer Assessment" borderColour="borders" backgroundColour="transparent" colour="text" />
                  <Chip label="Manager Assessment" borderColour="borders" backgroundColour="transparent" colour="text" />
                  <Chip label="Self Assessment" borderColour="borders" backgroundColour="transparent" colour="text" />
                </ChipWrapper>
              </div>

              <ChevronIcon tone="primary" strokeWidth={1} size={40} />
            </KeplerNavlink>
          </ListItemLayout>

          <ListItemLayout>
            <KeplerNavlink style={{ textDecoration: 'none' }} to={`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardQuestionnaire.replace(':companySlug', companyVariables.slug!)}`} className="card action-card interactive">
              <QuestionnaireIcon />

              <div className="action-card-content">
                <h3 className="subtitle">Questionnaire Based Assessment</h3>

                <p className="body2">Answer scenario based questions in a multiple choice format. Ideal for assessing behavioural competencies. Select from a list of assessment questionnaires focused on a variety of essential skill sets.</p>
              </div>

              <ChevronIcon tone="primary" strokeWidth={1} size={40} />
            </KeplerNavlink>
          </ListItemLayout>

          <ListItemLayout>
            <KeplerNavlink style={{ textDecoration: 'none' }} to={`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardFOP.replace(':companySlug', companyVariables.slug!)}`} className="card action-card interactive">
              <FOPIcon />

              <div className="action-card-content">
                <h3 className="subtitle">Fields of Practice Assessment</h3>

                <p className="body2">Allows people to compare their skills against regulatory expectations. Ideal for facilitating CPD assessments that align with regulatory requirements. Ask your customer success manager about adding assessments for new regulatory frameworks.</p>
              </div>

              <ChevronIcon tone="primary" strokeWidth={1} size={40} />
            </KeplerNavlink>
          </ListItemLayout>
        </ListLayout>
      </div>
    </PageLoaderLayer>
  );
}
