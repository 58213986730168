import React from 'react';
import { SkeletonLoader } from '../../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';

export function AssessmentQuestionnairesSkeleton(): JSX.Element {
  return (
    <React.Fragment>
      <SkeletonLoader height="45px" width="250px" />

      <SkeletonLoader height="45px" />

      <SkeletonLoader height="15px" width="250px" />

      <SkeletonLoader height="45px" />

      <SkeletonLoader height="15px" width="250px" />

      <SkeletonLoader height="45px" />

      <SkeletonLoader height="15px" width="250px" />

      <SkeletonLoader height="45px" />
    </React.Fragment>
  );
}
