import React, { useEffect, useReducer, useState } from 'react';
import { CompletionStatus, FetchType } from '../../../enums';
import { IRatingsProps } from './rating.models';
import { RatingScale } from '../../../models/rating-scale';
import { useAppActions, useAppState } from '../../../overmind';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import * as st from './rating.state';
import { ReviewResource } from '../../../models/review-resource';
import { ExplainerFocusPanelLayout } from '../../../widgets/layouts/focus-panel-layouts/explainer-focus-panel/explainer-focus-panel.layout';
import { filteredColours } from '../../../library/consts/filtered-colours';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Button, PageHeader, Panel, UnorderedList, UnorderedListItem, capitalCase, useMatchScreenWidth } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { AssessmentType } from '../../../enums/assessment-type';
import { Rater } from '../../../components/inputs/raters/rater/rater';
import ratingStyles from './rating.module.css';
import classNames from 'classnames';

function RatingKeyPartial({ ratingScale, dialogContained }: { ratingScale: RatingScale; dialogContained?: boolean }): JSX.Element {
  return (
    <div className={classNames(ratingStyles.ratingKey, { [ratingStyles.dialogContained]: dialogContained })}>
      <h6 className="subtitle" style={{ textTransform: 'uppercase', color: 'var(--accent-2)', marginBottom: 15 }}>
        Rating key
      </h6>

      <UnorderedList>
        {ratingScale.map(rating => {
          return (
            <UnorderedListItem bulletTop='10px' key={rating.label}>
              <span className="caption1" style={{ fontWeight: 'bold' }}>
                {rating.label}
              </span>
              <br />
              <span className="caption2">{rating.description}</span>
            </UnorderedListItem>
          );
        })}
      </UnorderedList>
    </div>
  );
}

export function RatingLayout(props: IRatingsProps): JSX.Element {
  const actions = useAppActions();
  const { skillAssessmentConfig } = useAppState();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(st.reducer, st.initialState);
  const { ratingSlug } = useParams<any>();

  const [open, setOpen] = useState<boolean>(false);
  const [errorFields, setErrorFields] = useState<ReviewResource[]>();

  const [queriedSkill, setQueriedSkill] = useState<{ name: string; description: string }>();

  const subdomain = getSubdomain();

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: props.path, type: FetchType.PageFetching });

      if (props.type === 'peer') {
        const [ratingScale, teamMembers] = await Promise.all([actions.getCompanyRatingScales(), actions.getTeamReviews()]);
        const teamMember = teamMembers?.find(tempTeamMember => tempTeamMember.userSlug === ratingSlug);
        dispatch({ type: 'populate-peer', payload: { ratingScale, teamMember, title: props.title, includeManagerSkillAdd: false } });
      } else if (props.type === 'self') {
        const [ratingScale, learnerSkills] = await Promise.all([actions.getCompanyRatingScales(), actions.getSelfReviews()]);
        dispatch({ type: 'populate-self', payload: { ratingScale, learnerSkills, title: props.title, includeLearnerSkillFrequency: true } });
      } else {
        const [ratingScale, reviews] = await Promise.all([actions.getCompanyRatingScales(), actions.getLearnerCareerPathSkillSet(ratingSlug!)]);
        dispatch({ type: 'populate-career-paths', payload: { ratingScale, careerPathSkills: reviews, title: props.title, subdomain } });
      }

      actions.stopLoader(props.path);
    }

    getData();
  }, []);

  function onRatingPeerHandler(rating: number, skill: ReviewResource, index: number, parent: ReviewResource[]) {
    dispatch({ type: 'patch-team-member-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);

    if (rating === undefined) {
      return;
    }

    const nextSibling = parent[index + 1];
    if (nextSibling) {
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);
      window.scrollBy({ behavior: 'smooth', top: currentElement?.clientHeight });
    }
  }

  function onRatingCareerPathHandler(rating: number, skill: ReviewResource, index: number, parent: ReviewResource[]) {
    dispatch({ type: 'patch-career-path-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);

    const nextSibling = parent[index + 1];
    if (nextSibling) {
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);
      window.scrollBy({ behavior: 'smooth', top: currentElement?.clientHeight });
    }
  }

  function onRatingSelfHandler(rating: number, skill: ReviewResource) {
    dispatch({ type: 'patch-learner-skill', payload: { skillId: skill.companySkillId, skillUpdates: { skillLevel: rating } } });

    if (errorFields) setErrorFields([...errorFields.filter(s => s.companySkillId !== skill.companySkillId)]);
  }

  const isTablet = useMatchScreenWidth('tablet');

  return (
    <PageLoaderLayer path={props.path}>
      <div className="wrapper pageWrapper">
        <div style={{ marginBottom: 30 }}>
          {isTablet ? (
            <PageHeader breadcrumbs={state.crumbs} title={state.title ?? props.title} subtitle={state.subtitle ?? props.subtitle} type="actions">
              <Anchor onClick={() => setOpen(true)}>view rating key</Anchor>
            </PageHeader>
          ) : (
            <PageHeader breadcrumbs={state.crumbs} title={state.title ?? props.title} subtitle={state.subtitle ?? props.subtitle} />
          )}
        </div>

        <div className={ratingStyles.ratingBody}>
          <div className={ratingStyles.ratingScale}>
            {state.type === 'peer' && (
              <React.Fragment>
                {skillAssessmentConfig?.assessmentType !== AssessmentType.PeerEndorsement && <Navigate to={PagePath.analysisBase} />}
                {!!state.teamMember && (
                  <React.Fragment>
                    {state.teamMember.reviews.map((skill, index, parent) => {
                      const colour = filteredColours[index % filteredColours.length];

                      return (
                        <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                          {!!state.ratingScale && (
                            <Rater
                              scale={state.ratingScale}
                              data={{
                                id: skill.companySkillId,
                                label: skill.skillName,
                                description: skill.skillDescription,
                                rating: skill.skillLevel,
                              }}
                              colour={colour}
                              fadeOnComplete
                              extraRating={{ label: `Don't know` }}
                              onChange={rating => onRatingPeerHandler(rating, skill, index, parent)}
                            />
                          )}

                          {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                            <div style={{ padding: '0 0' }}>
                              <div className="formErrorMessage">Rating cannot be blank</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}

                <footer className={ratingStyles.ratingFooter}>
                  <Anchor
                    arrow
                    reverse
                    block
                    onClick={() => {
                      if (skillAssessmentConfig?.selfReviewCompletionStatus === CompletionStatus.NoActionRequired) {
                        navigate(`${PagePath.analysisBase}`);
                      } else {
                        navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`);
                      }
                    }}
                  >
                    Back
                  </Anchor>

                  <Button
                    type={'button'}
                    onClick={async () => {
                      const emptyReviews = state.teamMember?.reviews.filter(r => typeof r.skillLevel === 'undefined' || r.skillLevel === null) ?? [];

                      if (emptyReviews?.length > 0) {
                        const first = emptyReviews[0];
                        const element = document.getElementById(`rater-${first.companySkillId}`);
                        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        setErrorFields(emptyReviews);
                      } else {
                        actions.startLoader({ path: props.path, type: FetchType.Sending });

                        if (!!state.teamMember) {
                          await actions.submitTeamMemberReview({ ...state.teamMember, hasCompletedReview: true });
                        }

                        if (skillAssessmentConfig?.assessmentSlug) actions.getSkillAssessmentConfiguration(skillAssessmentConfig?.assessmentSlug);

                        actions.resetAllLoaders();

                        navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalPeerEndorsements}`);
                      }
                    }}
                  >
                    Submit Rating
                  </Button>
                </footer>
              </React.Fragment>
            )}

            {state.type === 'career-path' && (
              <React.Fragment>
                {skillAssessmentConfig?.assessmentType !== AssessmentType.FieldsOfPractice && <Navigate to={PagePath.analysisBase} />}
                {!!state.careerPathSkills && (
                  <React.Fragment>
                    {state.careerPathSkills.reviews
                      .sort((a, b) => a.skillName.localeCompare(b.skillName))
                      .map((skill, index, parent) => {
                        const colour = filteredColours[index % filteredColours.length];

                        return (
                          <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                            {!!state.ratingScale && (
                              <Rater
                                scale={state.ratingScale}
                                data={{
                                  id: skill.companySkillId,
                                  label: skill.skillName,
                                  description: skill.skillDescription,
                                  rating: skill.skillLevel,
                                }}
                                colour={colour}
                                fadeOnComplete
                                onChange={rating => onRatingCareerPathHandler(rating, skill, index, parent)}
                              />
                            )}

                            {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                              <div style={{ padding: '0 0' }}>
                                <div className="formErrorMessage">Rating cannot be blank</div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </React.Fragment>
                )}

                <footer className={ratingStyles.ratingFooter}>
                  <Anchor arrow reverse block onClick={() => navigate(`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`)}>
                    Back
                  </Anchor>

                  <Button
                    type={'button'}
                    onClick={async () => {
                      if (!!state.careerPathSkills && !!skillAssessmentConfig) {
                        const emptySkills = state.careerPathSkills.reviews.filter(skill => typeof skill.skillLevel === 'undefined' || skill.skillLevel === null) ?? [];

                        if (emptySkills.length > 0) {
                          const first = emptySkills[0];
                          const element = document.getElementById(`rater-${first.companySkillId}`);
                          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          setErrorFields(emptySkills);
                        } else {
                          actions.startLoader({ path: props.path, type: FetchType.Sending });
                          await actions.saveLearnerCareerPathSkillSets(state.careerPathSkills);

                          actions.resetAllLoaders();

                          const result = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig?.assessmentSlug);
                          if (result?.assessmentResultReady) {
                            actions.clearSkillAssessmentConfig();
                          }
                          navigate(`${PagePath.analysisBase}${PagePath.analysisRatingCareerPaths}`);
                        }
                      }
                    }}
                  >
                    Submit Ratings
                  </Button>
                </footer>
              </React.Fragment>
            )}

            {state.type === 'self' && (
              <React.Fragment>
                {skillAssessmentConfig?.assessmentType !== AssessmentType.PeerEndorsement && <Navigate to={PagePath.analysisBase} />}
                {!!state.learnerSkills && (
                  <React.Fragment>
                    {state.learnerSkills.map((skill, index) => {
                      const colour = filteredColours[index % filteredColours.length];

                      return (
                        <div key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                          {!!state.ratingScale && (
                            <Rater
                              scale={state.ratingScale}
                              data={{
                                id: skill.companySkillId,
                                label: skill.skillName,
                                description: skill.skillDescription,
                                rating: skill.skillLevel,
                              }}
                              colour={colour}
                              extraRating={{
                                count: skill.yearsSpent,
                                label: 'Number of years using this skill',
                                maxValue: 99,
                                onChange: years => {
                                  dispatch({ type: 'patch-learner-skill', payload: { skillId: skill.companySkillId, skillUpdates: { yearsSpent: years } } });
                                },
                              }}
                              onChange={(rating: any) => onRatingSelfHandler(rating, skill)}
                            />
                          )}

                          {errorFields?.find(i => i.companySkillId === skill.companySkillId) && (
                            <div style={{ padding: '0 0' }}>
                              <div className="formErrorMessage">Rating cannot be blank</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                )}

                <footer className={ratingStyles.ratingFooter}>
                  <Anchor arrow reverse block onClick={() => navigate(`${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}`)}>
                    Back
                  </Anchor>

                  <Button
                    type="button"
                    onClick={async () => {
                      if (!!state.learnerSkills && !!skillAssessmentConfig) {
                        const emptySkills = state.learnerSkills?.filter(skill => !skill.skillLevel) ?? [];

                        if (emptySkills.length > 0) {
                          const first = emptySkills[0];
                          const element = document.getElementById(`rater-${first.companySkillId}`);
                          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                          setErrorFields(emptySkills);
                        } else {
                          actions.startLoader({ path: props.path, type: FetchType.Sending });
                          await actions.submitSelfReviews(state.learnerSkills);
                          await actions.completeSelfReview();
                          await actions.getSkillAssessmentConfiguration(skillAssessmentConfig.assessmentSlug);

                          const skillAssessmentConfiguration = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug);
                          if (skillAssessmentConfiguration?.selfReviewCompletionStatus === CompletionStatus.Completed && skillAssessmentConfig?.selfPeerReviewCompletionStatus === CompletionStatus.NoActionRequired) {
                            actions.resetSkillConfiguration();
                          }
                          navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnical}`);

                          actions.stopLoader(props.path);
                        }
                      }
                    }}
                  >
                    Submit
                  </Button>
                </footer>
              </React.Fragment>
            )}
          </div>

          {!isTablet && !!state.ratingScale && <RatingKeyPartial ratingScale={state.ratingScale} />}
        </div>
      </div>

      <Panel open={open} onClose={() => setOpen(false)}>
        <div className="panelContent" style={{ gridTemplateRows: '1fr auto' }}>
          <div>{!!state.ratingScale && <RatingKeyPartial ratingScale={state.ratingScale} dialogContained />}</div>

          <footer className="panelFooter" style={{ justifyContent: 'flex-end' }}>
            <Button filled type="button" onClick={() => setOpen(false)}>
              Got it, Thanks!
            </Button>
          </footer>
        </div>
      </Panel>

      <Panel open={!!queriedSkill} onClose={() => setQueriedSkill(undefined)}>
        {!!queriedSkill && <ExplainerFocusPanelLayout title={capitalCase(queriedSkill.name)} description={queriedSkill.description} onClose={() => setQueriedSkill(void 0)} />}
      </Panel>
    </PageLoaderLayer>
  );
}
