import { SkillScoresSkillSubTypeResponse } from '../../models/overmind/analytics';

export interface YourSkillsState {
  skillSubTypes: SkillScoresSkillSubTypeResponse[];
  activeSkillSubType: SkillScoresSkillSubTypeResponse | undefined;
  includeAwaitingAssessment: boolean;
}

export const initialState: YourSkillsState = {
  skillSubTypes: [],
  activeSkillSubType: undefined,
  includeAwaitingAssessment: false,
};

export enum YourSkillsActionTypes {
  SetSkillTypes,
  SetActiveSkillType,
  SetIncludeAwaitingAssessment,
}

type YourSkillsActions =
  | { type: YourSkillsActionTypes.SetSkillTypes; payload: SkillScoresSkillSubTypeResponse[] }
  | { type: YourSkillsActionTypes.SetActiveSkillType; payload: SkillScoresSkillSubTypeResponse | undefined }
  | { type: YourSkillsActionTypes.SetIncludeAwaitingAssessment; payload: boolean };

export function reducer(state: YourSkillsState, action: YourSkillsActions): YourSkillsState {
  if (action.type === YourSkillsActionTypes.SetSkillTypes) {
    return { ...state, skillSubTypes: action.payload };
  }

  if (action.type === YourSkillsActionTypes.SetIncludeAwaitingAssessment) {
    return { ...state, includeAwaitingAssessment: action.payload };
  }

  if (action.type === YourSkillsActionTypes.SetActiveSkillType) {
    if (!action.payload) return { ...state, activeSkillSubType: undefined };
    return { ...state, activeSkillSubType: action.payload };
  }

  return state;
}

