import React from 'react';
import { IconProps } from '@keplerco/core';

export function AIRoleIcon({ size = 80 }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 100 101" fill="none">
      <circle cx="50" cy="50.5" r="39.3921" stroke="#57A8F2" strokeWidth="1.21586" />
      <mask id="mask0_2647_11217" maskUnits="userSpaceOnUse" x="17" y="17" width="66" height="67">
        <circle cx="50" cy="50.5" r="33" fill="var(--secondary)" />
      </mask>
      <g mask="url(#mask0_2647_11217)">
        <circle cx="49.8282" cy="50.3282" r="32.8282" fill="white" />
        <circle cx="49.8282" cy="50.3282" r="32.8282" fill="#57A8F2" />
      </g>
      <g clipPath="url(#clip0_2647_11217)">
        <path d="M57.6899 52.4975C57.6899 53.4 56.9574 54.1325 56.0549 54.1325C55.1524 54.1325 54.4199 53.4 54.4199 52.4975C54.4199 51.595 55.1524 50.8625 56.0549 50.8625C56.9574 50.8625 57.6899 51.595 57.6899 52.4975Z" fill="black" />
        <path
          d="M37.0925 44.9301C36.9925 44.9301 36.8925 44.8926 36.815 44.8201C35.7875 43.8401 35.1975 42.4226 35.1975 40.9326C35.1975 38.0851 37.325 35.7676 39.9375 35.7676C40.35 35.7676 40.76 35.8251 41.155 35.9401C41.3675 36.0001 41.49 36.2226 41.43 36.4351C41.37 36.6476 41.1475 36.7701 40.935 36.7101C40.6125 36.6176 40.2775 36.5701 39.94 36.5701C37.7675 36.5701 36.0025 38.5276 36.0025 40.9351C36.0025 42.2076 36.5025 43.4126 37.3725 44.2426C37.5325 44.3951 37.5375 44.6476 37.385 44.8076C37.3075 44.8901 37.2 44.9326 37.095 44.9326L37.0925 44.9301Z"
          fill="black"
        />
        <path
          d="M36.74 53.4175C34.125 53.4175 32 51.1 32 48.2525C32 45.6025 33.82 43.395 36.2325 43.115C36.4575 43.095 36.65 43.2475 36.6775 43.4675C36.7025 43.6875 36.545 43.885 36.325 43.9125C34.315 44.145 32.8 46.01 32.8 48.2525C32.8 50.66 34.5675 52.615 36.7375 52.615C37.5775 52.615 38.3775 52.3275 39.0525 51.7825C39.225 51.6425 39.4775 51.67 39.615 51.8425C39.755 52.015 39.7275 52.2675 39.555 52.405C38.735 53.065 37.76 53.415 36.7375 53.415L36.74 53.4175Z"
          fill="black"
        />
        <path
          d="M45.4199 70.2675C42.8049 70.2675 40.6799 67.95 40.6799 65.1025C40.6799 64.88 40.8599 64.7025 41.0799 64.7025C41.2999 64.7025 41.4799 64.8825 41.4799 65.1025C41.4799 67.51 43.2474 69.4675 45.4199 69.4675C47.5924 69.4675 49.3599 67.51 49.3599 65.1025V36.165C49.3599 33.7575 47.5924 31.8 45.4199 31.8C43.2474 31.8 41.4799 33.7575 41.4799 36.165C41.4799 38.5725 43.2474 40.53 45.4199 40.53C45.6424 40.53 45.8199 40.71 45.8199 40.93C45.8199 41.15 45.6399 41.33 45.4199 41.33C42.8049 41.33 40.6799 39.0125 40.6799 36.165C40.6799 33.3175 42.8049 31 45.4199 31C48.0349 31 50.1599 33.3175 50.1599 36.165V65.1C50.1599 67.9475 48.0324 70.265 45.4199 70.265V70.2675Z"
          fill="black"
        />
        <path
          d="M39.9375 65.5025C37.3225 65.5025 35.1975 63.185 35.1975 60.3375C35.1975 59.3075 35.475 58.315 35.9975 57.465C36.1125 57.2775 36.3575 57.215 36.55 57.3325C36.7375 57.4475 36.7975 57.695 36.68 57.8825C36.235 58.6075 35.9975 59.4575 35.9975 60.3375C35.9975 62.745 37.765 64.7025 39.935 64.7025C40.86 64.7025 41.76 64.34 42.4675 63.68C42.63 63.53 42.8825 63.54 43.0325 63.7C43.1825 63.8625 43.175 64.115 43.0125 64.2675C42.155 65.065 41.0625 65.505 39.9325 65.505L39.9375 65.5025Z"
          fill="black"
        />
        <path
          d="M36.3375 58.0724C33.7825 58.0724 32 55.9924 32 53.0149C32 52.1099 32.2175 51.2224 32.6275 50.4424C32.73 50.2474 32.9725 50.1724 33.17 50.2749C33.365 50.3774 33.44 50.6199 33.3375 50.8174C32.9875 51.4824 32.8025 52.2424 32.8025 53.0149C32.8025 55.5199 34.2575 57.2699 36.34 57.2699C36.5625 57.2699 36.74 57.4499 36.74 57.6699C36.74 57.8899 36.56 58.0699 36.34 58.0699L36.3375 58.0724Z"
          fill="black"
        />
        <path
          d="M59.5825 46.0949C59.36 46.0949 59.1825 45.9149 59.1825 45.6949C59.1825 45.4749 59.3625 45.2949 59.5825 45.2949C61.755 45.2949 63.52 43.3374 63.52 40.9324C63.52 38.5274 61.7525 36.5674 59.5825 36.5674C59.245 36.5674 58.91 36.6149 58.5875 36.7074C58.375 36.7724 58.1525 36.6449 58.0925 36.4324C58.03 36.2199 58.155 35.9974 58.3675 35.9374C58.7625 35.8249 59.1725 35.7649 59.585 35.7649C62.2 35.7649 64.325 38.0824 64.325 40.9299C64.325 43.7774 62.1975 46.0949 59.585 46.0949H59.5825Z"
          fill="black"
        />
        <path
          d="M62.7801 53.4175C62.6426 53.4175 62.5051 53.4125 62.3701 53.3975C62.1501 53.3775 61.9876 53.18 62.0101 52.96C62.0301 52.74 62.2251 52.5825 62.4476 52.6C62.5576 52.61 62.6701 52.615 62.7826 52.615C64.9551 52.615 66.7201 50.6575 66.7201 48.2525C66.7201 46.01 65.2051 44.145 63.1951 43.9125C62.9751 43.8875 62.8176 43.6875 62.8426 43.4675C62.8676 43.2475 63.0651 43.095 63.2876 43.115C65.7001 43.395 67.5201 45.6025 67.5201 48.2525C67.5201 51.1 65.3926 53.4175 62.7801 53.4175Z"
          fill="black"
        />
        <path
          d="M54.1001 70.2675C51.4851 70.2675 49.3601 67.95 49.3601 65.1025V36.165C49.3601 33.3175 51.4876 31 54.1001 31C56.7126 31 58.8401 33.3175 58.8401 36.165C58.8401 39.0125 56.7126 41.33 54.1001 41.33C53.8776 41.33 53.7001 41.15 53.7001 40.93C53.7001 40.71 53.8801 40.53 54.1001 40.53C56.2726 40.53 58.0401 38.5725 58.0401 36.165C58.0401 33.7575 56.2726 31.8 54.1001 31.8C51.9276 31.8 50.1601 33.7575 50.1601 36.165V65.1C50.1601 67.5075 51.9276 69.465 54.1001 69.465C56.2726 69.465 58.0401 67.5075 58.0401 65.1C58.0401 64.8775 58.2201 64.7 58.4401 64.7C58.6601 64.7 58.8401 64.88 58.8401 65.1C58.8401 67.9475 56.7126 70.265 54.1001 70.265V70.2675Z"
          fill="black"
        />
        <path
          d="M59.5825 65.5024C56.9675 65.5024 54.8425 63.1849 54.8425 60.3374C54.8425 60.1149 55.0225 59.9374 55.2425 59.9374C55.4625 59.9374 55.6425 60.1174 55.6425 60.3374C55.6425 62.7449 57.41 64.7024 59.5825 64.7024C61.755 64.7024 63.52 62.7449 63.52 60.3374C63.52 59.4574 63.285 58.6099 62.8375 57.8824C62.7225 57.6949 62.78 57.4474 62.9675 57.3324C63.155 57.2149 63.4025 57.2749 63.52 57.4649C64.045 58.3149 64.32 59.3099 64.32 60.3374C64.32 63.1849 62.1925 65.5024 59.58 65.5024H59.5825Z"
          fill="black"
        />
        <path
          d="M63.18 58.0726C62.9575 58.0726 62.78 57.8926 62.78 57.6726C62.78 57.4526 62.96 57.2726 63.18 57.2726C65.2625 57.2726 66.7175 55.5226 66.7175 53.0176C66.7175 52.2426 66.5325 51.4826 66.1825 50.8201C66.08 50.6251 66.155 50.3826 66.35 50.2776C66.545 50.1751 66.7875 50.2501 66.8925 50.4451C67.3025 51.2226 67.52 52.1126 67.52 53.0176C67.52 55.9951 65.735 58.0751 63.1825 58.0751L63.18 58.0726Z"
          fill="black"
        />
        <path d="M49.76 46.84H43.805C43.5825 46.84 43.405 46.66 43.405 46.44C43.405 46.22 43.585 46.04 43.805 46.04H49.76C49.9825 46.04 50.16 46.22 50.16 46.44C50.16 46.66 49.98 46.84 49.76 46.84Z" fill="black" />
        <path d="M56.0576 52.9001H49.7076C49.4851 52.9001 49.3076 52.7201 49.3076 52.5001C49.3076 52.2801 49.4876 52.1001 49.7076 52.1001H56.0576C56.2801 52.1001 56.4576 52.2801 56.4576 52.5001C56.4576 52.7201 56.2776 52.9001 56.0576 52.9001Z" fill="black" />
        <path d="M49.7601 57.385H42.0951C41.8726 57.385 41.6951 57.205 41.6951 56.985C41.6951 56.765 41.8751 56.585 42.0951 56.585H49.7601C49.9826 56.585 50.1601 56.765 50.1601 56.985C50.1601 57.205 49.9801 57.385 49.7601 57.385Z" fill="black" />
        <path d="M45.4375 46.4375C45.4375 47.34 44.705 48.0725 43.8025 48.0725C42.9 48.0725 42.1675 47.34 42.1675 46.4375C42.1675 45.535 42.9 44.8025 43.8025 44.8025C44.705 44.8025 45.4375 45.535 45.4375 46.4375Z" fill="black" />
        <path d="M43.7275 56.9851C43.7275 57.8876 42.995 58.6201 42.0925 58.6201C41.19 58.6201 40.4575 57.8876 40.4575 56.9851C40.4575 56.0826 41.19 55.3501 42.0925 55.3501C42.995 55.3501 43.7275 56.0826 43.7275 56.9851Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_2647_11217">
          <rect width="35.52" height="39.2675" fill="white" transform="translate(32 31)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AIRoleIcon;
