import React from 'react';
import { IActiveAssessmentListFocusPanelLayoutProps } from './active-assessment-focus-panels.models';
import { Anchor, ChevronIcon, Chip, PanelHeader, ListItemLayout, ListLayout } from '@keplerco/core';

export function ActiveAssessmentListFocusPanelLayout(props: IActiveAssessmentListFocusPanelLayoutProps): JSX.Element {
  return (
    <div className="panelContent">
      <PanelHeader title="Assessment progress" subtitle="The assessment results will be available once everyone has completed the process." />

      <ListLayout>
        {props.activeAssessmentList.map(activeAssessment => (
          <ListItemLayout key={activeAssessment.slug} onClick={() => props.onClickAssessment(activeAssessment.slug!)}>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr auto auto`, alignItems: 'center', gap: 15 }}>
              {activeAssessment.name}
              <Chip label="Active" backgroundColour="a" />
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>
        ))}
      </ListLayout>

      <footer className="panelFooter">
        <Anchor onClick={props.onClickBack}>Back</Anchor>
      </footer>
    </div>
  );
}
