import { IconProps } from '@keplerco/core';
import React from 'react';

export function StarFillIcon({ size = 20 }: IconProps): JSX.Element {
  return (
    <svg viewBox="0 0 21 20" width={size} height={size} className="fill">
      <path
        d="M12.2181 0.914543L13.9582 4.44206C14.1967 4.92679 14.6587 5.26193 15.1946 5.33957L19.0875 5.90571C20.4338 6.10074 20.9715 7.75562 19.9964 8.70614L17.1808 11.4517C16.7946 11.8285 16.6166 12.3719 16.7094 12.904L17.374 16.7799C17.6031 18.1204 16.1962 19.1429 14.992 18.5086L11.5099 16.6776C11.0309 16.4258 10.4609 16.4258 9.98188 16.6776L6.4998 18.5086C5.29745 19.1429 3.89061 18.1204 4.11972 16.7799L4.78432 12.904C4.87521 12.3719 4.69912 11.8285 4.31285 11.4517L1.49727 8.70614C0.524031 7.75752 1.05988 6.10263 2.40613 5.90571L6.29909 5.33957C6.83305 5.26193 7.29506 4.92679 7.53553 4.44206L9.27562 0.914543C9.87774 -0.304848 11.6159 -0.304848 12.2181 0.914543Z"
        fill="url(#paint0_linear_1875_168)"
      />

      <path
        d="M15.7551 18.5087C15.5222 18.5087 15.295 18.4519 15.081 18.3383L11.599 16.5073C11.3377 16.3691 11.0423 16.2971 10.745 16.2971C10.4496 16.2971 10.1542 16.3691 9.89105 16.5073L6.40897 18.3383C6.19501 18.4519 5.9678 18.5087 5.7349 18.5087C5.31455 18.5087 4.89799 18.3137 4.62154 17.9842C4.3451 17.6567 4.23338 17.2401 4.30723 16.8122L4.97562 12.9362C5.07787 12.3417 4.88095 11.7339 4.44735 11.3117L1.63177 8.56613C1.42727 8.36732 1.28716 8.11928 1.22656 7.8523C1.16976 7.60047 1.18301 7.33349 1.26443 7.08166C1.34585 6.82983 1.49165 6.6064 1.68668 6.43599C1.89306 6.25422 2.15247 6.13682 2.43459 6.09516L6.32755 5.52902C6.92589 5.44192 7.44091 5.06701 7.70789 4.52548L9.44798 0.997962C9.57484 0.742344 9.76608 0.53217 10.0028 0.392054C10.2262 0.259511 10.4818 0.189453 10.7469 0.189453C11.012 0.189453 11.2676 0.259511 11.491 0.392054C11.7277 0.53217 11.919 0.742344 12.0458 0.997962L13.7859 4.52548C14.0529 5.06701 14.5698 5.44192 15.1662 5.52902L19.0592 6.09706C19.3413 6.13871 19.6007 6.25611 19.8071 6.43788C20.0021 6.60829 20.1479 6.83172 20.2294 7.08355C20.3108 7.33538 20.324 7.60047 20.2672 7.85419C20.2066 8.12306 20.0665 8.36921 19.862 8.56803L17.0464 11.3135C16.6147 11.7358 16.4159 12.3417 16.5182 12.9381L17.1828 16.8141C17.2566 17.242 17.1449 17.6585 16.8685 17.9861C16.592 18.3118 16.1755 18.5087 15.7551 18.5087Z"
        fill="url(#paint1_linear_1875_168)"
      />

      <path
        opacity="0.23"
        d="M15.8763 5.63316C11.7959 7.79171 9.00491 12.0653 8.96515 16.9939L6.41087 18.3364C6.19691 18.45 5.96969 18.5068 5.73679 18.5068C5.31644 18.5068 4.89988 18.3118 4.62344 17.9823C4.34699 17.6548 4.23528 17.2382 4.30912 16.8103L4.97562 12.9362C5.07787 12.3417 4.88095 11.7339 4.44735 11.3117L1.63177 8.56613C1.42727 8.36732 1.28716 8.11928 1.22656 7.8523C1.16976 7.60047 1.18301 7.33349 1.26443 7.08166C1.34585 6.82983 1.49165 6.6064 1.68668 6.43599C1.89306 6.25421 2.15057 6.13682 2.43459 6.09516L6.32566 5.52902C6.924 5.44192 7.43902 5.06701 7.706 4.52548L9.44609 0.997962C9.57295 0.742344 9.76419 0.53217 10.0009 0.392054C10.2243 0.259511 10.4799 0.189453 10.745 0.189453C11.0101 0.189453 11.2657 0.259511 11.4891 0.392054C11.7258 0.53217 11.9171 0.742344 12.0439 0.997962L13.784 4.52548C14.051 5.06701 14.5679 5.44192 15.1643 5.52902L15.8763 5.63316Z"
        fill="#F9F900"
      />

      <defs>
        <linearGradient id="paint0_linear_1875_168" x1="10.7466" y1="-0.282933" x2="10.7466" y2="19.7339" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFDE3F" />
          <stop offset="0.0846299" stopColor="#FFD73C" />
          <stop offset="0.3477" stopColor="#FFC634" />
          <stop offset="0.637" stopColor="#FFBC2F" />
          <stop offset="1" stopColor="#FFB92E" />
        </linearGradient>

        <linearGradient id="paint1_linear_1875_168" x1="10.7467" y1="-0.083821" x2="10.7467" y2="19.5195" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFDE3F" />
          <stop offset="1" stopColor="#FF750E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
