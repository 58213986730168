import React, { useEffect, useState } from 'react';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageHeader } from '@keplerco/core';
import { useAppActions, useAppState } from '../../../overmind';
import Stepper from '../../../components/general/stepper/stepper';
import { Step } from '../../../components/general/stepper/stepper.models';

import { PeopleStepLayout } from './peer/people-step/people-step.layout';
import { AssessmentType } from '../../../enums/assessment-type';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { ActivateStepLayout } from './peer/activate-step/activate-step.layout';

import { SkillsStepLayout } from './peer/skills-step/skills-step.layout';
import { IPerson } from '../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { AssessmentPreferencesResponse } from '../../../models/overmind/assessment-preferences';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';
import { DetailsStepLayout } from './peer/details-step/details-step.layout';
import { useParams } from 'react-router-dom';
import { CompanySkillListItemResponse } from '../../../models/overmind/company-entity';

export interface AssessmentsWizardDetails {
  companySlug: string;
  slug: string | undefined;
  assessmentType: AssessmentType;
  name: string;
  keplerPointsAvailable: number;
}

export interface AssessmentWizardPeople {
  assessmentSlug: string;
  assignedTeamChampionId?: string | undefined;
  userIds: string[];
}

export function AssessmentsWizardPeerPage(): JSX.Element {
  const { companyVariables } = useAppState();
  const params = useParams();
  const actions = useAppActions();
  const keplerNavigate = useKeplerNavigate();

  const [details, setDetails] = useState<AssessmentsWizardDetails>();
  const [preferences, setPreferences] = useState<AssessmentPreferencesResponse>({
    forceAssessment: false,
    minScore: 0,
    maxScore: 0,
  });
  const [roleSlug, setRoleSlug] = useState<string | undefined>();
  const [people, setPeople] = useState<IPerson[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [skills, setSkills] = useState<CompanySkillListItemResponse[]>([]);
  const [applyToAll, setApplyToAll] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [allowedSteps, setAllowedSteps] = useState([1]);
  const [assessmentSlug, setAssessmentSlug] = useState<string>(params.assessmentSlug || '');
  const [steps, setSteps] = useState<Step[]>([]);
  const totalSteps = 4;

  useEffect(() => {
    async function getData() {
      if (!assessmentSlug) return;
      setIsLoading(true);

      const detailsData = await actions.getSkillAssessment({ assessmentSlug, companySlug: companyVariables.slug! });
      if (detailsData) {
        setDetails({
          assessmentType: detailsData.assessmentType,
          companySlug: companyVariables.slug!,
          keplerPointsAvailable: detailsData.keplerPointsAvailable,
          name: detailsData.name!,
          slug: assessmentSlug,
        });
      }

      const preferencesData = await actions.getAssessmentPreferences({ assessmentSlug, companySlug: companyVariables.slug! });
      if (preferencesData) {
        setPreferences(preferencesData);
      }

      const employeesData = await actions.searchPeople({
        companySlug: companyVariables.slug,
        page: 1,
        pageSize: 99999,
      });
      const assigneesData = await actions.getSkillAssessmentAssignees({ companySlug: companyVariables.slug!, skillAssessmentSlug: assessmentSlug });

      const peopleData: IPerson[] = [];
      assigneesData?.assignees?.forEach(assignee => {
        const person = employeesData?.employees.find(employee => employee.id === assignee.userId);
        if (!person) return;
        peopleData.push({
          ...person,
          selected: true,
          manager: person.id === assigneesData.assignedTeamChampionId,
          skills:
            assignee.skills?.map(skill => ({
              slug: skill.companySkillId.toString(),
              name: skill.skillName,
              description: skill.skillDescription,
              level: skill.skillLevel,
              companyEntityId: skill.companySkillId,
            })) ?? [],
        });
      });
      setPeople(peopleData);

      setIsLoading(false);
    }

    getData();
  }, [assessmentSlug]);

  useEffect(() => {
    setSteps([
      {
        stepNumber: 1,
        label: 'Details',
        content: (
          <DetailsStepLayout
            isLoading={isLoading}
            assessmentSlug={assessmentSlug}
            details={details}
            assessmentType={AssessmentType.PeerEndorsement}
            nextPreferences={preferences}
            setDetails={setDetails}
            setAssessmentSlug={setAssessmentSlug}
            setNextPreferences={setPreferences}
            completeStep={() => completeStep(1)}
          />
        ),
        isCompleted: Boolean(details?.name && details.keplerPointsAvailable > 0),
      },
      {
        stepNumber: 2,
        label: 'People',
        content: <PeopleStepLayout assessmentSlug={assessmentSlug!} people={people} preferences={preferences} setPeople={setPeople} completeStep={() => completeStep(2)} goToPreviousStep={goToPreviousStep} setRoleSlug={setRoleSlug} />,
        isCompleted: !!people.length,
      },
      {
        stepNumber: 3,
        label: 'Skills',
        content: (
          <SkillsStepLayout
            skills={skills}
            setSkills={setSkills}
            applyToAll={applyToAll}
            setApplyToAll={setApplyToAll}
            assessmentSlug={assessmentSlug!}
            preferences={preferences}
            roleSlug={roleSlug}
            initialPeople={people}
            people={people}
            setPeople={setPeople}
            completeStep={() => completeStep(3)}
            goToPreviousStep={goToPreviousStep}
          />
        ),
        isCompleted: people.length > 0 && people.every(person => person.skills.length > 0),
      },
      {
        stepNumber: 4,
        label: 'Activate',
        content: <ActivateStepLayout goToPreviousStep={goToPreviousStep} onSaveDraft={handleSaveDraft} onComplete={() => completeStep(4)} assessmentSlug={assessmentSlug!} />,
        isCompleted: false,
      },
    ]);
  }, [details, preferences, assessmentSlug, roleSlug, people, isLoading, applyToAll, skills]);

  useEffect(() => {
    const completedSteps = steps.filter(step => step.isCompleted).map(step => step.stepNumber);

    setAllowedSteps([1, ...completedSteps, completedSteps[completedSteps.length - 1] + 1]);
  }, [steps]);

  function completeStep(stepNumber: number) {
    setSteps(currentSteps => currentSteps.map(step => (step.stepNumber === stepNumber ? { ...step, isCompleted: true } : step)));

    allowStep(stepNumber + 1);

    if (stepNumber < totalSteps) {
      goToNextStep();
    } else {
      handleSubmit();
    }
  }

  function allowStep(stepNumber: number) {
    setAllowedSteps(currentAllowedSteps => {
      if (!currentAllowedSteps.includes(stepNumber)) {
        return [...currentAllowedSteps, stepNumber];
      }
      return currentAllowedSteps;
    });
  }

  function goToNextStep() {
    if (currentStepIndex < totalSteps) {
      setCurrentStepIndex(currentIndex => currentIndex + 1);
    }
  }

  function goToPreviousStep() {
    setCurrentStepIndex(currentIndex => {
      return currentIndex > 1 ? currentIndex - 1 : currentIndex;
    });
  }

  function handleSubmit() {
    keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
  }

  async function handleSaveDraft() {
    keplerNavigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
  }

  return (
    <PageLoaderLayer path={PagePath.assessmentManagementWizardPeer}>
      <div className="wrapper stack" style={{ paddingBottom: 30, gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Assessment Management', url: `${PagePath.assessmentManagementBase}` },
            { name: 'Wizard', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizard.replace(':companySlug', companyVariables.slug!)}` },
            { name: 'Peer Assessment', url: `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardPeer.replace(':companySlug', companyVariables.slug!)}` },
          ]}
          title="Peer Assessment"
          subtitle="Create and customise assessments by selecting skills, roles, and criteria for evaluation"
          divider
        />
        {!!steps.length && <Stepper steps={steps} currentStepIndex={currentStepIndex} goToStep={setCurrentStepIndex} allowedSteps={allowedSteps} />}
      </div>
    </PageLoaderLayer>
  );
}

