import React from 'react';
import { CompletionStatus } from '../../../enums/completion-status';
import { Chip, ThemeColours, dePascalCase } from '@keplerco/core';

export function colourFromStatus(status?: CompletionStatus): [ThemeColours, ThemeColours] {
  if (status === CompletionStatus.Completed) {
    return ['apple', 'contrast-text'];
  } else if (status === CompletionStatus.InProgress) {
    return ['borders', 'contrast-text'];
  } else {
    return ['secondary', 'background'];
  }
}

export function TechnicalSkillsChips(props: { technicalStatus?: CompletionStatus; hasPeerReview?: boolean; peerReviewStatus?: CompletionStatus }): JSX.Element {
  if (props.hasPeerReview) {
    if (props.technicalStatus !== CompletionStatus.Completed && props.technicalStatus !== CompletionStatus.NoActionRequired) {
      const status = CompletionStatus[props.technicalStatus ?? CompletionStatus.NotStarted];
      const [background, text] = colourFromStatus(props.technicalStatus);
      return <Chip label={`${dePascalCase(status)}`} variant="tiny" backgroundColour={background} colour={text} />;
    }

    const status = CompletionStatus[props.peerReviewStatus ?? CompletionStatus.NotStarted];
    const [background, text] = colourFromStatus(props.peerReviewStatus);
    return (
      <Chip
        label={props.peerReviewStatus === CompletionStatus.Completed ? 'Complete' : `Peer-review: ${dePascalCase(status)}`}
        variant="tiny"
        backgroundColour={background}
        colour={text}
      />
    );
  }

  const status = CompletionStatus[props.technicalStatus ?? CompletionStatus.NotStarted];
  const [background, text] = colourFromStatus(props.technicalStatus);
  return <Chip label={`${dePascalCase(status)}`} variant={'tiny'} backgroundColour={background} colour={text} />;
}

export function technicalSkillStatus(technicalStatus?: CompletionStatus, hasPeerReview?: boolean, peerReviewStatus?: CompletionStatus): any {
  if (hasPeerReview) {
    if (technicalStatus === CompletionStatus.NotStarted) {
      return `Includes skill competency and peer rating.`;
    } else if (technicalStatus === CompletionStatus.InProgress) {
      return `You're on your way`;
    } else if (technicalStatus === CompletionStatus.Completed) {
      if (peerReviewStatus === CompletionStatus.NotStarted) {
        return `You're done with self-assessment. You just need to do peer review`;
      } else if (peerReviewStatus === CompletionStatus.InProgress) {
        return `Carry on reviewing your peers`;
      } else if (peerReviewStatus === CompletionStatus.Completed) {
        return `Thank you, we are just waiting for your peers to finish their reviews.`;
      }
    }
  }

  if (technicalStatus === CompletionStatus.Completed) {
    return `All done, thank you`;
  } else {
    return `Rate your skill competencies`;
  }
}




