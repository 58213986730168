import React from 'react';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import accountStyles from '../account.module.css';
import classNames from 'classnames';

export function VerifyEmailPage(): JSX.Element {
  const params = new URLSearchParams(window.location.search);

  return (
    <div className={accountStyles.accountPage}>
      <div className={classNames('card', accountStyles.accountCard)}>
        <AccountPageLoaderLayer path={PagePath.accountVerifyEmail}>
          <object data="/graphics/verify-email.graphic.svg" type="image/svg+xml" style={{ display: 'block', width: '100%', height: 'auto' }}>
            <img src="/graphics/verify-email.graphic.svg" alt="Plane" />
          </object>

          <h1 className="heading1">Verify your email</h1>

          <br />

          <h6 className="subtitle">We've sent a confirmation email to {!!params.get('email') ? <strong>{params.get('email')}</strong> : 'your email'} with a link to activate your account.</h6>

          <br />

          <h6 className="subtitle">Didn't receive the email? Please check your Spam folder.</h6>

          {/* TODO: Add in the following line when we have services to resend email: "If you still don't see it, you can <span>resend the confirmation email</span>." KPLR-1217 */}
        </AccountPageLoaderLayer>
      </div>
    </div>
  );
}
