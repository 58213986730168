import React, { Fragment, useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../overmind';
import { KeplerState } from '../../models/kepler-state';
import { useForm } from 'react-hook-form';
import { ProfileEmailBlock } from './profile-email-block/profile-email-block';
import { OrganizationLevelType } from '../../enums/organization-level';
import { FetchStatus, FetchType } from '../../enums';
import { PagePath } from '../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { getSubdomain } from '../../library/helpers/get-subdomain';
import { SkillScoresRequest, SkillScoresResponse } from '../../models/overmind/analytics';
import { ResponseGroup } from '../../enums/analytics';
import { SkillScoresSearchParams } from '../../models/overmind/search-params';
import { Anchor, AvatarIcon, Button, DropdownSelect, DropdownSelectItem, FieldController, FormControl, LettersValidator, Modal, RequiredValidator, SkillLevel, SkillPercentage, Textfield } from '@keplerco/core';
import { PeriodOfExperience, PeriodOfExperienceDescription } from '../../enums/period-of-experience';
import { Profile } from '../../models/profile';
import styles from './profile.module.css';
import classNames from 'classnames';

type EditModeType = 'none' | 'personal' | 'contact';

export function ProfilePage(): JSX.Element {
  const isCPD = getSubdomain().includes('cpd');

  const actions = useAppActions();
  const { user, fetchState, companyVariables } = useAppState<KeplerState>();

  const { control, setValue, handleSubmit } = useForm<any>({ mode: 'onBlur' });

  const [profile, setProfile] = useState<Profile>();
  const [skillScoresResponse, setSkillScoresResponse] = useState<SkillScoresResponse>();
  const [editMode, setEditMode] = useState<EditModeType>('none');
  const [submittedResetPassword, setSubmittedResetPassword] = useState<boolean>(false);
  const [items, setItems] = useState<DropdownSelectItem[]>([
    { label: PeriodOfExperienceDescription.ZeroToTwo, value: PeriodOfExperience.ZeroToTwo.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.ZeroToTwo) },
    { label: PeriodOfExperienceDescription.TwoToFour, value: PeriodOfExperience.TwoToFour.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.TwoToFour) },
    { label: PeriodOfExperienceDescription.FourToSeven, value: PeriodOfExperience.FourToSeven.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.FourToSeven) },
    { label: PeriodOfExperienceDescription.SevenPlus, value: PeriodOfExperience.SevenPlus.toString(), onClick: () => onClickPeriodOfExperience(PeriodOfExperience.SevenPlus) },
  ]);

  function onClickPeriodOfExperience(periodOfExperience: PeriodOfExperience) {
    setItems(currentState => currentState.map(item => ({ ...item, selected: parseInt(item.value) === periodOfExperience })));
  }

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.profile, type: FetchType.PageFetching });

      const profileResponse = await actions.getProfile();
      setProfile(profileResponse);

      const skillScoresSearchParams: SkillScoresSearchParams = {
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: user?.learnerSlug,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: companyVariables.scoreComparison,
      };
      const skillScoresRequest: SkillScoresRequest = {
        responseGroup: ResponseGroup.Overall,
        searchParams: skillScoresSearchParams,
      };
      const skillScoresResponse = await actions.analyticsGetSkillScores(skillScoresRequest);
      setSkillScoresResponse(!!skillScoresResponse ? skillScoresResponse[0] : undefined);

      actions.stopLoader(PagePath.profile);
    }

    if (!profile || !skillScoresResponse) getData();
  }, []);

  useEffect(() => {
    if (!profile) return;
    setValue('firstName', profile.firstName);
    setValue('lastName', profile.lastName);
    setValue('preferredName', profile.preferredName);
    setValue('jobTitle', profile.jobTitle);
    setValue('periodOfExperience', profile.periodOfExperience);

    setItems(currentState => currentState.map(item => ({ ...item, selected: parseInt(item.value) === profile.periodOfExperience })));
  }, [profile]);

  function toggleEditMode(mode: EditModeType): void {
    setEditMode(editMode === 'none' ? mode : 'none');
  }

  return (
    <PageLoaderLayer path={PagePath.profile}>
      <div className="wrapper pageWrapper" style={{ paddingBottom: 30 }}>
        <header className={styles.profileHeader}>
          <div className={styles.profileHeaderIcon}>
            <AvatarIcon name={{ firstName: user?.firstName, lastName: user?.lastName }} />
          </div>

          <div>
            <h1 className="heading1">
              {profile?.firstName} {profile?.lastName}
            </h1>

            <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
              <h6 className="subtitle" style={{ color: 'var(--accent-2)' }}>
                {profile?.jobTitle}
              </h6>

              <span>|</span>

              {companyVariables.useLevels ? (
                <div style={{ marginTop: 5 }}>
                  <SkillLevel level={skillScoresResponse?.score?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!skillScoresResponse?.score} title="Skill average" />
                </div>
              ) : (
                <SkillPercentage percentage={skillScoresResponse?.score?.percentage ?? 0} noPercentage={!skillScoresResponse?.score} title="Skill average" />
              )}
            </div>
          </div>
        </header>

        <section className={styles.profileBody}>
          <div className="card" style={{ gridArea: 'about' }}>
            <div className={styles.profileCardHeader}>
              <h6 className={classNames('subtitle', styles.profileCardHeaderTitle)}>About</h6>

              {editMode !== 'personal' && <Anchor onClick={() => toggleEditMode('personal')}>Edit Details</Anchor>}
            </div>

            {/* TODO: refactor to FieldControllers and TextFields */}
            <form
              id="saveProfile"
              onSubmit={handleSubmit(async fieldValues => {
                actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                const request: Profile = {
                  ...profile,
                  ...fieldValues,
                  periodOfExperience: parseInt(fieldValues.periodOfExperience),
                };
                const response = await actions.saveProfile(request);
                setProfile(response);
                setEditMode('none');
                actions.stopLoader(PagePath.profile);
              })}
            >
              <div className={styles.profileCardAboutItem}>
                <span className="caption1" style={{ color: 'var(--accent-3)' }}>
                  First name
                </span>

                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="firstName"
                    control={control}
                    rules={new LettersValidator('Enter your name')}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{profile?.firstName}</p>
                )}
              </div>

              <div className={styles.profileCardAboutItem}>
                <span className="caption1" style={{ color: 'var(--accent-3)' }}>
                  Last name
                </span>

                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="lastName"
                    control={control}
                    rules={new LettersValidator('Enter your surname')}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{profile?.lastName}</p>
                )}
              </div>

              <div className={styles.profileCardAboutItem}>
                <span className="caption1" style={{ color: 'var(--accent-3)' }}>
                  Preferred name
                </span>

                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="preferredName"
                    rules={new LettersValidator('Enter your preferred name')}
                    control={control}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{profile?.preferredName}</p>
                )}
              </div>

              <div className={styles.profileCardAboutItem}>
                <span className="caption1" style={{ color: 'var(--accent-3)' }}>
                  Job title
                </span>

                {editMode === 'personal' ? (
                  <FormControl
                    paddingBottom={0}
                    name="jobTitle"
                    rules={new RequiredValidator('Enter your job title')}
                    control={control}
                    render={({ field, fieldState }) => {
                      return <Textfield {...field} haserror={!!fieldState.error} type="text" responsive />;
                    }}
                  />
                ) : (
                  <p className="body1">{profile?.jobTitle}</p>
                )}
              </div>

              <div className={styles.profileCardAboutItem}>
                {editMode === 'personal' ? (
                  <FieldController
                    name="periodOfExperience"
                    control={control}
                    rules={new RequiredValidator('Select years of experience')}
                    render={({ field, fieldState }) => (
                      <DropdownSelect
                        {...field}
                        label="Years of experience"
                        items={items}
                        value={field.value.toString()}
                        responsive
                        validation={{
                          dirty: fieldState.isDirty || !!fieldState.error,
                          invalid: fieldState.invalid,
                          message: fieldState.error?.message ?? 'Select years of experience',
                        }}
                      />
                    )}
                  />
                ) : (
                  <Fragment>
                    {profile?.periodOfExperience !== undefined && (
                      <Fragment>
                        <span className="caption1" style={{ color: 'var(--accent-3)' }}>
                          Years of experience
                        </span>
                        <p className="body1">{items.find(item => parseInt(item.value) === profile.periodOfExperience)?.label}</p>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>

              {editMode === 'personal' && (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button type="button" filled square>
                    Save Details
                  </Button>
                </div>
              )}
            </form>
          </div>

          {!!profile && (
            <div className="card" style={{ gridArea: 'contact' }}>
              <div className={styles.profileCardHeader}>
                <h6 className={classNames('subtitle', styles.profileCardHeaderTitle)}>Contact</h6>
              </div>

              <div className={styles.profileCardAboutItem}>
                <ProfileEmailBlock email={profile.email} isPrimary={true} hideChip={isCPD} verificationDate={new Date()} />

                {profile.emails?.map(userEmail => (
                  <ProfileEmailBlock
                    key={userEmail.id}
                    email={userEmail.email}
                    isPrimary={false}
                    hideChip={isCPD}
                    verificationDate={userEmail.dateConfirmed}
                    onResendVerification={async () => {
                      actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                      await actions.resendVerification(userEmail.email);
                      setProfile(await actions.getProfile());
                      actions.stopLoader(PagePath.profile);
                    }}
                    onMakePrimary={async () => {
                      actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                      await actions.changePrimaryEmail(userEmail.email);
                      setProfile(await actions.getProfile());
                      actions.stopLoader(PagePath.profile);
                    }}
                    onDelete={async () => {
                      actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                      await actions.removeEmail(userEmail);
                      setProfile(await actions.getProfile());
                      actions.stopLoader(PagePath.profile);
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="card" style={{ gridArea: 'security' }}>
            <h6 className={classNames('subtitle', styles.profileCardHeaderTitle)}>Security</h6>

            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
              <div>
                <span>Password</span>
                <p className="body1">********************</p>
              </div>

              <Anchor
                color="link-text"
                onClick={async () => {
                  if (profile?.email !== undefined && fetchState[PagePath.profile].status === FetchStatus.Inactive) {
                    actions.startLoader({ path: PagePath.profile, type: FetchType.Sending });
                    await actions.forgotPassword({ email: profile.email });
                    setSubmittedResetPassword(true);
                    actions.stopLoader(PagePath.profile);
                  }
                }}
              >
                Change Password
              </Anchor>
            </div>
          </div>
        </section>
      </div>

      <Modal open={submittedResetPassword} onClose={() => setSubmittedResetPassword(false)}>
        <div className="modalContent" style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
          <h2 className="heading2">We've emailed a password reset link to you</h2>

          <h6 className="subtitle">Please follow the link to set a new password.</h6>

          <footer style={{ textAlign: 'center' }}>
            <Button filled type="button" onClick={() => setSubmittedResetPassword(false)}>
              GOT IT
            </Button>
          </footer>
        </div>
      </Modal>
    </PageLoaderLayer>
  );
}
