import React, { useMemo } from 'react';
import pkg from '../../../package.json';
import { PageLoaderLayer } from '../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../navigation/navigation.enums';
import { UnorderedList, UnorderedListItem } from '@keplerco/core';

export function VersionPage(): JSX.Element {
  const items = useMemo(() => {
    return Object.entries(pkg.dependencies);
  }, [pkg.dependencies]);

  return (
    <PageLoaderLayer path={PagePath.version}>
      <div style={{ width: '80%', maxWidth: 650, margin: 'auto', paddingBottom: 30 }}>
        <h2 className="heading2" style={{ marginBottom: 30 }}>
          Application Version v{pkg.version}
        </h2>

        <UnorderedList>
          {items.map(([name, version], index) => {
            return (
              <UnorderedListItem key={index.toString()}>
                {name}
                <span style={{ opacity: 0.75 }}>@{version}</span>
              </UnorderedListItem>
            );
          })}
        </UnorderedList>
      </div>
    </PageLoaderLayer>
  );
}
