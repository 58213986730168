import { QuestionnaireQuestion, QuestionnaireSkill } from '../../models/questionnaire';

export function sumAllSubQuestions(questionSections: QuestionnaireSkill[]): [number, number, number] {
  const totalCount = questionSections.length;
  const completeCount = questionSections.filter(i => i.isComplete).length;
  const incompleteSections = totalCount - completeCount;
  return [completeCount, totalCount, incompleteSections];
}

export function firstUnfinishedOrLast(questionSections: QuestionnaireSkill[]): number {
  return Math.max(questionSections.findIndex(i => !i.isComplete) ?? questionSections.length - 1, 0);
}

export function setQuestionSelectionStatus(question: QuestionnaireQuestion, index: number, value?: boolean) {
  const current = question.answers[index];
  const newValue = { ...current, selected: value ?? !current.selected };
  question.answers[index] = newValue;

  return newValue;
}
