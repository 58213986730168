import { ISunburstConfigItem } from '@keplerco/core';
import { UrgencyIndication } from '../../enums/analytics';
import { Score } from '../../models/view/score';

export interface YourSkillsSunburstSlice extends ISunburstConfigItem {
  urgencyIndication: UrgencyIndication | undefined;
  score: Score | undefined;
  comparisonScore: Score | undefined;
}
export enum SkillTypeName {
  Behavioural = 'Behavioral',
  Technical = 'Technical',
  Combined = 'Combined',
  Other = 'Other',
}
