import React from 'react';
import { AnalyticsReportTemplateLayout } from './report-template.layout';
import { PagePath } from '../../../../navigation/navigation.enums';
import { useParams } from 'react-router';

export function ReportTemplatePage(): JSX.Element {
  const { reportSlug } = useParams<any>();

  return <AnalyticsReportTemplateLayout path={PagePath.analyticsReportTemplate} slug={reportSlug!} name="Report" />;
}
