import React, { useEffect, useReducer } from 'react';
import { FetchStatus, FetchType } from '../../../enums';
import * as store from './additional-learning.store';
import { useAppActions, useAppState } from '../../../overmind';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { AdditionalLearningFocusPanelLayout } from './additional-learning-focus-panel/additional-learning-focus-panel.layout';
import { AdditionalLearningTypeEnum } from './additional-learning.models';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { ConfirmationModalLayout } from '../../../widgets/layouts/modal-layouts/confirmation-modal/confirmation-modal.layout';
import { ReflectionFocusPanelLayout } from '../reflections/reflection-focus-panel.layout';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Button, CascadingPanel, CascadingPanels, DeleteIcon, EditIcon, formatDate, IconButton, KeplerPoints, ListItemLayout, ListLayout, Modal, PageHeader, Searchfield, useCascadingPanelsConnector } from '@keplerco/core';
import { useDataSyncReaction } from '../../../library/hooks/useDataSyncReaction';
import layoutStyles from '../../../design/layout.module.css';
import classNames from 'classnames';
import styles from './additional-learning.module.css';

function mapLearningTypeEnum(value: AdditionalLearningTypeEnum) {
  if (value === AdditionalLearningTypeEnum.InHouse) {
    return 'In-house training';
  } else if (value === AdditionalLearningTypeEnum.ShortCourse) {
    return 'Short course';
  } else if (value === AdditionalLearningTypeEnum.Online) {
    return 'Online course';
  }

  return AdditionalLearningTypeEnum[value];
}

enum CascadingPanelIds {
  AdditionalLearning = 'AdditionalLearning',
  Reflection = 'Reflection',
}

export function AdditionalLearningPage(): JSX.Element {
  const actions = useAppActions();
  const { fetchState } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const { openPanelIds, next, close, closeAll } = useCascadingPanelsConnector();

  useDataSyncReaction(async () => {
    getData();
  });

  async function getData(type?: FetchType): Promise<void> {
    if (type !== undefined) actions.startLoader({ path: PagePath.learningJourneyAdditionalLearning, type: type });

    const data = await actions.getAdditionalLearningList(state.request);
    dispatch({ type: store.AdditionalLearningActionTypes.SetData, payload: data });

    if (type !== undefined) actions.stopLoader(PagePath.learningJourneyAdditionalLearning);
  }

  useEffect(() => {
    getData(!state.data ? FetchType.PageFetching : FetchType.Custom);
  }, [state.request?.searchTerm]);

  function closeReflections() {
    closeAll();
    getData(FetchType.PageFetching);
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.learningJourneyAdditionalLearning}>
        <div className="wrapper stack" style={{ gap: 30 }}>
          <PageHeader
            breadcrumbs={[
              {
                name: 'Learning Journey',
                url: PagePath.learningJourneyBase,
              },
              {
                name: 'Additional Learning',
                url: window.location.pathname,
              },
            ]}
            title="Additional Learning"
            subtitle="Learning can take place anywhere, so if you've completed any in-person sessions, webinars, talks or learning from places outside of this platform, you can track it here. It's also important to reflect on your learning to validate your existing knowledge and skills."
            divider
          />

          <div className={layoutStyles.filtersGroup}>
            <Searchfield
              loading={fetchState[PagePath.learningJourneyAdditionalLearning].type === FetchType.Custom && fetchState[PagePath.learningJourneyAdditionalLearning].status === FetchStatus.Active}
              onInput={searchTerm => dispatch({ type: store.AdditionalLearningActionTypes.SetRequest, payload: !!searchTerm ? { searchTerm } : {} })}
            />

            <Button
              filled
              type="button"
              arrow={false}
              onClick={() => {
                dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: true } });
                next(CascadingPanelIds.AdditionalLearning);
              }}
            >
              Track Additional Learning
            </Button>
          </div>

          {!!state.data?.length ? (
            <ListLayout>
              {state.data.map(item => {
                return (
                  <ListItemLayout key={item.slug}>
                    <div className={classNames('card', styles.card)}>
                      <div className={styles.delete}>
                        <IconButton iconType="fill" hoverTone="light" onClick={async () => dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: item })}>
                          <DeleteIcon tone="default" />
                        </IconButton>
                      </div>

                      <div className={styles.content}>
                        <div className={classNames('caption1', styles.title)}>{item.name}</div>

                        <div className="caption1" style={{ fontWeight: 700 }}>
                          {mapLearningTypeEnum(item.type)}
                        </div>

                        <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                          Added: {formatDate(new Date(item.completedOn))}
                        </div>

                        <div className={classNames('caption1', styles.keplerPoints)}>
                          <KeplerPoints trimWhiteSpace points={item.keplerPoints} /> Earned
                        </div>
                      </div>

                      <div className={styles.edit}>
                        <IconButton
                          iconType="stroke"
                          hoverTone="light"
                          onClick={async () => {
                            dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: true, data: item.slug } });
                            next(CascadingPanelIds.AdditionalLearning);
                          }}
                        >
                          <EditIcon tone="default" size={30} />
                        </IconButton>
                      </div>
                    </div>
                  </ListItemLayout>
                );
              })}
            </ListLayout>
          ) : (
            <EmptyState
              badgeIconWidth={450}
              badgeUrl={themedAssetUrl('graphics/empty-state-add.graphic.svg')}
              title="No learning activities have been assigned yet"
              subtitle="Have you completed any in-person sessions, webinars, talks or learning from places outside of this platform?"
              buttons={[
                {
                  text: 'Track Additional Learning',
                  onClick: () => {
                    dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: true } });
                    next(CascadingPanelIds.AdditionalLearning);
                  },
                },
              ]}
            />
          )}
        </div>
      </PageLoaderLayer>

      {/* TODO: unify additional learning and reflection panel combinations into a component */}
      <CascadingPanels
        openPanelIds={openPanelIds}
        onClosePanel={(id: string) => {
          switch (id as CascadingPanelIds) {
            case CascadingPanelIds.AdditionalLearning: {
              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });
              break;
            }

            case CascadingPanelIds.Reflection: {
              closeReflections();
              break;
            }
          }

          close(id);
        }}
      >
        <CascadingPanel id={CascadingPanelIds.AdditionalLearning}>
          <AdditionalLearningFocusPanelLayout
            slug={state.additionalLearningToEdit.data}
            path={PagePath.learningJourneyAdditionalLearning}
            onCancel={() => {
              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });
              closeAll();
            }}
            onSubmit={async (model, doReflection) => {
              actions.startLoader({ path: PagePath.learningJourneyAdditionalLearning, type: FetchType.Sending });

              const result = await actions.saveAdditionalLearning(model);

              if (!doReflection || !result?.reflectionId) {
                getData(FetchType.PageFetching);
                closeAll();
                dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });
                return;
              }

              dispatch({ type: store.AdditionalLearningActionTypes.SetReflectionId, payload: result.reflectionId });
              next(CascadingPanelIds.Reflection);
              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToEdit, payload: { show: false } });

              actions.stopLoader(PagePath.learningJourneyAdditionalLearning);

              console.log('onSubmit');
            }}
          />
        </CascadingPanel>

        <CascadingPanel id={CascadingPanelIds.Reflection}>
          <ReflectionFocusPanelLayout reflectionId={state.reflectionId} path={PagePath.learningJourneyAdditionalLearning} onCancel={closeReflections} onSubmit={closeReflections} />
        </CascadingPanel>
      </CascadingPanels>

      <Modal open={!!state.additionalLearningToDelete} onClose={() => dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: undefined })}>
        {!!state.additionalLearningToDelete && (
          <ConfirmationModalLayout
            title="Remove additional learning"
            subtitle={`"${mapLearningTypeEnum(state.additionalLearningToDelete.type)}: ${state.additionalLearningToDelete.name}"?`}
            cancelButtonText="No, cancel"
            onClickCancel={() => dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: undefined })}
            submitButtonText="Yes, remove it"
            textAlign="left"
            onClickSubmit={async () => {
              if (!!state.additionalLearningToDelete) {
                actions.startLoader({ path: PagePath.learningJourneyAdditionalLearning, type: FetchType.Sending });
                await actions.deleteAdditionalLearning(state.additionalLearningToDelete.slug);
                getData(FetchType.PageFetching);
              }

              dispatch({ type: store.AdditionalLearningActionTypes.SetAdditionalLearningToDelete, payload: undefined });
            }}
          >
            <h5 className="heading4" style={{ fontWeight: 400 }}>
              Are you sure you want to remove{' '}
              <strong>
                {mapLearningTypeEnum(state.additionalLearningToDelete.type)}: {state.additionalLearningToDelete.name}
              </strong>
              ?
            </h5>
          </ConfirmationModalLayout>
        )}
      </Modal>
    </React.Fragment>
  );
}
