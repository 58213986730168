import classNames from 'classnames';
import React, { Fragment, useState } from 'react';
import { QuestionnaireAnswer } from '../../../models/questionnaire';
import { IMultipleChoiceQuestionProps } from './multiple-choice-question.models';
import { safeCallback } from '@keplerco/core';
import styles from './multiple-choice-question.module.css';

export function MultipleChoiceQuestion(props: IMultipleChoiceQuestionProps): JSX.Element {
  const { isSaving = false } = props;
  const [currentlySelected, setCurrentlySelected] = useState<QuestionnaireAnswer | undefined>(props.answers.find(d => d.selected));

  return (
    <Fragment>
      <div className="heading5" style={{ lineHeight: '1.33rem', marginBottom: 10 }}>
        {props.question}
      </div>

      <ul style={{ padding: 0, listStyle: 'none' }}>
        {props.answers.map(a => {
          const active = currentlySelected?.id === a.id;

          return (
            <li
              key={a.id}
              className={classNames(styles.answer, { [styles.active]: active, [styles.saving]: isSaving && active, [styles.fade]: isSaving && !active })}
              onClick={() => {
                if (isSaving) return;
                setCurrentlySelected(a);
                if (a.id !== currentlySelected?.id) {
                  safeCallback(props.onSelection, a, () => {
                    setCurrentlySelected(void 0);
                  });
                }
              }}
            >
              {a.description}
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
}
