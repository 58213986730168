import { Context } from '../..';
import { BulkUploadType } from '../../../enums/bulk-upload-type';
import { BulkUploadDto, BulkUploadRow } from '../../../models/bulk-uploads/bulk-upload-dto';
import * as base from '../base';

// note: can use function overloading here when other types are introduced

export async function bulkUpload(
  context: Context,
  payload: {
    data: BulkUploadRow[];
    bulkUploadType: BulkUploadType;
    companySlug: string;
  }
): Promise<base.IResponse<BulkUploadDto<any>>> {
  const request: base.IRequest = {
    method: 'POST',
    url: base.apiURL(`bulkupload`, payload.bulkUploadType, `upload?companySlug=${payload.companySlug}`),
    body: JSON.stringify(payload.data),
    authenticated: true,
    headers: { 'Content-Type': 'application/json' },
  };

  const response: base.IResponse<BulkUploadDto<any>> = await base.request(request);
  return response;
}

export async function bulkUploadValidate(
  context: Context,
  payload: {
    file: File;
    bulkUploadType: BulkUploadType;
    companySlug: string;
  }
): Promise<base.IResponse<BulkUploadDto<any>>> {
  const formData: FormData = new FormData();
  formData.append('File', payload.file);

  const request: base.IRequest = {
    method: 'POST',
    url: base.apiURL(`bulkupload`, payload.bulkUploadType, `validate?companySlug=${payload.companySlug}`),
    body: formData,
    authenticated: true,
    headers: { Accept: 'multipart/form-data' },
  };

  const response: base.IResponse<BulkUploadDto<any>> = await base.request(request);
  return response;
}
