import React from 'react';
import { useForm } from 'react-hook-form';
import { IReportManagementProps } from './report-management';
import { PagePath } from '../../navigation/navigation.enums';
import { FocusPanelLoaderLayer } from '../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { FetchType } from '../../enums';
import { useAppActions, useAppState } from '../../overmind';
import { Anchor, Button, PanelHeader, FormControl, RequiredValidator, Textfield, Toggle, kebabCase, useMatchScreenWidth } from '@keplerco/core';
import reportManagementStyles from './report-management.module.css';
import classNames from 'classnames';

interface RequestModel {
  companySlug: string;
  reportType: string;
  body: { name: string; description: string };
}

export function ReportManagementFocusPanel(props: IReportManagementProps): JSX.Element {
  const { report, isLoading, tempValue, handleToggle } = props;

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const { control, handleSubmit } = useForm<any>({ mode: 'all', reValidateMode: 'onChange', defaultValues: props.report });

  async function onSubmitHandler(value: any) {
    const request: RequestModel = {
      companySlug: companyVariables.slug!,
      reportType: report!.reportType,
      body: { name: value.name, description: value.description },
    };

    actions.startLoader({ path: PagePath.reportManagement, type: FetchType.DialogFetching });
    await actions.editReportTemplate(request);

    props.onSubmit();

    actions.stopLoader(PagePath.reportManagement);
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.reportManagement}>
      <div className="panelContent panelForm">
        <PanelHeader supertitle="Report Management" title="Report details" subtitle="Edit report details" divider />

        <form id="editReport" className="panelForm" onSubmit={handleSubmit(onSubmitHandler)}>
          <div>
            {isMobile && (
              <div className={classNames('card', reportManagementStyles.toggleCard)}>
                <h6 className="subtitle" style={{ whiteSpace: 'nowrap' }}>
                  Report status
                </h6>

                <div className={reportManagementStyles.toggleCardDivider} />

                <div className={reportManagementStyles.toggle} onClick={event => event.stopPropagation()}>
                  OFF
                  <Toggle id={`${kebabCase(report.name)}-report`} name="Report" value={tempValue ?? report.isVisibleToCompany} isLoading={isLoading} clickableArea="toggle" toggleBackgroundColour="primary" toggleForegroundColour="white" size="large" onChange={handleToggle} />
                  ON
                </div>
              </div>
            )}

            <div className="row">
              <div className="column">
                <FormControl
                  control={control}
                  rules={new RequiredValidator('Enter report name')}
                  name="name"
                  paddingBottom={15}
                  render={({ field, fieldState }) => {
                    return <Textfield {...field} haserror={!!fieldState.error} label="Report Name" autoFocus responsive />;
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormControl
                  control={control}
                  name="description"
                  paddingBottom={15}
                  render={({ field }) => {
                    return <Textfield {...field} label="Description (Optional)" responsive />;
                  }}
                />
              </div>
            </div>
          </div>

          <footer className="panelFooter">
            <Anchor onClick={props.onCancel}>Cancel</Anchor>

            <Button type="button">Submit</Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}
