import React, { Fragment, useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { ActiveAssessmentFocusPanelLayout } from './active-assessment-focus-panels/active-assessment-focus-panel.layout';
import { ActiveAssessmentListFocusPanelLayout } from './active-assessment-focus-panels/active-assessment-list-focus-panel.layout';
import { UniqueEntity } from '../../../models';
import { ActionCard, CascadingPanel, CascadingPanels, PageHeader, useCascadingPanelsConnector } from '@keplerco/core';
import { SkillsBreakdownWidget, DailyActivityWidget, LeaderboardWidget } from '../../../widgets';
import { CourseEngagementWidget } from '../../../widgets/analytics/overview/course-engagement.widget';
import { EntityAverageWidget } from '../../../components/general/average/entity-average/entity-average.widget';
import { HeatmapWidget } from '../../../widgets/analytics/heatmap/heatmap.widget';
import RankedEntitiesWidget from '../../../widgets/analytics/ranked-entities/ranked-entities.widget';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';
import analyticsStyles from '../analytics.module.css';
import classNames from 'classnames';

enum CascadingPanelIds {
  ActiveAssessmentList = 'ActiveAssessmentList',
  ActiveAssessment = 'ActiveAssessment',
}

export function AnalyticsOverviewPage(): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput, companyVariables } = useAppState();

  const { openPanelIds, next, close } = useCascadingPanelsConnector();

  const [entity, setEntity] = useState<PermissionsEntity>();
  const [activeAssessmentList, setActiveSkillAssessmentList] = useState<UniqueEntity[]>();
  const [activeAssessmentSlug, setActiveAssessmentSlug] = useState<string>();

  useEffect(() => {
    async function getData() {
      const tempActiveAssessmentList = await actions.getActiveSkillAssessmentList(companyVariables.slug!);
      setActiveSkillAssessmentList(tempActiveAssessmentList);
    }

    getData();
  }, []);

  function onClickHandler() {
    if (!activeAssessmentList?.length) return;

    if (activeAssessmentList.length === 1) {
      setActiveAssessmentSlug(activeAssessmentList[0].slug);
      next(CascadingPanelIds.ActiveAssessmentList);
      return;
    }

    next(CascadingPanelIds.ActiveAssessmentList);
  }

  return (
    <div className={classNames('pageWrapper wrapper', analyticsStyles.page)}>
      <PageHeader
        breadcrumbs={[
          { name: 'Analytics', url: PagePath.analyticsBase },
          { name: 'Overview', url: window.location.pathname },
        ]}
        title="Overview"
        type="actions"
        divider
      >
        <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

          <Daterange
            defaultValue={dateRangeOutput}
            onDaterangeChange={result => {
              actions.setGlobalDateRange(result);
            }}
          />
        </div>
      </PageHeader>

      {!!activeAssessmentList && activeAssessmentList.length > 0 && (
        <Fragment>
          <ActionCard
            icon="/assessments-pending.svg"
            title="Assessments in progress"
            description="Your team has outstanding assessments"
            action={{
              type: `anchor`,
              text: `View Progress`,
              onClick: onClickHandler,
            }}
          />

          <CascadingPanels
            openPanelIds={openPanelIds}
            onClosePanel={id => {
              if (id === CascadingPanelIds.ActiveAssessment) setActiveAssessmentSlug(undefined);
              close(id);
            }}
          >
            <CascadingPanel id={CascadingPanelIds.ActiveAssessmentList}>
              <ActiveAssessmentListFocusPanelLayout
                activeAssessmentList={activeAssessmentList}
                onClickAssessment={activeAssessment => {
                  setActiveAssessmentSlug(activeAssessment);
                  next(CascadingPanelIds.ActiveAssessment);
                }}
                onClickBack={() => close(CascadingPanelIds.ActiveAssessmentList)}
              />
            </CascadingPanel>

            <CascadingPanel id={CascadingPanelIds.ActiveAssessment}>
              <ActiveAssessmentFocusPanelLayout activeAssessmentSlug={activeAssessmentSlug} onClick={() => close(CascadingPanelIds.ActiveAssessment)} />
            </CascadingPanel>
          </CascadingPanels>
        </Fragment>
      )}

      <div className={analyticsStyles.oneTwoGrid}>
        <EntityAverageWidget entity={entity} path={PagePath.analyticsOverview} />

        <SkillsBreakdownWidget entity={entity} path={PagePath.analyticsOverview} />
      </div>

      <HeatmapWidget entity={entity} />

      <RankedEntitiesWidget rankingLabel="employees" entity={entity} />

      <CourseEngagementWidget entity={entity} isLearner={false} />

      <DailyActivityWidget entity={entity} />

      <div className={analyticsStyles.twoOneGrid}>
        <LeaderboardWidget entity={entity} />
      </div>
    </div>
  );
}
