import React, { useEffect, useReducer } from 'react';
import { FetchStatus, FetchType } from '../../../enums';
import { TeamListItem } from '../../../models/view/team-list-item';
import { useAppActions, useAppState } from '../../../overmind';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { ManageTeamFocusPanelCMSLayout } from './manage-team-focus-panel.cms.layout';
import * as store from './teams.cms.store';
import { createDepartmentLink, createTeamLink } from './teams.cms.helpers';
import { ITeamsCMSLayoutProps } from './teams.cms.models';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { PagerConnector } from '../../../components/general/pager-connector/pager-connector';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { ColumnConfiguratorWidget, ConfirmationModalLayout } from '../../../widgets/layouts';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { AddTwoToneIcon, Anchor, Button, KebabMenu, MenuItem, Modal, PageHeader, Pager, Panel, Searchfield, Table, TableColumn, TableRow } from '@keplerco/core';
import { useKeplerNavigate } from '../../../navigation/guards/use-kepler-navigate';

export function TeamsCMSLayout(props: ITeamsCMSLayoutProps): JSX.Element {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { fetchState } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  async function updateData(request: BaseSearchRequest) {
    const data = await actions.searchTeams(request);

    dispatch({ type: store.TeamsCMSActionTypes.SetData, payload: data });

    actions.stopLoader(props.path);
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: props.path, type: FetchType.PageFetching });

      dispatch({ type: store.TeamsCMSActionTypes.SetHeader, payload: props.slugs });

      const request: BaseSearchRequest = { ...state.request, ...props.slugs };
      dispatch({ type: store.TeamsCMSActionTypes.SetRequest, payload: request });

      await updateData(request);
    }

    initPage();
  }, []);

  useEffect(() => {
    if (!state.data) return;
    updateData(state.request);
  }, [state.request]);

  // tables
  async function onInputHandler(value: string) {
    actions.startLoader({ path: props.path, type: FetchType.Custom });
    dispatch({ type: store.TeamsCMSActionTypes.SetRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  async function onClickTeamHandler(teamSlug: string) {
    if (!teamSlug) return;
    dispatch({ type: store.TeamsCMSActionTypes.SetTeamToManage, payload: { show: true, data: teamSlug } });
  }

  async function archiveTeam(teamSlug: string) {
    actions.startLoader({ path: props.path, type: FetchType.Sending });
    await actions.archiveTeam(teamSlug);
    closeConfirmationModal();
    actions.stopLoader(props.path);
  }

  async function onPageChangeHandler(page: number) {
    if (page === state.request?.page) return;

    actions.startLoader({ path: props.path, type: FetchType.PageFetching });
    dispatch({ type: store.TeamsCMSActionTypes.SetRequest, payload: { ...state.request, page } });
  }

  // kebab menus
  function generateKebabMenuItems(team: TeamListItem): MenuItem[] {
    const kebabMenuItems: MenuItem[] = [
      {
        label: 'Manage people',
        onClick: () => keplerNavigate(createTeamLink(props.slugs.companySlug!, team.teamSlug)),
      },
      {
        label: 'Manage team',
        onClick: () => onClickTeamHandler(team.teamSlug),
      },
      {
        label: 'Archive team',
        onClick: () => dispatch({ type: store.TeamsCMSActionTypes.SetArchiveTeam, payload: team }),
        disabled: team.totalUsers !== 0,
      },
    ];

    return kebabMenuItems;
  }

  // focus panels
  async function onCloseManageTeamFocusPanelHandler() {
    dispatch({ type: store.TeamsCMSActionTypes.SetTeamToManage, payload: { data: void 0, show: false } });
    await updateData(state.request);
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: store.TeamsCMSActionTypes.ManageColumns, payload: { open: false, reset: false } });
  }

  function onClickResetManagingColumns() {
    dispatch({ type: store.TeamsCMSActionTypes.ManageColumns, payload: { open: true, reset: true } });
  }

  function onClickSubmitManagingColumns(selection: ColumnConfiguration[]) {
    dispatch({ type: store.TeamsCMSActionTypes.EditColumnConfiguration, payload: selection });
    dispatch({ type: store.TeamsCMSActionTypes.ManageColumns, payload: { open: false } });
  }

  const onSortHandler = async (column: any) => {
    const isCurrentlyAscending = state.request?.sortAscending;
    const currentSortField = state.request?.sortField;

    const newSortDirection = currentSortField === column ? !isCurrentlyAscending : true;

    dispatch({
      type: store.TeamsCMSActionTypes.SetRequest,
      payload: {
        ...state.request,
        sortField: column,
        sortAscending: newSortDirection,
      },
    });
  };

  function closeConfirmationModal() {
    updateData(state.request);
    dispatch({ type: store.TeamsCMSActionTypes.SetArchiveTeam, payload: void 0 });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={props.path}>
        <div className="wrapper stack" style={{ gap: 30 }}>
          <PageHeader breadcrumbs={state.crumbs} title={state.pageTitle} />

          <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: 15 }}>
            <Searchfield loading={fetchState[props.path].status === FetchStatus.Active && fetchState[props.path].type === FetchType.Custom} onInput={onInputHandler} />

            <Button type="button" square theme="dark" onClick={() => dispatch({ type: store.TeamsCMSActionTypes.SetTeamToManage, payload: { show: true } })}>
              <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                <AddTwoToneIcon />
                Create a team
              </div>
            </Button>
          </div>

          <div style={{ width: '100%' }}>
            <Table
              currentSortBy={String(state.request?.sortField)}
              onSort={onSortHandler}
              currentSortDirection={state.request?.sortAscending ? 'Ascending' : 'Descending'}
              onConfigClicked={() => dispatch({ type: store.TeamsCMSActionTypes.ManageColumns, payload: { open: true } })}
              emptyState={<EmptyState title="No teams found" buttons={[{ text: 'Create a team', onClick: () => dispatch({ type: store.TeamsCMSActionTypes.SetTeamToManage, payload: { show: true } }) }]} />}
              footerContent={
                <PagerConnector onPageChange={onPageChangeHandler} defaultPageNumber={state.request.page ?? 1} pageCount={state.data?.totalPages ?? 1}>
                  {connector => {
                    return <Pager {...connector} />;
                  }}
                </PagerConnector>
              }
            >
              {state.data?.teams.map(team => (
                <TableRow id={team.teamSlug} key={team.teamSlug} configCell={() => <KebabMenu items={generateKebabMenuItems(team)} />}>
                  <TableColumn id="team" label="Team name">
                    {!!team.teamSlug && (
                      <Anchor
                        hovertype="opacity"
                        textTransform="none"
                        onClick={event => {
                          event.stopPropagation();
                          keplerNavigate(createTeamLink(props.slugs.companySlug!, team.teamSlug));
                        }}
                      >
                        {team.teamName}
                      </Anchor>
                    )}

                    {!team.teamSlug && <>{team.teamName}</>}
                  </TableColumn>

                  <TableColumn sortable id="department" label="Department" hidden={!state.columnConfiguration.find(i => i.key === 'department')?.selected}>
                    <Anchor
                      hovertype="opacity"
                      textTransform="none"
                      onClick={event => {
                        event.stopPropagation();
                        keplerNavigate(createDepartmentLink(props.slugs.companySlug!, team.departmentSlug));
                      }}
                    >
                      {team.departmentName}
                    </Anchor>
                  </TableColumn>

                  <TableColumn id="dateCreated" label="Date added" hidden={!state.columnConfiguration.find(i => i.key === 'dateCreated')?.selected}>
                    {team.dateCreated.toLocaleString('en-UK', { dateStyle: 'long' })}
                  </TableColumn>

                  <TableColumn id="people" label="People" hidden={!state.columnConfiguration.find(i => i.key === 'people')?.selected}>
                    {team.totalUsers}
                  </TableColumn>
                </TableRow>
              ))}
            </Table>
          </div>
        </div>
      </PageLoaderLayer>

      <Panel open={!!state.teamToManage?.show} onClose={onCloseManageTeamFocusPanelHandler}>
        {state.teamToManage?.show && <ManageTeamFocusPanelCMSLayout teamSlug={state.teamToManage.data} path={props.path} onClick={onCloseManageTeamFocusPanelHandler} />}
      </Panel>

      <Panel open={!!state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget onReset={onClickResetManagingColumns} onCancel={onClickCancelManagingColumns} onSave={onClickSubmitManagingColumns} columns={state.columnConfiguration} />
      </Panel>

      <Modal open={!!state.archiveTeam} onClose={closeConfirmationModal}>
        {!!state.archiveTeam && (
          <ConfirmationModalLayout
            title="Are you sure you want to archive the following team?"
            titleSize="small"
            subtitle={state.archiveTeam.teamName}
            subtitleSize="large"
            submitButtonText="Yes, archive"
            onClickSubmit={() => {
              if (!!state.archiveTeam?.teamSlug) archiveTeam(state.archiveTeam.teamSlug);
            }}
            cancelButtonText="No, cancel"
            onClickCancel={closeConfirmationModal}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}
