import React, { Fragment, PropsWithChildren, useRef, useState } from 'react';
import { DaterangeDateOptionTypes, IDaterangeProps } from './daterange.models';
import { DaterangeDialog } from './daterange.dialog';
import styles from './daterange.module.css';
import { DateRangeIcon } from './datrange.icon';
import { formatDate } from '@keplerco/core';

const localOptions = ['en-UK', { dateStyle: 'long' }];

export function Daterange(props: PropsWithChildren<IDaterangeProps>): JSX.Element {
  const { defaultValue = { type: DaterangeDateOptionTypes.UpToToday } } = props;

  const defaultSetRange = defaultValue.daterangeIfApplicable ?? [];

  const today = useRef<Date>(new Date());

  const [dateLimitStart, setDateLimitStart] = useState<Date | undefined>(defaultSetRange[0] ?? today.current);
  const [dateLimitEnd, setDateLimitEnd] = useState<Date | undefined>(defaultSetRange[1] ?? today.current);
  const [activeItem, setActiveItem] = useState<DaterangeDateOptionTypes>(defaultValue.type);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function customDateRangeValue() {
    const start = !!dateLimitStart ? dateLimitStart.toLocaleDateString(...(localOptions as any)) : `Earliest Date`;
    const end = !!dateLimitEnd ? formatDate(dateLimitEnd) : formatDate(new Date());
    return `${start} - ${end}`;
  }

  function formatRangeString(input: string) {
    const result = input.match(/[A-Z][a-z]+/g);
    if (result) return result.join(' ');
  }

  return (
    <Fragment>
      <div onClick={() => setIsOpen(true)}>
        {!!props.children ? (
          props.children
        ) : (
          <div className={styles.trigger}>
            <div className={styles.range}>
              <label className={styles.label}>Date range</label>
              {activeItem === DaterangeDateOptionTypes.CustomRange ? <output className={styles.output}>{customDateRangeValue()}</output> : <output className={styles.output}>{formatRangeString(DaterangeDateOptionTypes[activeItem])}</output>}
            </div>

            <div style={{ display: 'grid', placeItems: 'center', width: 40 }}>
              <DateRangeIcon />
            </div>
          </div>
        )}
      </div>

      <DaterangeDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onClickActiveItem={setActiveItem}
        activeItem={activeItem}
        defaultValue={defaultValue}
        onDaterangeChange={props.onDaterangeChange}
        today={today}
        dateLimitStart={dateLimitStart}
        dateLimitEnd={dateLimitEnd}
        onChangeDateLimitStart={setDateLimitStart}
        onChangeDateLimitEnd={setDateLimitEnd}
        title={props.title}
        applyButtonText={props.applyButtonText}
      />
    </Fragment>
  );
}
