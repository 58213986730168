import React from 'react';
import { FocusPanelLoaderLayer } from '../../../../../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { OrganizationLevelType } from '../../../../../../../enums';
import { PermissionType } from '../../../../../../../enums/permission-type';
import { extractHighestOrganizationLevel } from '../../../../../../../library/helpers/permissions/extract-highest-organization-level';
import { IManagePersonPermissionsFocusPanelProps, ManagePersonPermissionsCascadingPanelIds } from '../../manage-person-panels.models';
import { PagePath } from '../../../../../../../navigation/navigation.enums';
import { Anchor, AvatarIcon, ChevronIcon, Chip, ListItemLayout, ListLayout, useMatchScreenWidth } from '@keplerco/core';
import { extractPermission } from '../../../../../../../library/helpers/permissions/extract-permission';
import managePersonPanelsStyles from '../../manage-person-panels.module.css';

export function ManagePersonPermissionsFocusPanel(props: IManagePersonPermissionsFocusPanelProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  function generateChip(permissionType: PermissionType): JSX.Element {
    const permissionTypes = [PermissionType.SkillLevelManagement, PermissionType.Skills];
    if (permissionTypes.includes(permissionType)) return <Chip label="Not available in the system yet" />;

    const permission = extractPermission(props.permissions, permissionType);
    if (!permission) return <Chip label="No permissions assigned" />;

    const organizationLevel = extractHighestOrganizationLevel(permission.organizationLevels);

    switch (organizationLevel?.organizationLevel) {
      case OrganizationLevelType.Company: {
        return <Chip label="Whole company" backgroundColour='apple' />;
      }

      case OrganizationLevelType.Department: {
        return organizationLevel.entities.length > 1 ? <Chip label="Multiple departments" backgroundColour='apple' /> : <Chip label="Department only" backgroundColour='apple' />;
      }

      case OrganizationLevelType.Team: {
        return organizationLevel.entities.length > 1 ? <Chip label="Multiple teams" backgroundColour='apple' /> : <Chip label="Team only" backgroundColour='apple' />;
      }

      default: {
        return <Chip label="No access" backgroundColour='apple' />;
      }
    }
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationPeople}>
      <div className="panelContent">
        <header className={managePersonPanelsStyles.header}>
          <div className={managePersonPanelsStyles.avatar}>
            <AvatarIcon name={props.person} />
          </div>

          <div>
            <h3 className="heading3">Permissions</h3>

            {!!props.person && (
              <span className="caption1">
                <strong>Name</strong> {props.person.firstName} {props.person.lastName} | <strong>Department</strong> {props.person.departmentName} | <strong>Team</strong> {props.person.teamName}
              </span>
            )}
          </div>
        </header>

        <ListLayout>
          <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Analytics)}>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
              Analytics
              {!isMobile && generateChip(PermissionType.Analytics)}
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>

          <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Administration)}>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
              Administration
              {!isMobile && generateChip(PermissionType.Administration)}
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>

          <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Roles)}>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
              Roles
              {!isMobile && generateChip(PermissionType.RoleManagement)}
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>

          <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.Assessments)}>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
              Assessments
              {!isMobile && generateChip(PermissionType.Assessments)}
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>

          <ListItemLayout onClick={() => props.onClickSection(ManagePersonPermissionsCascadingPanelIds.LearningManagement)}>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
              Learning Management
              {!isMobile && generateChip(PermissionType.LearningManagement)}
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>

          <ListItemLayout>
            <div className="card" style={{ display: 'grid', gridTemplateColumns: `1fr ${!isMobile ? 'auto' : ''} auto`, alignItems: 'center', gap: 15 }}>
              Skills
              {!isMobile && generateChip(PermissionType.Skills)}
              <ChevronIcon tone="primary" />
            </div>
          </ListItemLayout>
        </ListLayout>

        <footer className="panelFooter">
          <Anchor onClick={props.onCancel}>Cancel</Anchor>
        </footer>
      </div>
    </FocusPanelLoaderLayer>
  );
}
