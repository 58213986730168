import React, { useEffect, useReducer, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { ICompanyFormValues } from './manage-company-focus-panel.model';
import * as store from './manage-company-focus-panel.store';
import { CopyButton, FormControl, RequiredValidator, Textfield, LettersValidator, Checkbox, RadioButton, Anchor, Button, useMatchScreenWidth, DropdownSearch, PanelHeader } from '@keplerco/core';
import { FocusPanelLoaderLayer } from '../../../components/general/loading-state/loader-layers/focus-panel-loader-layer/focus-panel-loader-layer';
import { FetchType } from '../../../enums';
import { Company } from '../../../models';
import { PagePath } from '../../../navigation/navigation.enums';
import { useAppActions } from '../../../overmind';
import { ScoreComparison } from '../../../enums/score-comparison';
import { FlagIcon } from '../../../design/icons/flag.icon';

export function ManageCompanyFocusPanelLayout(props: { companyToManage?: Company; onClickCancel: () => void; onClickSubmit: () => void }): JSX.Element {
  const actions = useAppActions();

  const mappedLearningPlatforms = new Map<string, any>();

  const isMobile = useMatchScreenWidth('mobile');

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const industryIdErrorRef = useRef<HTMLDivElement>(null);
  const countryIdErrorRef = useRef<HTMLDivElement>(null);

  // company form
  const learning = Object.fromEntries(mappedLearningPlatforms.entries());
  const { control, getValues, watch, handleSubmit } = useForm({ mode: 'all', reValidateMode: 'onChange', defaultValues: { ...(props.companyToManage as any), ...learning } });

  useEffect(() => {
    watch(() => {
      const values = getValues() as ICompanyFormValues;
      dispatch({ type: store.CompanyActionTypes.SetCompanyFormValues, payload: values });
    });

    if (!state.companyFormValues) dispatch({ type: store.CompanyActionTypes.SetCompanyFormValues, payload: getValues() });
  }, []);

  useEffect(() => {
    if (props.companyToManage?.industryId && state.industries) {
      const industryToSelect = state.industries.find(industry => industry.id === props.companyToManage?.industryId);
      dispatch({ type: store.CompanyActionTypes.SetSelectedIndustry, payload: industryToSelect });

      if (props.companyToManage?.countryIds && state.countries) {
        const countryOptionsToSelect = state.countries.filter(country => country.id !== undefined).filter(country => props.companyToManage?.countryIds.includes(country.id as number));
        dispatch({ type: store.CompanyActionTypes.SetSelectedCountryOptions, payload: countryOptionsToSelect });
      }
    }
  }, [props.companyToManage, state.industries, state.countries]);

  useEffect(() => {
    async function getIndustries() {
      const data = await actions.getIndustries();
      dispatch({ type: store.CompanyActionTypes.SetIndustries, payload: data ?? [] });
    }

    async function getCountries() {
      const data = await actions.getCountries();
      dispatch({ type: store.CompanyActionTypes.SetCountries, payload: data ?? [] });
    }

    async function getData() {
      actions.startLoader({ path: PagePath.administrationSettings, type: FetchType.DialogFetching });
      await getIndustries();
      await getCountries();
      actions.stopLoader(PagePath.administrationSettings);
    }

    getData();
  }, []);

  useEffect(() => {
    // Update selectedCountryOptions based on editData.countryIds
    if (props.companyToManage?.countryIds && state.countries) {
      const newSelectedCountryOptions = state.countries.filter(country => props.companyToManage?.countryIds.includes(country.id || -1));
      dispatch({ type: store.CompanyActionTypes.SetSelectedCountryOptions, payload: newSelectedCountryOptions });
    } else {
      dispatch({ type: store.CompanyActionTypes.SetSelectedCountryOptions, payload: [] });
    }

    // Update selectedIndustry based on editData.industryId
    if (props.companyToManage?.industryId && state.industries) {
      const newSelectedIndustry = state.industries.find(industry => industry.id === props.companyToManage?.industryId);
      dispatch({ type: store.CompanyActionTypes.SetSelectedIndustry, payload: newSelectedIndustry });
    } else {
      dispatch({ type: store.CompanyActionTypes.SetSelectedIndustry, payload: undefined });
    }

    if (!!props.companyToManage) dispatch({ type: store.CompanyActionTypes.SetScoreComparison, payload: props.companyToManage.scoreComparison });
  }, [props.companyToManage, state.countries, state.industries]);

  async function onSubmitHandler() {
    if (state.industryIdError || state.countryIdsError) return;

    if (!state.companyFormValues || !state.selectedIndustry?.id) return; // will always be false

    const selectedCountryIds = state.selectedCountryOptions?.map(country => country.id).filter(id => id !== undefined) as number[];

    const company: Company = {
      companySlug: props.companyToManage?.companySlug ?? '',
      companyName: state.companyFormValues?.companyName,
      subDomain: String(state.companyFormValues?.subDomain ?? '')
        .trim()
        .toLowerCase()
        // .replace(/\W/gi, '') TODO: review subDomain validation
        .trim(),
      industryId: state.selectedIndustry.id,
      countryIds: selectedCountryIds,
      displayJobInformationForm: state.companyFormValues.displayJobInformationForm || false,
      allowOffPlatformLearning: state.companyFormValues.allowOffPlatformLearning || false,
      allowReflections: state.companyFormValues.allowReflections || false,
      includeSamlSSO: state.companyFormValues.includeSamlSSO || false,
      allowCreateAccount: state.companyFormValues.allowCreateAccount || false,
      downloadLearningProgressPdf: state.companyFormValues.downloadLearningProgressPdf || false,
      showPoweredByLogo: state.companyFormValues.showPoweredByLogo || false,
      scoreComparison: state.scoreComparison,
    };

    actions.startLoader({ path: PagePath.administrationSettings, type: FetchType.Sending });
    const response = await actions.saveCompany(company);
    if (!!response?.companySlug) await actions.getCompanyVariables(response.companySlug);
    props.onClickSubmit();
    actions.stopLoader(PagePath.administrationSettings);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    dispatch({ type: store.CompanyActionTypes.SetScoreComparison, payload: value as ScoreComparison });
  }

  return (
    <FocusPanelLoaderLayer path={PagePath.administrationSettings}>
      <div className="panelContent">
        {!!props.companyToManage ? (
          <PanelHeader title="Update company" type="actions" divider>
            <div className="headerActions" style={{ justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
              <CopyButton label="Department Id" value={props.companyToManage.companySlug} />
            </div>
          </PanelHeader>
        ) : (
          <PanelHeader title="Create company" divider />
        )}

        <form id="saveCompany" style={{ marginTop: 30 }} onSubmit={handleSubmit(onSubmitHandler)}>
          <div>
            <h6 className="subtitle">Setup company profile</h6>

            <div className="row">
              <div className="column">
                <FormControl
                  control={control}
                  rules={new RequiredValidator('Enter the name of your company')}
                  name="companyName"
                  paddingBottom={10}
                  render={({ field, fieldState }) => {
                    return <Textfield {...field} haserror={!!fieldState.error} label="Company Name" autoFocus responsive />;
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormControl
                  control={control}
                  rules={new LettersValidator('Enter your company sub domain')}
                  name="subDomain"
                  paddingBottom={10}
                  render={({ field, fieldState }) => {
                    return <Textfield {...field} haserror={!!fieldState.error} label="Sub Domain" responsive />;
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <DropdownSearch
                  label="Industry"
                  items={(state.industries ?? []).map(industry => ({
                    value: industry.id!.toString(),
                    label: industry.name,
                    onClick: () => {
                      dispatch({ type: store.CompanyActionTypes.SetSelectedIndustry, payload: industry });
                      dispatch({ type: store.CompanyActionTypes.SetIndustryIdError, payload: false });
                    },
                  }))}
                  value={state.selectedIndustry?.id?.toString()}
                  validation={{
                    dirty: !!state.selectedIndustry || !!state.industryIdError,
                    invalid: !state.selectedIndustry || !!state.industryIdError,
                    message: state.industryIdError ? 'Please select an industry' : '',
                  }}
                  onBlur={() => {
                    if (!state.selectedIndustry) {
                      dispatch({ type: store.CompanyActionTypes.SetIndustryIdError, payload: true });
                    }
                  }}
                  responsive
                  dialogContained
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <DropdownSearch
                  label="Country"
                  items={(state.countries ?? []).map(country => ({
                    value: country.id!.toString(),
                    label: country.name,
                    icon: <FlagIcon isoCode={country.iso} />,
                    onClick: () => {
                      const selectedCountries = state.selectedCountryOptions ?? [];
                      const isSelected = selectedCountries.some(c => c.id === country.id);
                      const updatedCountries = isSelected ? selectedCountries.filter(c => c.id !== country.id) : [...selectedCountries, country];
                      dispatch({ type: store.CompanyActionTypes.SetSelectedCountryOptions, payload: updatedCountries });
                      dispatch({ type: store.CompanyActionTypes.SetCountryIdsError, payload: false });
                    },
                  }))}
                  value={state.selectedCountryOptions?.map(selectedCountry => selectedCountry.id!.toString())}
                  validation={{
                    dirty: !!state.selectedCountryOptions?.length || !!state.countryIdsError,
                    invalid: !state.selectedCountryOptions?.length || !!state.countryIdsError,
                    message: state.countryIdsError ? 'Please select a country' : '',
                  }}
                  onBlur={() => {
                    if (!state.selectedCountryOptions?.length) {
                      dispatch({ type: store.CompanyActionTypes.SetCountryIdsError, payload: true });
                    }
                  }}
                  responsive
                  multiple
                  dialogContained
                />
              </div>
            </div>

            <h4 className="subtitle" style={{ borderTop: '1px solid var(--borders)', padding: '20px 0 10px', marginTop: 20 }}>
              Platform settings
            </h4>

            <div className="row">
              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="allowOffPlatformLearning"
                  render={({ field }) => {
                    return (
                      <Checkbox {...field} id="allowOffPlatformLearning" checked={state.companyFormValues?.allowOffPlatformLearning} clickablearea="label">
                        Allow Off-Platform Learning
                      </Checkbox>
                    );
                  }}
                />
              </div>

              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="allowReflections"
                  render={({ field }) => {
                    return (
                      <Checkbox {...field} id="allowReflections" checked={state.companyFormValues?.allowReflections} clickablearea="label">
                        Allow Course Reflections
                      </Checkbox>
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="includeSamlSSO"
                  render={({ field }) => {
                    return (
                      <Checkbox {...field} id="includeSamlSSO" checked={state.companyFormValues?.includeSamlSSO} clickablearea="label">
                        Include Saml SSO
                      </Checkbox>
                    );
                  }}
                />
              </div>

              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="allowCreateAccount"
                  render={({ field }) => {
                    return (
                      <Checkbox {...field} id="allowCreateAccount" checked={state.companyFormValues?.allowCreateAccount} clickablearea="label">
                        Allow Create Account
                      </Checkbox>
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="downloadLearningProgressPdf"
                  render={({ field }) => {
                    return (
                      <label htmlFor="downloadLearningProgressPdf">
                        <Checkbox {...field} id="downloadLearningProgressPdf" checked={state.companyFormValues?.downloadLearningProgressPdf} clickablearea="label">
                          Allow Learner Report Download
                        </Checkbox>
                      </label>
                    );
                  }}
                />
              </div>

              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="displayJobInformationForm"
                  render={({ field }) => {
                    return (
                      <label htmlFor="displayJobInformationForm">
                        <Checkbox {...field} id="displayJobInformationForm" checked={state.companyFormValues?.displayJobInformationForm} clickablearea="label">
                          Require Job Information
                        </Checkbox>
                      </label>
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="column">
                <FormControl
                  paddingBottom={10}
                  control={control}
                  name="showPoweredByLogo"
                  render={({ field }) => {
                    return (
                      <label htmlFor="showPoweredByLogo">
                        <Checkbox {...field} id="showPoweredByLogo" checked={state.companyFormValues?.showPoweredByLogo} clickablearea="label">
                          Display Kepler Logo
                        </Checkbox>
                      </label>
                    );
                  }}
                />
              </div>
            </div>

            <h4 className="subtitle" style={{ borderTop: '1px solid var(--borders)', padding: '20px 0 10px', marginTop: 20 }}>
              Skill score comparison
            </h4>

            <div className="row">
              <RadioButton id="compareToCompanyAverage" value={ScoreComparison.Company} checked={state.scoreComparison === ScoreComparison.Company} clickablearea="label" onChange={handleChange}>
                Compare to Company Average
              </RadioButton>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <RadioButton id="compareToDepartmentAverage" value={ScoreComparison.Department} checked={state.scoreComparison === ScoreComparison.Department} clickablearea="label" onChange={handleChange}>
                Compare to Department Average
              </RadioButton>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <RadioButton id="compareToTeamAverage" value={ScoreComparison.Team} checked={state.scoreComparison === ScoreComparison.Team} clickablearea="label" onChange={handleChange}>
                Compare to Team Average
              </RadioButton>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <RadioButton id="compareToRoleAverage" value={ScoreComparison.Role} checked={state.scoreComparison === ScoreComparison.Role} clickablearea="label" onChange={handleChange}>
                Compare to Role Average
              </RadioButton>
            </div>

            {/* TODO */}
            <div className="row" style={{ marginTop: 20 }}>
              <RadioButton id="compareToFOPExpectedProficiency" value={ScoreComparison.FOPExpectedProficiency} checked={state.scoreComparison === ScoreComparison.FOPExpectedProficiency} clickablearea="label" onChange={handleChange}>
                Compare to FOP Expected Proficiency
              </RadioButton>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <RadioButton id="compareToRoleExpectedProficiency" value={ScoreComparison.RoleExpectedProficiency} checked={state.scoreComparison === ScoreComparison.RoleExpectedProficiency} clickablearea="label" onChange={handleChange}>
                Compare to Role Expected Proficiency
              </RadioButton>
            </div>

            <div className="row" style={{ marginTop: 20 }}>
              <RadioButton id="FOPAverage" value={ScoreComparison.FieldOfPractice} checked={state.scoreComparison === ScoreComparison.FieldOfPractice} clickablearea="label" onChange={handleChange}>
                Compare to FOP Average
              </RadioButton>
            </div>

            <div className="row" style={{ marginTop: 20, marginBottom: isMobile ? 100 : 50 }}>
              <RadioButton id="none" checked={state.scoreComparison === ScoreComparison.None} value={ScoreComparison.None} clickablearea="label" onChange={handleChange}>
                None
              </RadioButton>
            </div>
          </div>

          <footer className="panelFooter">
            <Anchor onClick={props.onClickCancel}>Cancel</Anchor>

            <Button
              type="button"
              onClick={() => {
                if (!state.selectedIndustry?.id) {
                  dispatch({ type: store.CompanyActionTypes.SetIndustryIdError, payload: true });
                  setTimeout(() => industryIdErrorRef.current?.scrollIntoView());
                }
                const selectedCountryIds = state.selectedCountryOptions?.map(country => country.id).filter(id => id !== undefined);

                if (!selectedCountryIds?.length) {
                  dispatch({ type: store.CompanyActionTypes.SetCountryIdsError, payload: true });
                  if (!!state.selectedIndustry?.id) setTimeout(() => countryIdErrorRef.current?.scrollIntoView());
                }
              }}
            >
              Submit
            </Button>
          </footer>
        </form>
      </div>
    </FocusPanelLoaderLayer>
  );
}
