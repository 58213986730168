import { Context } from '../..';
import {
  DailyActivityResponse,
  SkillScoresResponse,
  SkillScoresRequest,
  SkillScoreOverTimeResponse,
  LeaderboardResponse,
  LessonStatusCountsResponse,
  KeplerPointsResponse,
  PersonResponse,
  SkillLevelsResponse,
  ScoreTypesResponse,
  ScoresResponse,
  RankedScoresResponse,
  EntityScores,
} from '../../../models/overmind/analytics';
import { ScoresSearchParams, DateSearchParams, AnalyticsSearchParams, LeaderboardSearchParams, PeopleSearchParams, PermissionSearchParams, SearchingSearchParams, RoleFitSearchParams } from '../../../models/overmind/search-params';
import { People } from '../../../models/view/people';
import * as base from '../base';

const CONTROLLER = `analytics`;

export async function analyticsGetDailyActivity(context: Context, props: DateSearchParams & PermissionSearchParams): Promise<DailyActivityResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `people`, `daily-activity${base.params(props)}`) };
  const response: base.IResponse<DailyActivityResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillScores(context: Context, props: SkillScoresRequest): Promise<SkillScoresResponse[] | undefined> {
  const url = base.apiURL(CONTROLLER, `skillscores`, `${props.responseGroup}${base.params(props.searchParams)}`);
  const request: base.IRequest = { url: url, authenticated: true };
  const response: base.IResponse<SkillScoresResponse[]> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillScoresOverTime(context: Context, props: AnalyticsSearchParams): Promise<SkillScoreOverTimeResponse[] | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skill-levels`, `over-time${base.params(props)}`) };
  const response: base.IResponse<SkillScoreOverTimeResponse[]> = await base.request(request);
  return response.data;
}

export async function analyticsGetKeplerPointsLeaderboard(context: Context, props: LeaderboardSearchParams): Promise<LeaderboardResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `keplerpoints`, `leaderboard${base.params(props)}`) };
  const response: base.IResponse<LeaderboardResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillScoresLeaderboard(context: Context, props: LeaderboardSearchParams): Promise<LeaderboardResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skillscores`, `leaderboard${base.params(props)}`) };
  const response: base.IResponse<LeaderboardResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetLessonStatusCounts(context: Context, props: AnalyticsSearchParams): Promise<LessonStatusCountsResponse | undefined> {
  const url = base.apiURL(CONTROLLER, `courses`, `status-counts${base.params(props)}`);
  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<LessonStatusCountsResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetPersonCount(context: Context, props: AnalyticsSearchParams): Promise<number | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skills`, `people`, `count${base.params(props)}`) };
  const response: base.IResponse<number> = await base.request(request);
  return response.data;
}

export async function analyticsGetKeplerPoints(context: Context, props: AnalyticsSearchParams): Promise<KeplerPointsResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `keplerpoints`, `total${base.params(props)}`) };
  const response: base.IResponse<KeplerPointsResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetPerson(context: Context, props: string): Promise<PersonResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `person`, props) };
  const response: base.IResponse<PersonResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetPeople(context: Context, props: PeopleSearchParams): Promise<People | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `people${base.params(props)}`) };
  const response: base.IResponse<People> = await base.request(request);
  return response.data;
}

export async function analyticsGetSkillLevels(context: Context, props: AnalyticsSearchParams): Promise<SkillLevelsResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `skill-levels${base.params(props)}`) };
  const response: base.IResponse<SkillLevelsResponse> = await base.request(request);
  return response.data;
}

// NEW
export async function analyticsGetAvailableScoreTypes(context: Context, payload: ScoresSearchParams): Promise<ScoreTypesResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `score-types${base.params(payload)}`) };
  const response: base.IResponse<ScoreTypesResponse> = await base.request(request);
  return response.data;
}

// TODO: Why do we need both `analytics/skillscores/{responseGroup}` and `analytics/scores`? Can we not combine them?
export async function analyticsGetScoresByOrganizationLevel(context: Context, payload: ScoresSearchParams): Promise<ScoresResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `scores`, `by-organization-level${base.params(payload)}`) };
  const response: base.IResponse<ScoresResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetRankedScoresByOrganizationLevel(context: Context, payload: ScoresSearchParams): Promise<RankedScoresResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `ranked`, `by-organization-level${base.params(payload)}`) };
  const response: base.IResponse<RankedScoresResponse> = await base.request(request);
  return response.data;
}

// TODO: Why do we need both `analytics/skillscores/{responseGroup}` and `analytics/scores/{scoreType}`? Can we not combine them?
export async function analyticsGetScoresByScoreType(context: Context, payload: ScoresSearchParams & Partial<SearchingSearchParams>): Promise<ScoresResponse | undefined> {
  const url: string = base.apiURL(CONTROLLER, `scores`, `by-score-type${base.params(payload)}`);
  const request: base.IRequest = { url };
  const response: base.IResponse<ScoresResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetRankedScoresByScoreType(context: Context, payload: ScoresSearchParams): Promise<RankedScoresResponse | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `ranked`, `by-score-type${base.params(payload)}`) };
  const response: base.IResponse<RankedScoresResponse> = await base.request(request);
  return response.data;
}

export async function analyticsGetRoleFitComparison(context: Context, payload: RoleFitSearchParams): Promise<EntityScores[] | undefined> {
  const url: string = base.apiURL(CONTROLLER, `role-fit-comparison${base.params(payload)}`);
  const request: base.IRequest = { url };
  const response: base.IResponse<EntityScores[]> = await base.request(request);
  return response.data;
}

export async function analyticsGetRoleFitAverage(context: Context, payload: RoleFitSearchParams): Promise<EntityScores[] | undefined> {
  const url: string = base.apiURL(CONTROLLER, `role-fit-average${base.params(payload)}`);
  const request: base.IRequest = { url };
  const response: base.IResponse<EntityScores[]> = await base.request(request);
  return response.data;
}
