import { IconToneProps, colourString } from '@keplerco/core';
import React from 'react';

export function LockIcon(props: IconToneProps): JSX.Element {
  const size = `${props.size ?? 20}px`;
  const tone = colourString(props.tone ?? 'accent-3');

  return (
    <svg viewBox="0 0 24 24" width={size} height={size}>
      <path fill={tone} d="M17 9.761V5A5 5 0 1 0 7 5v4.761A7.98 7.98 0 0 0 4 16a8 8 0 0 0 16 0 7.98 7.98 0 0 0-3-6.239zM9 5c0-1.654 1.346-3 3-3s3 1.346 3 3v3.587a7.96 7.96 0 0 0-6 0V5zm4 11.723V19h-2v-2.277A1.99 1.99 0 0 1 10 15a2 2 0 0 1 4 0c0 .738-.404 1.376-1 1.723z" />
    </svg>
  );
}

