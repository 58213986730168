import React, { useState } from 'react';
import classNames from 'classnames';
import { ExplainerFocusPanelLayout } from '../../../../widgets/layouts';
import { RaterRadioButton } from '../rater-radio-button/rater-radio-button';
import { RatingScalePoint } from '../../../../models/rating-scale';
import { IRaterCardProps } from '../raters.models';
import { IconButton, HelpIcon, safeCallback, capitalCase, Panel } from '@keplerco/core';
import styles from './rater-card.module.css';
import raterRadioButtonStyles from '../rater-radio-button/rater-radio-button.module.css';

export function RaterCard(props: IRaterCardProps): JSX.Element {
  const { colour = 'primary' } = props;

  const maxWidth = props.scale.length * 70 + (props.scale.length - 1) * 45;

  const [open, setOpen] = useState<boolean>();

  return (
    <React.Fragment>
      <div className={classNames('card', styles.card, { [styles.fade]: props.fadeOnComplete && props.data.rating !== null && props.data.rating !== undefined })}>
        <header style={{ display: 'flex', alignItems: 'center' }}>
          <h4 className="heading4">{props.data.label}</h4>

          {!!props.data.description && (
            <IconButton iconType="fill" onClick={() => setOpen(true)}>
              <HelpIcon tone="link-text" />
            </IconButton>
          )}
        </header>

        <div className={styles.radioButtons}>
          <ul className={raterRadioButtonStyles.list} style={{ maxWidth }}>
            {props.scale.map((point: RatingScalePoint) => {
              return <RaterRadioButton bgColor={props.bgColor} key={point.label} colour={colour} details={props.data} point={point} onChange={() => safeCallback(props.onChange, point.rating)} />;
            })}
          </ul>
        </div>
      </div>

      {open && (
        <Panel open={open} onClose={() => setOpen(false)}>
          {open && <ExplainerFocusPanelLayout title={capitalCase(props.data.label)} description={props.data.description ?? ''} onClose={() => setOpen(false)} />}
        </Panel>
      )}
    </React.Fragment>
  );
}
