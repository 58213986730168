import { SSOType } from './sso-button.models';
import google from './google.icon.svg';
import linkedin from './linkedin.icon.svg';
import saml from './saml.icon.svg';
import microsoft from './microsoft.icon.svg';

export function getSSOIcon(type: SSOType): string {
  switch (type) {
    case 'google': {
      return google;
    }

    case 'linkedin': {
      return linkedin;
    }

    case 'saml': {
      return saml;
    }

    default: {
      return microsoft;
    }
  }
}
