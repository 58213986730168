import { Context } from '../..';
import { Proficiency } from '../../../models/proficiency';
import * as base from '../base';

const CONTROLLER = `senioritylevelconfiguration`;

// TODO: look at naming, having seniorityLevel.level isn't great, can't this just be seniority?
export async function getSeniorityLevels(context: Context, companySlug: string): Promise<Proficiency[] | undefined> {
  const url: string = base.apiURL(CONTROLLER, companySlug);
  const request: base.IRequest = { url };
  const response: base.IResponse<Proficiency[]> = await base.request(request);
  return response.data;
}
