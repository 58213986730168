import React from 'react';
import { AddTwoToneIcon } from '@keplerco/core';
import styles from './capture-additional-learning-card.module.css';
import classNames from 'classnames';

export function CaptureAdditionalLearningCard(props: { onCancel?: () => void; onClick: () => void }): JSX.Element {
  return (
    <div className={classNames('card', styles.card)} style={{ cursor: 'pointer' }} onClick={props.onClick}>
      <header className={styles.header}>
        <span className={classNames('caption1', styles.title)}>Additional Learning</span>
      </header>

      <p className="body2" style={{ marginTop: 15 }}>
        Have you completed any in-person sessions, webinars, talks or learning from places outside of this platform?
      </p>

      <figure className={styles.figure}>
        <AddTwoToneIcon primaryTone="link-text_2" secondaryTone="link-text_2" size={85} />
      </figure>

      <footer style={{ textAlign: 'center' }}>
        <a className={styles.anchor}>Capture Additional Learning</a>
      </footer>
    </div>
  );
}
