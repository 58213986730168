import { IconProps } from '@keplerco/core';
import React from 'react';

export function StarOutlineIcon({ size = 20 }: IconProps): JSX.Element {
  return (
    <svg viewBox="0 0 21 20" width={size} height={size}>
      <path
        d="M15.5632 19.0693C15.3303 19.0693 15.1031 19.0125 14.8891 18.8988L11.4071 17.0679C11.1458 16.9296 10.8504 16.8577 10.5531 16.8577C10.2577 16.8577 9.96235 16.9296 9.69916 17.0679L6.21708 18.8988C6.00312 19.0125 5.7759 19.0693 5.54301 19.0693C5.12266 19.0693 4.70609 18.8742 4.42965 18.5448C4.1532 18.2172 4.04149 17.8006 4.11533 17.3727L4.78373 13.4968C4.88597 12.9022 4.68905 12.2944 4.25545 11.8722L1.43987 9.12668C1.23538 8.92787 1.09526 8.67982 1.03467 8.41285C0.977866 8.16102 0.99112 7.89404 1.07254 7.64221C1.15396 7.39038 1.29975 7.16695 1.49478 6.99653C1.70117 6.81476 1.96057 6.69737 2.2427 6.65571L6.13566 6.08957C6.73399 6.00247 7.24902 5.62756 7.51599 5.08603L9.25609 1.55851C9.38295 1.30289 9.57419 1.09272 9.81087 0.952601C10.0343 0.820058 10.2899 0.75 10.555 0.75C10.8201 0.75 11.0757 0.820058 11.2991 0.952601C11.5358 1.09272 11.7271 1.30289 11.8539 1.55851L13.594 5.08603C13.861 5.62756 14.3779 6.00247 14.9743 6.08957L18.8673 6.65761C19.1494 6.69926 19.4088 6.81666 19.6152 6.99843C19.8102 7.16884 19.956 7.39227 20.0375 7.6441C20.1189 7.89593 20.1321 8.16101 20.0753 8.41474C20.0147 8.68361 19.8746 8.92976 19.6701 9.12857L16.8546 11.8741C16.4228 12.2963 16.224 12.9022 16.3263 13.4987L16.9909 17.3746C17.0647 17.8025 16.953 18.2191 16.6766 18.5467C16.4001 18.8723 15.9836 19.0693 15.5632 19.0693Z"
        stroke="var(--light)"
      />
    </svg>
  );
}
