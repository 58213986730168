import { Anchor, Button, CheckboxCard, PanelHeader, SearchBar, Toggle, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { ListItem } from '../../../../../../components/lists/list-item';
import { ActivationStatus, OrganizationLevelType } from '../../../../../../enums';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { Cohort, CohortType, ISelectPeopleLayoutProps } from '../people-step.models';
import styles from './select-people.module.css';
import { EmptyState } from '../../../../../../components/general/empty-state/empty-state';
import { PagingSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../../../../models/overmind/search-params';
import { IPerson } from '../../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';

export function SelectPeopleLayout({ selectedPeople, showManagerToggle, selectedCohort: selectedEntity, setSelectedPeople, onBack, onDone }: ISelectPeopleLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();
  const isMobile = useMatchScreenWidth('mobile');
  const [loading, setLoading] = useState<boolean>(false);
  const [peopleToSelect, setPeopleToSelect] = useState<IPerson[]>(selectedPeople);
  const [people, setPeople] = useState<IPerson[]>();
  const [request, setRequest] = useState<SearchingSearchParams & SortingSearchParams & PagingSearchParams>({
    search: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 99999, // TODO: get correct size
  });

  async function getDepartmentPeople(selectedEntity: Cohort) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, departmentSlug: selectedEntity.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          ...employee,
          selected: selectedPeople.some(selected => selected.id === employee.id),
          manager: selectedPeople.some(selected => selected.id === employee.id && selected.manager),
          skills: [],
        })) ?? []
    );
    return;
  }
  async function getTeamPeople(selectedEntity: Cohort) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, teamSlug: selectedEntity.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          ...employee,
          selected: selectedPeople.some(selected => selected.id === employee.id),
          manager: selectedPeople.some(selected => selected.id === employee.id && selected.manager),
          skills: [],
        })) ?? []
    );
    return;
  }
  async function getRolePeople(selectedEntity: Cohort) {
    const assigneesResponse = await actions.getCompanyRoleAssignees({
      ...request,
      companySlug: companyVariables.slug,
      departmentSlug: undefined,
      teamSlug: undefined,
      learnerSlug: undefined,
      organizationLevel: OrganizationLevelType.Company,
      roleSlug: selectedEntity!.slug!,
    });
    const employeesResponse = await actions.searchPeople({ ...request, companySlug: companyVariables.slug });
    const people: IPerson[] = [];
    assigneesResponse?.assignees?.forEach(assignee => {
      const person = employeesResponse?.employees.find(employee => employee.email === assignee.email);
      if (!person) return;
      people.push({
        ...person,
        selected: false,
        manager: false,
        skills: [],
      });
    });
    setPeople(people);
    return;
  }

  async function getPeople() {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          ...employee,
          selected: selectedPeople.some(selected => selected.id === employee.id),
          manager: selectedPeople.some(selected => selected.id === employee.id && selected.manager),
          skills: [],
        })) ?? []
    );
  }
  useEffect(() => {
    async function getData() {
      setLoading(true);

      if (selectedEntity?.type === CohortType.People) {
        await getPeople();
      }

      if (selectedEntity?.type === CohortType.Role) {
        await getRolePeople(selectedEntity);
      }

      if (selectedEntity?.type === CohortType.Department) {
        await getDepartmentPeople(selectedEntity);
      }

      if (selectedEntity?.type === CohortType.Team) {
        await getTeamPeople(selectedEntity);
      }
      setLoading(false);
    }
    getData();
  }, [request, selectedEntity]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Assessments" title={`${selectedEntity?.name ?? ''} ${selectedEntity?.type === CohortType.People ? selectedEntity.type : selectedEntity?.type?.toLowerCase()}`} subtitle="Select who you would like to take part in the assessment" divider />
      <div>
        <div className="card" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.searchSelect}>
            <SearchBar label="Search" loading={loading} responsive onInput={event => setRequest(currentState => ({ ...currentState, search: (event.target as HTMLInputElement).value, page: 1 }))} />

            <CheckboxCard
              id="selectAll"
              checked={!people?.some(person => !person.selected)}
              onChange={() => {
                const allSelected = people?.every(person => person.selected);

                setPeople(currentState => {
                  if (!currentState) return [];
                  return currentState.map(person => ({ ...person, selected: allSelected ? false : true }));
                });

                setPeopleToSelect(currentState => {
                  const nextState: IPerson[] = structuredClone(currentState);

                  people?.forEach(person => {
                    const index = nextState.findIndex(temp => temp.learnerSlug === person.learnerSlug);
                    if (allSelected && index !== -1) nextState.splice(index, 1);
                    else if (!allSelected && index === -1) nextState.push({ ...person, selected: true });
                  });
                  return nextState;
                });
              }}
            >
              <div className="card">Select all</div>
            </CheckboxCard>
          </div>

          {loading && !people ? (
            <div className={styles.loadingState}>
              <object data={'/graphics/loading-state.graphic.svg'} type="image/svg+xml">
                <img src={'graphics/loading-state.graphic.svg'} alt="" />
              </object>

              <span className="heading5">Loading...</span>
            </div>
          ) : (
            <>
              {people?.length ? (
                <div className={styles.list}>
                  {people?.map(person => {
                    return (
                      <ListItem
                        key={person.id.toString()}
                        selected={person.selected}
                        onClick={() => {
                          setPeople(currentState => {
                            if (!currentState) return;
                            currentState.forEach(temp => {
                              if (temp.id !== person.id) return;
                              temp.selected = !temp.selected;
                              temp.manager = !temp.selected ? false : temp.manager;
                            });
                            return currentState;
                          });

                          setPeopleToSelect(currentState => {
                            let nextState: IPerson[] = structuredClone(currentState);
                            const manager = selectedPeople.find(temp => temp.manager);
                            person.selected
                              ? (nextState = nextState.filter(temp => temp.id !== person.id))
                              : nextState.push({
                                ...person,
                                manager: person.id === manager?.id,
                              });
                            return nextState;
                          });
                        }}
                      >
                        <>
                          {isMobile ? (
                            <div style={{ display: 'grid', gap: 15, alignItems: 'start', gridTemplateColumns: `auto` }}>
                              <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                                  {person.firstName} {person.lastName}
                                </div>
                                <div style={{ color: 'var(--default)' }} className="caption2">
                                  {person.department.name} | {person.team.name}
                                </div>
                                <div className="caption2">{person.email}</div>
                                {showManagerToggle && (
                                  <div className={styles.toggle}>

                                    <div className={styles.toggleLabelWrapper} onClick={event => event.stopPropagation()}>
                                      <Toggle
                                        id="manager"
                                        name="manager"
                                        title="manager"
                                        value={person.manager ?? false}
                                        toggleBackgroundColour="primary"
                                        toggleForegroundColour="white"
                                        onChange={() => {
                                          setPeople(currentState => {
                                            return currentState?.map(currentPerson => {
                                              if (currentPerson.id !== person.id) return { ...currentPerson, manager: false };
                                              return { ...currentPerson, manager: !currentPerson.manager, selected: true };
                                            });
                                          });

                                          setPeopleToSelect(currentState => {
                                            const nextState = structuredClone(currentState);

                                            const personIndex = nextState.findIndex((temp: IPerson) => temp.id === person.id);

                                            if (personIndex !== -1) {
                                              if (nextState[personIndex].manager) {
                                                nextState[personIndex].manager = false;
                                              } else {
                                                nextState.forEach((temp: IPerson) => {
                                                  temp.manager = false;
                                                });
                                                nextState[personIndex].manager = true;
                                                nextState[personIndex].selected = true;
                                              }
                                            } else {
                                              nextState.forEach((temp: IPerson) => {
                                                temp.manager = false;
                                              });
                                              nextState.push({
                                                ...person,
                                                manager: true,
                                                selected: true,
                                              });
                                            }

                                            return nextState;
                                          });
                                        }}
                                      >
                                        <h6 className="caption1">Manager</h6>
                                      </Toggle>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: `1fr 1fr 195px ${showManagerToggle ? 'auto' : ''}` }}>
                                <div>
                                  <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                                    {person.firstName} {person.lastName}
                                  </div>
                                  <div className="caption2">
                                    {person.department.name} | {person.team.name}
                                  </div>
                                </div>

                                <div className="caption2">{person.email}</div>

                                {showManagerToggle && (
                                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                                    <div className={styles.toggleLabelWrapper} onClick={event => event.stopPropagation()}>
                                      <Toggle
                                        id="manager"
                                        name="manager"
                                        title="manager"
                                        value={person.manager ?? false}
                                        toggleBackgroundColour="primary"
                                        toggleForegroundColour="white"
                                        onChange={() => {
                                          setPeople(currentState => {
                                            return currentState?.map(currentPerson => {
                                              if (currentPerson.id !== person.id) return { ...currentPerson, manager: false };
                                              return { ...currentPerson, manager: !currentPerson.manager, selected: true };
                                            });
                                          });

                                          setPeopleToSelect(currentState => {
                                            const nextState = structuredClone(currentState);

                                            const personIndex = nextState.findIndex((temp: IPerson) => temp.id === person.id);

                                            if (personIndex !== -1) {
                                              if (nextState[personIndex].manager) {
                                                nextState[personIndex].manager = false;
                                              } else {
                                                nextState.forEach((temp: IPerson) => {
                                                  temp.manager = false;
                                                });
                                                nextState[personIndex].manager = true;
                                                nextState[personIndex].selected = true;
                                              }
                                            } else {
                                              nextState.forEach((temp: IPerson) => {
                                                temp.manager = false;
                                              });
                                              nextState.push({
                                                ...person,
                                                manager: true,
                                                selected: true,
                                              });
                                            }

                                            return nextState;
                                          });
                                        }}
                                      >
                                        <h6 className="caption1">Manager</h6>
                                      </Toggle>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      </ListItem>
                    );
                  })}
                </div>
              ) : (
                <div style={{}}>
                  <EmptyState title="No people found" />
                </div>
              )}
            </>
          )}
          {/* TODO: add pagination? 
        {pageCount > 1 && (
          <div style={{ marginTop: 'auto' }}>
            <Pager
              activePageNumber={activePageNumber}
              pageCount={pageCount}
              onPageChange={page => {
                setRequest(currentState => ({ ...currentState, page: page }));
                setActivePageNumber(page);
              }}
            />
          </div>
        )} */}
        </div>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button
          type="button"
          onClick={() => {
            setSelectedPeople(peopleToSelect);
            onDone();
          }}
        >
          Done
        </Button>
      </footer>
    </div>
  );
}
