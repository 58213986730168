import React, { useEffect, useState } from 'react';
import { CompletionStatus, FetchType, SkillFrequency } from '../../../../enums';
import { LearnerSkillResource } from '../../../../models/learner-skill-resource';
import { useAppActions, useAppState } from '../../../../overmind';
import { useNavigate } from 'react-router';
import { RatingScale } from '../../../../models/rating-scale';
import { RatingData } from '../../../../components/inputs/raters/raters.models';
import { PagePath } from '../../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Button, ListLayout, PageHeader } from '@keplerco/core';
import { RaterCard } from '../../../../components/inputs/raters/rater-card/rater-card';

export function SkillFrequencyPage(): JSX.Element {
  const actions = useAppActions();
  const navigate = useNavigate();
  const { skillAssessmentConfig } = useAppState();
  const [data, setData] = useState<LearnerSkillResource[]>();
  const bgColor = getComputedStyle(document.body).backgroundColor;

  useEffect(() => {
    async function getData() {
      actions.startLoader({ path: PagePath.analysisTechnicalSkillFrequency, type: FetchType.PageFetching });

      const tempData = await actions.getSelfReviews();
      setData(tempData);

      actions.stopLoader(PagePath.analysisTechnicalSkillFrequency);
    }

    getData();
  }, []);

  async function onSubmitHandler() {
    actions.startLoader({ path: PagePath.analysisTechnicalSkillFrequency, type: FetchType.Sending });

    await actions.submitSelfReviews(data!);

    const skillAssessmentConfiguration = await actions.getSkillAssessmentConfiguration(skillAssessmentConfig!.assessmentSlug);

    if (skillAssessmentConfiguration?.selfReviewCompletionStatus === CompletionStatus.Completed && skillAssessmentConfig?.selfPeerReviewCompletionStatus === CompletionStatus.NoActionRequired) {
      actions.resetSkillConfiguration();
    }

    navigate(`${PagePath.analysisBase}${PagePath.analysisRatingTechnicalSelfAssessment}`);
    window.scrollTo(0, 0);

    actions.stopLoader(PagePath.analysisTechnicalSkillFrequency);
  }

  const handleRatingChange = (skill: LearnerSkillResource, newRating: number, index: number, parent: LearnerSkillResource[]) => {
    setData(prevData => prevData?.map(item => (item.companySkillId === skill.companySkillId ? { ...item, skillFrequency: newRating } : item)));
    if (index >= 1) {
      const previousElement = document.getElementById(`rater-${parent[index - 1].companySkillId}`);
      const currentElement = document.getElementById(`rater-${skill.companySkillId}`);

      if (previousElement && currentElement) {
        const container = document.getElementById('scrollable-container');
        if (container) {
          const scrollOffset = previousElement.offsetTop - container.offsetTop;
          container.scrollTo({ top: scrollOffset, behavior: 'smooth' });
        }
      }
    }
  };
  const scale: RatingScale = [
    { label: 'Never', rating: SkillFrequency.Never, description: 'Never Used' },
    { label: 'Daily', rating: SkillFrequency.Daily, description: 'Used Daily' },
    { label: 'Weekly', rating: SkillFrequency.Weekly, description: 'Weekly' },
    { label: 'Monthly', rating: SkillFrequency.Monthly, description: 'Monthly' },
  ];

  const allSelected = !!data && data.every(s => s.skillFrequency !== null);

  return (
    <PageLoaderLayer path={PagePath.analysisTechnicalSkillFrequency}>
      <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
        <PageHeader
          breadcrumbs={[
            { name: 'Skills analysis', url: PagePath.analysisBase },
            { name: 'Skill frequency', url: `${PagePath.analysisBase}${PagePath.analysisTechnicalSkillFrequency}` },
          ]}
          title="How often do you use each skill?"
          subtitle="To get an idea of how we can deliver the best learning to you, tell us how often you use each of these skills."
          divider
        />

        <div style={{ overflowY: 'auto', width: '100%', maxHeight: 'calc(100vh - 375px)' }}>
          <ListLayout>
            {data?.map((skill, index, parent) => {
              const tempRatingData: RatingData = {
                id: skill.companySkillId,
                label: skill.skillName,
                description: skill.skillDescription,
                rating: skill.skillFrequency,
              };

              return (
                <li key={skill.companySkillId} id={`rater-${skill.companySkillId}`}>
                  <RaterCard bgColor={bgColor} onChange={newRating => handleRatingChange(skill, newRating, index, parent)} data={tempRatingData} defaultValue={skill.skillFrequency} scale={scale} colour="link-text" />
                </li>
              );
            })}
          </ListLayout>
        </div>

        <footer style={{ height: 50, alignSelf: 'flex-end' }}>
          <Button type="button" disabled={!allSelected} onClick={() => allSelected && onSubmitHandler()}>
            Submit
          </Button>
        </footer>
      </div>
    </PageLoaderLayer>
  );
}
