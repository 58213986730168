import { Context } from '../..';
import * as base from '../base';

const CONTROLLER = `roles`;

export interface AIGenerateRoleRequest {
  name: string;
  aboutTheRole: string;
  aboutTheIndustry: string;
  seniorityLabel: string;
  country: string;
}

export interface AIGenerateRoleResponse {
  name: string;
  description: string;
  seniorityLabel: string;
}

export async function aiGenerateRole(context: Context, payload: AIGenerateRoleRequest): Promise<AIGenerateRoleResponse | undefined> {
  const url: string = base.aiURL(CONTROLLER, `details`);
  const request: base.IRequest = { url, body: JSON.stringify(payload), method: 'POST' };
  const response: base.IResponse<AIGenerateRoleResponse> = await base.request(request);
  return response.data;
}

interface AIGenerateRoleDescriptionRequest {
  name: string;
  description: string;
  seniorityLevel: string;
  instructionType: string;
}

export async function aiGenerateRoleDescription(context: Context, payload: AIGenerateRoleDescriptionRequest): Promise<{ description: string } | undefined> {
  const url: string = base.aiURL(CONTROLLER, `details`, 'regenerate');
  const request: base.IRequest = { url, body: JSON.stringify(payload), method: 'POST' };
  const response: base.IResponse<{ description: string }> = await base.request(request);
  return response.data;
}
