/* eslint-disable indent */
import { Anchor, Button, CheckboxCard, PanelHeader, PillButtonGroup, SearchBar } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { AllSkillsWidget } from '../../../../assessments/wizard/peer/skills-step/all-skills.widget';
import { SelectedSkillsWidget } from '../../../../assessments/wizard/peer/skills-step/selected-skills.widget';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';

enum PillButton {
  AllSkills = 0,
  SelectedSkills = 1,
}

enum PillButtonDescriptions {
  AllSkills = 'All skills',
  RoleSkills = 'Role skills',
  SelectedSkills = 'Selected skills',
}

export function SelectSkillsLayout({ skills, onBack, onAssign }: { skills: CompanySkillListItemResponse[]; onBack: () => void; onAssign: (selectedSkills: CompanySkillListItemResponse[]) => void }): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPillButton, setSelectedPillButton] = useState<PillButton>(PillButton.AllSkills);

  const [query, setQuery] = useState<string>('');
  const [selectedSkills, setSelectedSkills] = useState<CompanySkillListItemResponse[]>();

  const [allSkillsCount, setAllSkillsCount] = useState<number>(0);
  const [selectedSkillsCount, setSelectedSkillsCount] = useState<number>(0);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectedSkills(skills);
  }, [skills]);

  useEffect(() => {
    selectedPillButton === PillButton.SelectedSkills ? setSelectAll(!!selectedSkills?.length) : setSelectAll(false);
  }, [selectedPillButton, selectedSkills]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Assessments" title="Select skills" subtitle="Manage the skills you would like to assess" divider />

      <div className="card">
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          <SearchBar loading={loading} label="Search" onInput={event => setQuery((event.target as HTMLInputElement).value)} responsive />

          <div style={{ display: 'flex', flexDirection: 'row', gap: 15, alignItems: 'center', justifyContent: 'space-between' }}>
            <PillButtonGroup
              backgroundColour="borders"
              buttons={[
                { id: PillButton.AllSkills, label: `${PillButtonDescriptions.AllSkills} (${allSkillsCount})`, active: selectedPillButton === PillButton.AllSkills },
                { id: PillButton.SelectedSkills, label: `${PillButtonDescriptions.SelectedSkills} (${selectedSkillsCount})`, active: selectedPillButton === PillButton.SelectedSkills },
              ]}
              onClick={buttons => setSelectedPillButton(buttons[0].id ?? PillButton.AllSkills)}
            />

            <div className={classNames({ invisible: selectedPillButton === PillButton.AllSkills || (selectedPillButton === PillButton.SelectedSkills && !selectedSkills?.length) })}>
              <CheckboxCard
                id="selectAll"
                checked={selectAll}
                onChange={() => {
                  setSelectedSkills([]);
                }}
              >
                <div className="card">Select all</div>
              </CheckboxCard>
            </div>
          </div>

          <div className={classNames({ hidden: selectedPillButton !== PillButton.AllSkills })} style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <AllSkillsWidget showRoleSkills={false} learnerSlug={undefined} loading={loading} setLoading={setLoading} query={query} personSkills={[]} roleSkills={[]} setAllSkillsCount={setAllSkillsCount} selectedSkills={selectedSkills ?? []} setSelectedSkills={setSelectedSkills} searchGlobally />
          </div>

          <div className={classNames({ hidden: selectedPillButton !== PillButton.SelectedSkills })}>
            <SelectedSkillsWidget showRoleSkills={false} learnerSlug={undefined} loading={loading} query={query} roleSkills={[]} setSelectedSkillsCount={setSelectedSkillsCount} selectedSkills={selectedSkills ?? []} setSelectedSkills={setSelectedSkills} />
          </div>
        </div>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onAssign(selectedSkills ?? [])}>
          Assign
        </Button>
      </footer>
    </div>
  );
}
