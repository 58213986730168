import { Context } from '../..';
import { Proficiency } from '../../../models/proficiency';
import * as base from '../base';

const CONTROLLER = `skilllevelconfiguration`;

// TODO: replaced by getCompanyVariables, remove
export async function getSkillLevelConfigCount(context: Context, slug: string) {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `count`, slug), authenticated: true };
  const response: base.IResponse<number> = await base.request(request);
  context.state.companyVariables.maxLevel = response.data ?? 7;
}

// TODO: naming inconsistent, we've got 'skillLevel', 'expectedProficiency', 'expectedLevel', etc. all for basically the same thing
export async function getSkillLevelConfig(context: Context) {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, context.state.companyVariables.slug!) };
  const response: base.IResponse<Proficiency[]> = await base.request(request);
  context.state.skillLevelConfig = response.data ?? [];
}
