/* eslint-disable indent */
import { Anchor, Button, CheckboxCard, PanelHeader, PillButtonGroup, SearchBar } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { AllSkillsWidget } from './all-skills.widget';
import { RoleSkillsWidget } from './role-skills.widget';
import classNames from 'classnames';
import { SelectedSkillsWidget } from './selected-skills.widget';
import { CompanyEntitySearchParams, SkillScoresSearchParams } from '../../../../../models/overmind/search-params';
import { EntityType, OrganizationLevelType, SortField } from '../../../../../enums';
import { useAppActions, useAppState } from '../../../../../overmind';
import { extractHighestOrganizationLevel } from '../../../../../library/helpers/permissions/extract-highest-organization-level';
import { ScoreComparison } from '../../../../../enums/score-comparison';
import { SkillScoresRequest } from '../../../../../models/overmind/analytics';
import { ResponseGroup } from '../../../../../enums/analytics';
import { IPerson } from '../../../../../widgets/forms/skill-assessment/people-and-skills/people-and-skills.models';
import { CompanySkillListItemResponse } from '../../../../../models/overmind/company-entity';

enum PillButton {
  AllSkills = 0,
  RoleSkills = 1,
  SelectedSkills = 2,
}

enum PillButtonDescriptions {
  AllSkills = 'All skills',
  RoleSkills = 'Role skills',
  SelectedSkills = 'Selected skills',
}

export function SelectSkillsLayout({
  roleSlugs,
  personToEdit,
  skills,
  sync,
  onBack,
  onAssign,
}: {
  roleSlugs: string[] | undefined;
  personToEdit: IPerson | undefined;
  skills: CompanySkillListItemResponse[];
  sync: string;
  onBack: () => void;
  onAssign: (selectedSkills: CompanySkillListItemResponse[]) => void;
}): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();
  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement?.organizationLevels);

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedPillButton, setSelectedPillButton] = useState<PillButton>(PillButton.AllSkills);

  const [query, setQuery] = useState<string>('');
  const [personSkills, setPersonSkills] = useState<CompanySkillListItemResponse[]>();
  const [roleSkills, setRoleSkills] = useState<CompanySkillListItemResponse[]>();
  const [selectedSkills, setSelectedSkills] = useState<CompanySkillListItemResponse[]>();

  const [allSkillsCount, setAllSkillsCount] = useState<number>(0);
  const [roleSkillsCount, setRoleSkillsCount] = useState<number>(0);
  const [selectedSkillsCount, setSelectedSkillsCount] = useState<number>(0);

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    async function getPersonSkills(): Promise<CompanySkillListItemResponse[]> {
      const searchParams: SkillScoresSearchParams = {
        startDate: undefined,
        endDate: undefined,
        organizationLevel: OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        learnerSlug: personToEdit?.learnerSlug,
        teamSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: ScoreComparison.None,
      };
      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.Skill,
        searchParams: searchParams,
      };
      const response = await actions.analyticsGetSkillScores(request);

      const skills: CompanySkillListItemResponse[] = [];
      if (!!response && !!response[0]) {
        response[0].skillTypes.forEach(skillType =>
          skillType.skillSubTypes.forEach(skillSubType =>
            skillSubType.skills.forEach(skill =>
              skills.push({
                slug: skill.slug,
                name: skill.name,
                description: '',
                level: skill.score?.level,
              })
            )
          )
        );
      }
      setPersonSkills(skills);
      return skills;
    }

    function getSelectedSkills(personSkills: CompanySkillListItemResponse[] | undefined) {
      const response: CompanySkillListItemResponse[] = structuredClone(skills);
      if (!!personSkills?.length) {
        response.forEach(selectedSkill => {
          const personSkill = personSkills.find(personSkill => personSkill.slug === selectedSkill.slug);
          selectedSkill.level = personSkill?.level;
        });
      }
      setSelectedSkills(response);
    }

    async function getRoleSkills(personSkills: CompanySkillListItemResponse[] | undefined) {
      const roleSkills: CompanySkillListItemResponse[] = [];

      if (!roleSlugs) return setRoleSkills(roleSkills);

      for (const roleSlug of roleSlugs) {
        const request: CompanyEntitySearchParams = {
          search: undefined,
          sortAscending: true,
          sortField: SortField.Name,
          page: 1,
          pageSize: 99999,
          organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
          companySlug: companyVariables.slug,
          departmentSlug: undefined,
          teamSlug: undefined,
          learnerSlug: undefined,
          searchGlobally: false,
          relatedType: EntityType.Role,
          roleSlug: roleSlug,
        };
        const response = await actions.getCompanySkills(request);
        response?.entities.forEach(entity => {
          if (roleSkills.some(roleSkill => roleSkill.slug === entity.slug)) return;
          if (!!personSkills?.length) {
            const personSkill = personSkills.find(personSkill => personSkill.slug === entity.slug);
            entity.level = personSkill?.level;
          }
          roleSkills.push(entity);
        });
      }

      setRoleSkills(roleSkills.sort((a, b) => a.name.localeCompare(b.name)));
    }

    async function getData() {
      setLoading(true);

      let personSkills: CompanySkillListItemResponse[] | undefined;
      if (!!personToEdit) personSkills = await getPersonSkills();
      getSelectedSkills(personSkills);
      await getRoleSkills(personSkills);

      setLoading(false);
    }

    getData();
  }, [sync]);

  useEffect(() => {
    selectedPillButton === PillButton.SelectedSkills ? setSelectAll(!!selectedSkills?.length) : setSelectAll(!roleSkills?.length ? false : roleSkills.every(roleSkill => selectedSkills?.some(selectedSkill => selectedSkill.slug === roleSkill.slug)));
  }, [selectedPillButton, selectedSkills]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Assessments" title={!!personToEdit ? `Select skills for ${personToEdit.firstName} ${personToEdit.lastName}` : 'Select skills'} subtitle="Manage the skills you would like to assess" divider />

      <div className="card">
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          <SearchBar loading={loading} label="Search" onInput={event => setQuery((event.target as HTMLInputElement).value)} responsive />

          <div style={{ display: 'flex', flexDirection: 'row', gap: 15, alignItems: 'center', justifyContent: 'space-between' }}>
            <PillButtonGroup
              backgroundColour="borders"
              buttons={[
                { id: PillButton.AllSkills, label: `${PillButtonDescriptions.AllSkills} (${allSkillsCount})`, active: selectedPillButton === PillButton.AllSkills },
                { id: PillButton.RoleSkills, label: `${PillButtonDescriptions.RoleSkills} (${roleSkillsCount})`, active: selectedPillButton === PillButton.RoleSkills },
                { id: PillButton.SelectedSkills, label: `${PillButtonDescriptions.SelectedSkills} (${selectedSkillsCount})`, active: selectedPillButton === PillButton.SelectedSkills },
              ].filter(pillButton => roleSlugs ?? pillButton.id !== PillButton.RoleSkills)}
              onClick={buttons => setSelectedPillButton(buttons[0].id ?? PillButton.AllSkills)}
            />

            <div className={classNames({ invisible: selectedPillButton === PillButton.AllSkills || (selectedPillButton === PillButton.SelectedSkills && !selectedSkills?.length) })}>
              <CheckboxCard
                id="selectAll"
                checked={selectAll}
                onChange={() => {
                  selectedPillButton === PillButton.RoleSkills
                    ? setSelectedSkills(currentState => {
                        if (!currentState) return;
                        let nextState: CompanySkillListItemResponse[] = structuredClone(currentState);
                        selectAll
                          ? (nextState = nextState.filter(selectedSkill => !roleSkills?.some(roleSkill => roleSkill.slug === selectedSkill.slug)))
                          : roleSkills?.forEach(roleSkill => {
                              if (!nextState.some(selectedSkill => selectedSkill.slug === roleSkill.slug)) nextState.push(roleSkill);
                            });
                        return nextState;
                      })
                    : setSelectedSkills([]);
                }}
              >
                <div className="card">Select all</div>
              </CheckboxCard>
            </div>
          </div>

          <div className={classNames({ hidden: selectedPillButton !== PillButton.AllSkills })} style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <AllSkillsWidget
              showRoleSkills={!!roleSlugs}
              learnerSlug={personToEdit?.learnerSlug}
              loading={loading}
              setLoading={setLoading}
              query={query}
              personSkills={personSkills ?? []}
              roleSkills={roleSkills ?? []}
              setAllSkillsCount={setAllSkillsCount}
              selectedSkills={selectedSkills ?? []}
              setSelectedSkills={setSelectedSkills}
            />
          </div>

          <div className={classNames({ hidden: selectedPillButton !== PillButton.RoleSkills })}>
            <RoleSkillsWidget learnerSlug={personToEdit?.learnerSlug} loading={loading} query={query} roleSkills={roleSkills ?? []} setRoleSkillsCount={setRoleSkillsCount} selectedSkills={selectedSkills ?? []} setSelectedSkills={setSelectedSkills} />
          </div>

          <div className={classNames({ hidden: selectedPillButton !== PillButton.SelectedSkills })}>
            <SelectedSkillsWidget showRoleSkills={!!roleSlugs} learnerSlug={personToEdit?.learnerSlug} loading={loading} query={query} roleSkills={roleSkills ?? []} setSelectedSkillsCount={setSelectedSkillsCount} selectedSkills={selectedSkills ?? []} setSelectedSkills={setSelectedSkills} />
          </div>
        </div>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Back</Anchor>

        <Button type="button" onClick={() => onAssign(selectedSkills ?? [])}>
          Assign
        </Button>
      </footer>
    </div>
  );
}

