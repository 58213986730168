import React from 'react';
import { IconProps } from '@keplerco/core';

export function ImportRoleIcon({ size = 80 }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 100 101" fill="none">
      <circle cx="50" cy="50.5" r="39.3921" stroke="#9B57F2" strokeWidth="1.21586" />
      <mask id="mask0_2647_11285" maskUnits="userSpaceOnUse" x="17" y="17" width="66" height="67">
        <circle cx="50" cy="50.5" r="33" fill="var(--grc_4)" />
      </mask>
      <g mask="url(#mask0_2647_11285)">
        <circle cx="49.8282" cy="50.3282" r="32.8282" fill="white" />
        <circle cx="49.8282" cy="50.3282" r="32.8282" fill="#9B57F2" />
      </g>
      <g clipPath="url(#clip0_2647_11285)">
        <path
          d="M64.3542 49.5278C62.3576 49.5278 60.547 48.504 59.5156 46.7927C59.3984 46.5991 59.4621 46.3496 59.6556 46.2324C59.8466 46.1178 60.0988 46.1764 60.2159 46.3725C61.0996 47.8368 62.6454 48.7128 64.3542 48.7128C67.018 48.7128 69.1826 46.5457 69.1826 43.8844C69.1826 41.2232 67.0154 39.056 64.3542 39.056C63.5061 39.056 62.6734 39.2776 61.9451 39.6977C61.749 39.8098 61.4994 39.7436 61.3873 39.5475C61.2753 39.3514 61.3415 39.1018 61.5376 38.9898C62.3907 38.4983 63.3635 38.2385 64.3567 38.2385C67.4687 38.2385 70.0026 40.7699 70.0026 43.8844C70.0026 46.999 67.4713 49.5303 64.3567 49.5303L64.3542 49.5278Z"
          fill="black"
        />
        <path
          d="M64.3338 39.0559C64.1249 39.0559 63.9467 38.8955 63.9288 38.6841C63.7149 36.2139 61.6012 34.2784 59.1182 34.2784C57.4145 34.2784 55.8203 35.1901 54.9545 36.6621C54.8399 36.8556 54.5903 36.9218 54.3968 36.8072C54.2032 36.6927 54.137 36.4431 54.2516 36.2495C55.2626 34.5331 57.1268 33.4661 59.1182 33.4661C62.0214 33.4661 64.4916 35.73 64.7412 38.6179C64.7616 38.842 64.5935 39.0406 64.3694 39.0585C64.3567 39.0585 64.3465 39.0585 64.3338 39.0585V39.0559Z"
          fill="black"
        />
        <path
          d="M50.6635 43.9785C50.6177 43.9785 50.5719 43.9709 50.526 43.9556C48.2977 43.1509 46.8003 41.0168 46.8003 38.6459C46.8003 35.5339 49.3316 33 52.4462 33C53.9054 33 55.2882 33.5552 56.3425 34.5611C56.5055 34.7164 56.5106 34.9736 56.3553 35.1366C56.1999 35.2996 55.9427 35.3073 55.7772 35.1494C54.8757 34.2886 53.6915 33.8149 52.4436 33.8149C49.7799 33.8149 47.6152 35.9821 47.6152 38.6433C47.6152 40.6705 48.8962 42.4964 50.8011 43.184C51.0124 43.2604 51.1219 43.4947 51.0455 43.7061C50.9844 43.8716 50.8291 43.976 50.661 43.976L50.6635 43.9785Z"
          fill="black"
        />
        <path
          d="M38.7476 42.4786C38.5694 42.4786 38.4038 42.3615 38.3554 42.1807C38.2179 41.6892 38.1492 41.1798 38.1492 40.6654C38.1492 37.5534 40.6805 35.0195 43.7951 35.0195C45.2976 35.0195 46.711 35.6027 47.7755 36.6621C47.9359 36.82 47.9359 37.0798 47.7755 37.2402C47.6176 37.4006 47.3578 37.4006 47.1974 37.2402C46.2857 36.3336 45.076 35.8345 43.7925 35.8345C41.1287 35.8345 38.9641 38.0016 38.9641 40.6629C38.9641 41.1034 39.0227 41.5389 39.1398 41.9591C39.2009 42.1756 39.0736 42.4022 38.8546 42.4608C38.8189 42.471 38.7807 42.4761 38.7451 42.4761L38.7476 42.4786Z"
          fill="black"
        />
        <path
          d="M56.6325 53.2838H50.383C50.1564 53.2838 49.9756 53.1004 49.9756 52.8763C49.9756 52.6522 50.1589 52.4689 50.383 52.4689H56.6325C59.0263 52.4689 61.0331 50.755 61.4023 48.3943C61.438 48.1702 61.6443 48.0174 61.8684 48.053C62.0899 48.0887 62.2427 48.2975 62.2096 48.519C61.7767 51.2796 59.4312 53.2838 56.6325 53.2838Z"
          fill="black"
        />
        <path
          d="M47.9078 54.7941C46.2041 54.7941 44.8162 53.4062 44.8162 51.7025C44.8162 49.9988 46.2041 48.6108 47.9078 48.6108C49.6115 48.6108 50.9994 49.9988 50.9994 51.7025C50.9994 53.4062 49.6115 54.7941 47.9078 54.7941ZM47.9078 49.4283C46.6523 49.4283 45.6336 50.4495 45.6336 51.7025C45.6336 52.9554 46.6548 53.9766 47.9078 53.9766C49.1607 53.9766 50.1819 52.9554 50.1819 51.7025C50.1819 50.4495 49.1607 49.4283 47.9078 49.4283Z"
          fill="black"
        />
        <path
          d="M56.4952 67.3158C54.7915 67.3158 53.4036 65.9279 53.4036 64.2242C53.4036 62.5205 54.7915 61.1326 56.4952 61.1326C58.1989 61.1326 59.5868 62.5205 59.5868 64.2242C59.5868 65.9279 58.1989 67.3158 56.4952 67.3158ZM56.4952 61.95C55.2397 61.95 54.221 62.9712 54.221 64.2242C54.221 65.4771 55.2422 66.4983 56.4952 66.4983C57.7481 66.4983 58.7693 65.4771 58.7693 64.2242C58.7693 62.9712 57.7481 61.95 56.4952 61.95Z"
          fill="black"
        />
        <path
          d="M47.9078 67.3158C46.2041 67.3158 44.8162 65.9279 44.8162 64.2242C44.8162 62.5205 46.2041 61.1326 47.9078 61.1326C49.6115 61.1326 50.9994 62.5205 50.9994 64.2242C50.9994 65.9279 49.6115 67.3158 47.9078 67.3158ZM47.9078 61.95C46.6523 61.95 45.6336 62.9712 45.6336 64.2242C45.6336 65.4771 46.6548 66.4983 47.9078 66.4983C49.1607 66.4983 50.1819 65.4771 50.1819 64.2242C50.1819 62.9712 49.1607 61.95 47.9078 61.95Z"
          fill="black"
        />
        <path
          d="M39.3233 67.3158C37.6196 67.3158 36.2317 65.9279 36.2317 64.2242C36.2317 62.5205 37.6171 61.1326 39.3233 61.1326C41.0296 61.1326 42.4149 62.5205 42.4149 64.2242C42.4149 65.9279 41.0296 67.3158 39.3233 67.3158ZM39.3233 61.95C38.0678 61.95 37.0492 62.9712 37.0492 64.2242C37.0492 65.4771 38.0704 66.4983 39.3233 66.4983C40.5762 66.4983 41.5974 65.4771 41.5974 64.2242C41.5974 62.9712 40.5762 61.95 39.3233 61.95Z"
          fill="black"
        />
        <path
          d="M56.4949 61.9501C56.2683 61.9501 56.0875 61.7668 56.0875 61.5427V57.8806H39.7305V61.5427C39.7305 61.7693 39.5471 61.9501 39.323 61.9501C39.0989 61.9501 38.9155 61.7668 38.9155 61.5427V57.4731C38.9155 57.2465 39.0989 57.0657 39.323 57.0657H56.4949C56.7216 57.0657 56.9024 57.249 56.9024 57.4731V61.5427C56.9024 61.7693 56.7191 61.9501 56.4949 61.9501Z"
          fill="black"
        />
        <path d="M47.9079 61.9501C47.6813 61.9501 47.5005 61.7668 47.5005 61.5426V54.3892C47.5005 54.1625 47.6838 53.9817 47.9079 53.9817C48.1321 53.9817 48.3154 54.165 48.3154 54.3892V61.5426C48.3154 61.7693 48.1321 61.9501 47.9079 61.9501Z" fill="black" />
        <path
          d="M33.2139 44.1466C33.1604 44.1466 33.1094 44.1365 33.0585 44.1161C32.8497 44.0295 32.7504 43.7901 32.837 43.5838C33.6493 41.6178 35.5491 40.3496 37.6756 40.3496C37.8997 40.3496 38.1212 40.3649 38.3351 40.3904C38.5592 40.4184 38.7171 40.6221 38.6891 40.8462C38.6611 41.0703 38.4625 41.2333 38.2333 41.2002C38.0499 41.1773 37.8615 41.1645 37.673 41.1645C35.8776 41.1645 34.2733 42.2367 33.5882 43.8945C33.522 44.0524 33.3717 44.1466 33.2113 44.1466H33.2139Z"
          fill="black"
        />
        <path
          d="M45.3409 53.3399H35.0882C32.2818 53.3399 30 51.0581 30 48.2518C30 45.4454 32.2818 43.1636 35.0882 43.1636C35.5287 43.1636 35.9668 43.2196 36.3921 43.3317C36.6111 43.3902 36.7409 43.6118 36.6824 43.8308C36.6263 44.0473 36.4022 44.1797 36.1832 44.1211C35.8267 44.0269 35.46 43.9785 35.0882 43.9785C32.7325 43.9785 30.8175 45.8961 30.8175 48.2518C30.8175 50.6074 32.7351 52.525 35.0882 52.525H45.3409C45.5676 52.525 45.7484 52.7084 45.7484 52.9325C45.7484 53.1566 45.565 53.3399 45.3409 53.3399Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2647_11285">
          <rect width="40" height="34.3159" fill="white" transform="translate(30 33)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ImportRoleIcon;
