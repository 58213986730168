import { Anchor, Button, CheckboxCard, PanelHeader, SearchBar, useMatchScreenWidth } from '@keplerco/core';
import React, { useEffect, useState } from 'react';
import { ListItem } from '../../../../../../components/lists/list-item';
import { ActivationStatus, OrganizationLevelType } from '../../../../../../enums';
import { useAppActions, useAppState } from '../../../../../../overmind';
import { CompanyRoleAssignee, ISelectPeopleLayoutProps } from '../people-step.models';
import styles from './select-people.module.css';
import { EmptyState } from '../../../../../../components/general/empty-state/empty-state';
import { PagingSearchParams, SearchingSearchParams, SortingSearchParams } from '../../../../../../models/overmind/search-params';
import { Cohort, CohortType } from '../../../../../assessments/wizard/peer/people-step/people-step.models';

export function SelectPeopleLayout({ selectedPeople, selectedCohort, setSelectedPeople, onBack }: ISelectPeopleLayoutProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [peopleToSelect, setPeopleToSelect] = useState<CompanyRoleAssignee[]>(selectedPeople);
  const [people, setPeople] = useState<CompanyRoleAssignee[]>();
  const [request, setRequest] = useState<SearchingSearchParams & SortingSearchParams & PagingSearchParams>({
    search: undefined,
    sortAscending: true,
    page: 1,
    pageSize: 99999, // TODO: get correct size
  });

  const isMobile = useMatchScreenWidth('mobile');

  async function getDepartmentPeople(selectedCohort: Cohort) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, departmentSlug: selectedCohort.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          selected: selectedPeople.some(selected => selected.entitySlug === employee.learnerSlug) || peopleToSelect.some(selected => selected.entitySlug === employee.learnerSlug),
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          department: employee.department.name,
          team: employee.team.name,
          entitySlug: employee.learnerSlug,
          organizationLevel: OrganizationLevelType.Learner,
        })) ?? []
    );
    return;
  }
  async function getTeamPeople(selectedCohort: Cohort) {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug, teamSlug: selectedCohort.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          selected: selectedPeople.some(selected => selected.entitySlug === employee.learnerSlug) || peopleToSelect.some(selected => selected.entitySlug === employee.learnerSlug),
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          department: employee.department.name,
          team: employee.team.name,
          entitySlug: employee.learnerSlug,
          organizationLevel: OrganizationLevelType.Learner,
        })) ?? []
    );
    return;
  }

  async function getPeople() {
    const response = await actions.searchPeople({ ...request, companySlug: companyVariables.slug });
    setPeople(
      response?.employees
        .filter(employee => employee.activationStatus !== ActivationStatus.Archived)
        .map(employee => ({
          selected: selectedPeople.some(selected => selected.entitySlug === employee.learnerSlug) || peopleToSelect.some(selected => selected.entitySlug === employee.learnerSlug),
          name: `${employee.firstName} ${employee.lastName}`,
          email: employee.email,
          department: employee.department.name,
          team: employee.team.name,
          entitySlug: employee.learnerSlug,
          organizationLevel: OrganizationLevelType.Learner,
        })) ?? []
    );
  }

  useEffect(() => {
    async function getData() {
      if (!selectedCohort) return;
      setLoading(true);

      if (selectedCohort?.type === CohortType.People) {
        await getPeople();
      }

      if (selectedCohort?.type === CohortType.Department) {
        await getDepartmentPeople(selectedCohort);
      }

      if (selectedCohort?.type === CohortType.Team) {
        await getTeamPeople(selectedCohort);
      }
      setLoading(false);
    }

    getData();
  }, [request, selectedCohort]);

  return (
    <div className="panelContent">
      <PanelHeader supertitle="Roles" title={`${selectedCohort?.name ?? ''} ${selectedCohort?.type === CohortType.People ? selectedCohort?.type : selectedCohort?.type?.toLowerCase()}`} subtitle="Select the people that you want to assign to this role." divider />
      <div>
        <div className="card" style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={styles.searchSelect}>
            <SearchBar label="Search" loading={loading} responsive onInput={event => setRequest(currentState => ({ ...currentState, search: (event.target as HTMLInputElement).value, page: 1 }))} />

            <CheckboxCard
              id="selectAll"
              checked={!people?.some(person => !person.selected) && !!people?.length}
              onChange={() => {
                const allSelected = people?.every(person => person.selected);

                setPeople(currentState => {
                  if (!currentState) return [];
                  return currentState.map(person => ({ ...person, selected: allSelected ? false : true }));
                });

                setPeopleToSelect(currentState => {
                  const nextState: CompanyRoleAssignee[] = structuredClone(currentState);

                  people?.forEach(person => {
                    const index = nextState.findIndex(temp => temp.entitySlug === person.entitySlug);
                    if (allSelected && index !== -1) nextState.splice(index, 1);
                    else if (!allSelected && index === -1) nextState.push({ ...person, selected: true });
                  });
                  return nextState;
                });
              }}
            >
              <div className="card">Select all</div>
            </CheckboxCard>
          </div>

          {loading && !people ? (
            <div className={styles.loadingState}>
              <object data={'/graphics/loading-state.graphic.svg'} type="image/svg+xml">
                <img src={'graphics/loading-state.graphic.svg'} alt="" />
              </object>

              <span className="heading5">Loading...</span>
            </div>
          ) : (
            <>
              {people?.length ? (
                <div className={styles.list}>
                  {people?.map(person => {
                    return (
                      <ListItem
                        key={person.entitySlug.toString()}
                        selected={person.selected}
                        onClick={() => {
                          setPeople(currentState => {
                            if (!currentState) return;
                            currentState.forEach(temp => {
                              if (temp.entitySlug !== person.entitySlug) return;
                              temp.selected = !temp.selected;
                            });
                            return currentState;
                          });

                          setPeopleToSelect(currentState => {
                            let nextState: CompanyRoleAssignee[] = structuredClone(currentState);
                            person.selected
                              ? (nextState = nextState.filter(temp => temp.entitySlug !== person.entitySlug))
                              : nextState.push({
                                ...person,
                                selected: true,
                              });
                            return nextState;
                          });
                        }}
                      >
                        <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: isMobile ? 'auto' : '1fr 1fr' }}>
                          <div>
                            <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                              {person.name}
                            </div>

                            <div className="caption2">
                              {person.department} | {person.team}
                            </div>
                          </div>
                          <div className="caption2">{person.email}</div>
                        </div>
                      </ListItem>
                    );
                  })}
                </div>
              ) : (
                <div style={{}}>
                  <EmptyState title="No people found" />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <footer className="panelFooter">
        <Anchor onClick={onBack}>Cancel</Anchor>

        <Button type="button" onClick={() => setSelectedPeople(peopleToSelect)}>
          Done
        </Button>
      </footer>
    </div>
  );
}
