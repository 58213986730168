import React, { useEffect, useState } from 'react';
import { AuthenticationStatus } from './enums/authentication-status';
import { FetchType } from './enums';
import { Loader } from './components/general/loading-state/loaders/loader/loader';
import { KeplerState } from './models/kepler-state';
import { Route, Routes } from 'react-router-dom';
import { page, track } from './library/helpers/segment';
import { AppLoaderLayer } from './components/general/loading-state/loader-layers/app-loader-layer/app-loader-layer';
import { useAppActions, useAppState } from './overmind';
import { useWatchLocation } from './library/hooks/useWatchLocation';
import { useDataSyncTimeout } from './library/hooks/useDataSyncTimeout';
import { NavigationOutlet } from './navigation/navigation.outlet';
import { Mode, PagePath } from './navigation/navigation.enums';
import { AdministrationRouter } from './navigation/routers/administration.router';
import { AccountRouter } from './navigation/routers/account.router';
import { AnalysisRouter } from './navigation/routers/analysis.router';
import { LearningJourneyRouter } from './navigation/routers/learning-journey.router';
import { AnalyticsRouter } from './navigation/routers/analytics.router';
import { AssessmentsRouter } from './navigation/routers/assessments.router';
import { LearningManagementRouter } from './navigation/routers/learning-management.router';
import { OnboardingPage } from './pages/onboarding/onboarding.page';
import { CompaniesPage } from './pages/companies/companies.page';
import { PermissionType } from './enums/permission-type';
import { RouterGuard } from './navigation/guards/router.guard';
import { ProfilePage } from './pages/profile/profile.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { VersionPage } from './pages/version/version.page';
import { ErrorRedirect, ModeRedirect } from './navigation/routers/routers.helpers';
import { useMatchScreenWidth } from '@keplerco/core';
import { ErrorPage } from './pages/error/error.page';
import LearnerAssessmentsPage from './pages/assessments/learner-assessments/learner-assessments.page';
import { ReportManagementCMSPage } from './pages/report-management/report-management.cms.page';
import { useWatchSearchParams } from './library/hooks/useWatchSearchParams';
import { RoleSkillManagementRouter } from './navigation/routers/role-skill-management.router';
import { MyCareerRouter } from './navigation/routers/my-career.router';
import { scrollToTop } from './library/helpers/scroll-to-top';
import { YourSkillsPage } from './pages/your-skills/your-skills.page';

export function App(): JSX.Element {
  const actions = useAppActions();
  const { authenticationStatus, isInitialising, user, mayViewSideNav, sideNavVisible, sideNavCollapsed, companyVariables, mode } = useAppState<KeplerState>();

  const isTablet = useMatchScreenWidth('tablet');
  const isMobile = useMatchScreenWidth('mobile');

  const [mainPaddingLeft, setMainPaddingLeft] = useState<number>(0);

  useWatchLocation(() => {
    page();
    scrollToTop();
  });

  useWatchSearchParams(() => scrollToTop());

  useEffect(() => {
    function watchClicks(event: MouseEvent) {
      const target = event.target as HTMLElement;

      const analyticsProperties: IAnalyticsProperties = {
        userId: user?.learnerSlug,
        companyId: user?.companySlug,

        elementTag: target.tagName.toLowerCase(),
        elementId: target.id,
        elementContent: target.textContent,
      };

      track('KPLR-CLICK-EVENT', analyticsProperties);
    }

    function watchSubmit(event: SubmitEvent) {
      const target = event.target as HTMLFormElement;

      const analyticsProperties: IAnalyticsProperties = { formId: target.id };
      [...new FormData(target).entries()].forEach(entry => {
        if (!entry[0].toLowerCase().includes('password')) analyticsProperties[entry[0]] = entry[1];
      });

      track('KPLR-FORM-EVENT', analyticsProperties);
    }

    window.addEventListener('click', watchClicks);
    window.addEventListener('submit', watchSubmit);

    return () => {
      window.removeEventListener('click', watchClicks);
      window.removeEventListener('submit', watchSubmit);
    };
  }, []);

  useEffect(() => {
    async function authenticate() {
      await actions.authenticate();
    }

    async function initialise() {
      actions.startLoader({ path: PagePath.root, type: FetchType.PageFetching });
      await actions.initialiseForeground();
      actions.stopLoader(PagePath.root);

      await actions.initialiseBackground();
    }

    if (authenticationStatus === AuthenticationStatus.Unknown) authenticate();
    if (authenticationStatus === AuthenticationStatus.Authenticated) initialise();
  }, [authenticationStatus]);

  useEffect(() => {
    if (!companyVariables.slug) return;
    mode === Mode.PlatformManagement ? actions.getCompanyVariables(companyVariables.slug) : actions.getUserCompanyVariables();
    actions.getSkillLevelConfig();
  }, [companyVariables.slug]);

  useEffect(() => {
    if (isMobile || isTablet) return void setMainPaddingLeft(0);
    if (!mayViewSideNav) return void setMainPaddingLeft(0);
    if (!sideNavVisible) return void setMainPaddingLeft(0);
    if (sideNavCollapsed) return void setMainPaddingLeft(90);
    setMainPaddingLeft(245);
  }, [mayViewSideNav, sideNavVisible, sideNavCollapsed, isTablet, isMobile]);

  useDataSyncTimeout();

  if (isInitialising || authenticationStatus === AuthenticationStatus.Unknown)
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <Loader />
      </div>
    );

  return (
    <main style={{ paddingLeft: mainPaddingLeft }}>
      <NavigationOutlet isMobile={isMobile} isTablet={isTablet} />

      <Routes>
        {/* routers */}
        <Route
          path={PagePath.accountConfig}
          element={
            <RouterGuard authStatusRequired="Unauthenticated">
              <AccountRouter />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.analysisConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Incomplete">
              <AnalysisRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.learningJourneyConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <LearningJourneyRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.yourCareerConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <MyCareerRouter />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.administrationConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Administration}>
              <AdministrationRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.analyticsConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Analytics}>
              <AnalyticsRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.assessmentManagementConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.Assessments}>
              <AssessmentsRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.learningManagementConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.LearningManagement}>
              <LearningManagementRouter />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.reportManagement}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.PlatformManagement}>
              <ReportManagementCMSPage />
            </RouterGuard>
          }
        />
        <Route
          path={PagePath.roleSkillManagementConfig}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" permissionTypeRequired={PermissionType.RoleManagement}>
              <RoleSkillManagementRouter />
            </RouterGuard>
          }
        />

        {/* pages */}
        <Route
          path={PagePath.onboarding}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Incomplete">
              <OnboardingPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.profile}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete">
              <ProfilePage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.version}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete">
              <VersionPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.companies}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.PlatformManagement}>
              <CompaniesPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.dashboard}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <DashboardPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.yourSkills}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <YourSkillsPage />
            </RouterGuard>
          }
        />

        <Route
          path={PagePath.yourAssessments}
          element={
            <RouterGuard authStatusRequired="Authenticated" onboardingStatusRequired="Complete" skillAssessmentStatusRequired="Complete" modeRequired={Mode.LearningDashboard}>
              <LearnerAssessmentsPage />
            </RouterGuard>
          }
        />

        <Route path={PagePath.error} element={<ErrorPage />} />

        {/* redirects */}
        <Route
          path={PagePath.root}
          element={
            <RouterGuard authStatusRequired="Authenticated">
              <ModeRedirect />
            </RouterGuard>
          }
        />
        <Route path={PagePath.rootWildcard} element={<ErrorRedirect />} />
      </Routes>

      <AppLoaderLayer />
    </main>
  );
}
