import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { ITertiaryButtonProps } from './tertiary-button.models';
import styles from './tertiary-button.module.css';

export const TertiaryButton = forwardRef<HTMLButtonElement, ITertiaryButtonProps>((props, outerRef) => {
  const { children, disabled, onClick, onSubmit, onReset } = props;

  const cleansedProps = {
    ...props,
    children: undefined,
    disabled: undefined,
  };

  const innerRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(outerRef, () => innerRef.current!, []);

  return (
    <button
      {...cleansedProps}
      ref={innerRef}
      className={styles.tertiaryButton}
      aria-disabled={disabled}
      onClick={event => {
        if (disabled) return;
        if (!!onClick) return onClick(event);
        if (!!onSubmit) return onSubmit(event);
        if (!!onReset) return onReset(event);
      }}
    >
      {children}
    </button>
  );
});

TertiaryButton.displayName = 'TertiaryButton';
