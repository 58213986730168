import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import * as store from '../all-assessments/all-assessments.store';
import { SkillAssessmentDetailsWidget } from '../../../widgets';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppActions, useAppState } from '../../../overmind';
import { SkillAssessment, SkillAssessmentAssignees } from '../../../models';
import { FetchType } from '../../../enums';
import { PeerEndorsementSteps } from './peer-endorsement/peer-endorsement';
import { QuestionnaireSteps } from './questionnaire/questionnaire';
import { ScheduleAction } from '../../../enums/schedule-action';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagePath } from '../../../navigation/navigation.enums';
import { FieldsOfPracticeSteps } from './fields-of-practice/fields-of-practice';
import { Button, PageHeader, SmartSelect, SmartSelectSection } from '@keplerco/core';
import { AssessmentPreferencesResponse } from '../../../models/overmind/assessment-preferences';
import { defaultPreferences } from '../../../widgets/forms/skill-assessment/preferences/preferences.helpers';
import styles from './create-skill-assessment.module.css';
import classNames from 'classnames';

export function CreateSkillAssessmentsCMSPage(): JSX.Element {
  const { assessmentSlug } = useParams();
  const navigate = useNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [activeStep, setActiveStep] = useState(0);

  const [state, dispatch] = useReducer(store.reducer, store.initialState);
  const [activeSkillAssessmentSlug, setActivateSkillAssessmentSlug] = useState<string>();
  const [activeSkillAssessmentId, setActivateSkillAssessmentId] = useState<number>();

  const [assessmentDraftDetails, setAssessmentDraftDetails] = useState<SkillAssessment | null>(null);
  const [assessmentAssigneesData, setAssessmentAssignees] = useState<SkillAssessmentAssignees | null>(null);

  const [skillAssessmentType, setSkillAssessmentType] = useState<'peerEndorsement' | 'roleBased' | 'questionnaire' | 'fieldsOfPractice'>();

  const [hasUserWithNoSkills, setHasUserWithNoSkills] = useState<boolean>(false);

  const [preferences, setPreferences] = useState<AssessmentPreferencesResponse>(defaultPreferences);
  async function getPreferences(assessmentSlug: string | undefined) {
    if (!assessmentSlug) return;
    const response = await actions.getAssessmentPreferences({ companySlug: companyVariables.slug!, assessmentSlug });
    setPreferences(response ?? defaultPreferences);
  }
  useEffect(() => {
    getPreferences(activeSkillAssessmentSlug);
  }, [activeSkillAssessmentSlug]);

  const stepRefs = Array(4)
    .fill(null)
    .map(() => useRef<HTMLDivElement>(null));

  const SkillAssessmentSteps = useMemo(() => {
    if (activeStep >= 1) {
      if (skillAssessmentType === 'peerEndorsement' || skillAssessmentType === 'roleBased') {
        return PeerEndorsementSteps({
          index: activeStep,
          stepRefs: stepRefs,
          setHasUserWithNoSkills: setHasUserWithNoSkills,
          handleStepCompletion: step => {
            getPreferences(activeSkillAssessmentSlug);
            handleStepCompletion(step);
          },
          skillAssessmentSlug: activeSkillAssessmentSlug!,
          handleEndorsementSelectionChange: a => handleEndorsementSelectionChange(a),
          assessmentAssignees: assessmentAssigneesData,
          isDraft: !!assessmentSlug,
          onFinalStepCompletion: () => setSkillAssessmentCreationReady(true),
          preferences,
          setPreferences,
        });
      } else if (skillAssessmentType === 'questionnaire') {
        return QuestionnaireSteps({
          index: activeStep,
          stepRefs: stepRefs,
          handleStepCompletion: step => handleStepCompletion(step),
          onFinalStepCompletion: () => setSkillAssessmentCreationReady(true),
          assessmentAssignees: assessmentAssigneesData,
          skillAssessmentSlug: activeSkillAssessmentSlug!,
          isDraft: !!assessmentSlug,
          skillAssessmentId: activeSkillAssessmentId!,
        });
      } else if (skillAssessmentType === 'fieldsOfPractice') {
        return FieldsOfPracticeSteps({
          index: activeStep,
          stepRefs: stepRefs,
          assessmentAssignees: assessmentAssigneesData,
          handleStepCompletion: step => handleStepCompletion(step),
          onFinalStepCompletion: () => setSkillAssessmentCreationReady(true),
          skillAssessmentSlug: activeSkillAssessmentSlug!,
          isDraft: !!assessmentSlug,
          skillAssessmentId: activeSkillAssessmentId!,
        });
      }
    }

    return [] as JSX.Element[];
  }, [skillAssessmentType, activeStep, activeSkillAssessmentSlug, assessmentAssigneesData, stepRefs]);

  const [skillAssessmentCreationReady, setSkillAssessmentCreationReady] = useState<boolean>(false);

  React.useEffect(() => {
    dispatch({
      type: store.AssessmentsOverviewActionTypes.SetRequest,
      payload: {
        ...state.request,
        companySlug: companyVariables.slug,
      },
    });

    dispatch({ type: store.AssessmentsOverviewActionTypes.SetHeader, payload: { companySlug: companyVariables.slug!, entityName: 'Create Assessment' } });
  }, []);

  async function fetchAssessment(params: { fallbackSlug?: string; loadSteps?: boolean } = {}) {
    const { fallbackSlug, loadSteps = true } = params;
    const slugValue = assessmentSlug ?? fallbackSlug;

    if (slugValue && companyVariables.slug) {
      setActivateSkillAssessmentSlug(assessmentSlug);

      actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.PageFetching });

      const assessmentData = await actions.getSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: slugValue });

      const typeOfAssessment = assessmentData?.includeBehavioralSkills ? 'questionnaire' : 'peerEndorsement';

      if (!skillAssessmentType) setSkillAssessmentType(typeOfAssessment);

      if (assessmentData) {
        setAssessmentDraftDetails(assessmentData);
        setActivateSkillAssessmentId(assessmentData.id);
        if (loadSteps) setActiveStep(typeOfAssessment === 'peerEndorsement' ? 3 : 4);
      }

      const assessmentAssignees = await actions.getSkillAssessmentAssignees({
        companySlug: companyVariables.slug!,
        skillAssessmentSlug: slugValue,
      });

      if (assessmentAssignees) {
        setAssessmentAssignees(assessmentAssignees);
        actions.stopLoader(PagePath.assessmentManagementCreate);
      }
    }
  }

  React.useEffect(() => {
    fetchAssessment();
  }, [assessmentSlug, companyVariables.slug, assessmentAssigneesData === null]);

  function handleStepCompletion(stepIndex: number) {
    setActiveStep(stepIndex + 1);
    setTimeout(() => {
      const nextStepRef = stepRefs[stepIndex + 1];
      if (nextStepRef && nextStepRef.current) {
        nextStepRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }

  async function handleBaseStepCompletion(slug: string) {
    setActivateSkillAssessmentSlug(slug);
    window.history.replaceState(null, '', `${PagePath.assessmentManagementBase}${PagePath.assessmentManagementUpdate.replace(':companySlug', companyVariables.slug!).replace(':assessmentSlug', slug)}`);
    handleStepCompletion(0);
  }

  function handleEndorsementSelectionChange(updatedAssessmentAssignees: SkillAssessmentAssignees) {
    setAssessmentAssignees(updatedAssessmentAssignees);
  }

  async function handleSaveDraft() {
    navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
  }

  async function handleSaveAndActivateAssessment() {
    actions.startLoader({ path: PagePath.assessmentManagementCreate, type: FetchType.Sending });

    if (skillAssessmentType === 'peerEndorsement' || skillAssessmentType === 'roleBased') {
      await actions.completeEndorsementSkillAssignment({ assessmentSlug: assessmentSlug ?? activeSkillAssessmentSlug! });
      await actions.activateSkillAssessment({ companySlug: companyVariables.slug!, assessmentSlug: assessmentSlug ?? activeSkillAssessmentSlug! });
      navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
    } else {
      const [creationSchedule, completionSchedule] = await Promise.all([
        actions.getScheduledAction({ attachedEntity: activeSkillAssessmentId!, scheduleType: ScheduleAction.AssessmentCreation }),
        actions.getScheduledAction({ attachedEntity: activeSkillAssessmentId!, scheduleType: ScheduleAction.AssessmentCompletion }),
      ]);

      if (!!completionSchedule && !!creationSchedule) {
        await actions.updateScheduleAction({ ...creationSchedule, isActive: true });
        await actions.updateScheduleAction({ ...completionSchedule, isActive: true });
      }

      navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAllAssessments.replace(':companySlug', companyVariables.slug!)}`);
    }

    actions.stopLoader(PagePath.assessmentManagementCreate);
  }

  return (
    <PageLoaderLayer path={PagePath.assessmentManagementCreate}>
      <div className="pageWrapper wrapper">
        <PageHeader breadcrumbs={state.crumbs} subtitle="Create an assessment and get skill scores for your learners" title="Update Assessment" divider />

        <br />

        <SmartSelect>
          {[
            <SmartSelectSection
              key="beginner-step"
              headerContent={
                <React.Fragment>
                  <h4 className="heading4">Assessment Details:</h4>
                  <span className="caption1">Name your assessment, and choose how many Kepler Points you would like to award</span>
                </React.Fragment>
              }
            >
              <SkillAssessmentDetailsWidget
                onRadioChange={async selectedOption => {
                  setSkillAssessmentType(selectedOption);
                }}
                assessmentDraftDetails={assessmentDraftDetails}
                onSkillAssessmentSlugCreated={setActivateSkillAssessmentSlug}
                onSkillAssessmentIdCreated={setActivateSkillAssessmentId}
                onStepComplete={slug => handleBaseStepCompletion(slug)}
                key="assessmentDetails"
                slug={activeSkillAssessmentSlug}
              />
            </SmartSelectSection>,
            ...SkillAssessmentSteps,
          ].filter(i => i !== null)}
        </SmartSelect>

        {skillAssessmentCreationReady && (
          <footer className={classNames('card', styles.footer)}>
            <Button filled onClick={handleSaveDraft} type="button">
              Save draft
            </Button>

            <Button disabled={hasUserWithNoSkills} onClick={handleSaveAndActivateAssessment} type="button">
              Save & activate
            </Button>
          </footer>
        )}

        {!skillAssessmentCreationReady && <div style={{ height: 150 }} />}
      </div>
    </PageLoaderLayer>
  );
}
