import classNames from 'classnames';
import React from 'react';
import { IStarRatingProps } from './star-rating.models';
import styles from './star-rating.module.css';
import { StarFillIcon } from './star-fill.icon';
import { StarOutlineIcon } from './star-outline.icon';

export const StarRating = React.forwardRef((props: IStarRatingProps, ref: any) => {
  const { value = null, max = 5 } = props;

  const nativeProps = {
    ...props,
    children: void 0,
    label: void 0,
    value: void 0,
    id: void 0,
    max: void 0,
  };

  const options: { id: string; value: number }[] = Array.from(Array(max), (_, i) => ({
    id: crypto.randomUUID(),
    value: i + 1,
  }));

  return (
    <div style={{ display: 'inline-flex', gap: 10 }}>
      {options.map(option => {
        let selected = false;
        if (value !== null && typeof value !== 'undefined') selected = option.value <= value;

        return (
          <div key={option.id} className={classNames(styles.container, { selected })}>
            <input type="radio" className={styles.input} {...nativeProps} id={option.id} value={option.value} ref={ref} checked={option.value === value} />

            <div className={styles.star}>{selected ? <StarFillIcon /> : <StarOutlineIcon />}</div>
          </div>
        );
      })}
    </div>
  );
});

StarRating.displayName = 'StarRating';
