import React, { useEffect, useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { IDailyActivityWidgetProps } from './daily-activity.models';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { OrganizationLevelType } from '../../../enums';
import { lineChartIncrements } from '../../../library/helpers/line-chart-increments';
import { formatDate, ILineChartDataPoint, LineChart, ThemeColours } from '@keplerco/core';
import { PagePath } from '../../../navigation/navigation.enums';
import { DateSearchParams, PermissionSearchParams } from '../../../models/overmind/search-params';

export function DailyActivityWidget({ entity, path }: IDailyActivityWidgetProps): JSX.Element {
  const actions = useAppActions();
  const { dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [colourMap, setColourMap] = useState<Map<string, ThemeColours>>();
  const [data, setData] = useState<ILineChartDataPoint[]>([]);

  useEffect(() => {
    async function getData() {
      if (!entity) return;

      setLoading(true);

      const request: DateSearchParams & PermissionSearchParams = {
        startDate: path === PagePath.dashboard ? undefined : dateRange?.from?.toJSON(),
        endDate: path === PagePath.dashboard ? undefined : dateRange?.to?.toJSON(),
        organizationLevel: entity.organizationLevel,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
      };

      if (entity.organizationLevel === OrganizationLevelType.Department) request.departmentSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Team) request.teamSlug = entity.entity.slug;
      if (entity.organizationLevel === OrganizationLevelType.Learner) request.learnerSlug = entity.entity.slug;
      const response = await actions.analyticsGetDailyActivity(request);

      if (!response) {
        setColourMap(undefined);
        setData([]);
        setLoading(false);
        return;
      }

      setColourMap(new Map([[response.name, 'baby-blue']]));
      setData(response.data.map(item => ({ label: formatDate(new Date(item.dateTime)), value: [{ name: response.name, value: item.count, dataType: 'none' }] })));
      setLoading(false);
    }

    getData();
  }, [dateRange, companyVariables.slug, entity]);

  if (loading) return <SkeletonLoader height="475px" />;

  const increment = lineChartIncrements(data);

  return (
    <div className="card">
      {!colourMap || !data.length ? (
        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={590} title="No daily activity" />
      ) : (
        <React.Fragment>
          <h3 className="heading3" style={{ marginBottom: 15 }}>
            Daily Activity
          </h3>

          <LineChart yIncrements={increment} colorMap={colourMap} data={data} height={400} width={1024} />
        </React.Fragment>
      )}
    </div>
  );
}
