import React from 'react';
import { RatingScalePoint } from '../../../../models/rating-scale';
import { colourString, GraphColours } from '@keplerco/core';
import { CircleCheckTwoToneIcon } from '../../../../design/icons/circle-check-two-tone.icon';
import { CSSProperties } from 'styled-components';
import styles from './rater-radio-button.module.css';

export function RaterRadioButton(props: { colour: GraphColours | 'primary' | 'link-text'; details: { id: number; label: string; rating?: number }; point: RatingScalePoint; onChange: () => void; bgColor?: string }): JSX.Element {
  return (
    <li className={styles.listItem} style={{ '--colour': colourString(props.colour) } as CSSProperties}>
      <input className={styles.input} type="radio" id={props.details.id.toString()} name={props.details.label} value={props.point.rating} checked={props.details.rating !== undefined && props.details.rating === props.point.rating} onChange={props.onChange} />

      <div className={styles.radioButton}>
        <span className={styles.radioButtonBackground}>
          <CircleCheckTwoToneIcon primaryTone="transparent" />
        </span>
      </div>

      <label className={styles.label} htmlFor={props.details.id.toString()}>
        {props.point.label}
      </label>
    </li>
  );
}
