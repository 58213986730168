import React, { Fragment, useState } from 'react';
import styles from './import-role.module.css';
import classNames from 'classnames';
import { SelectRoleLayout } from './select-role.layout';
import { colourString, Button, Panel } from '@keplerco/core';
import { AstronautsIcon } from '../../../../../assessments/wizard/peer/people-step/astronauts.icon';
import { CompanyRoleListItemResponse } from '../../../../../../models/overmind/company-entity';

export function ImportRoleLayout({ selectedRole, setSelectedRole }: { selectedRole: CompanyRoleListItemResponse | undefined, setSelectedRole: (role: CompanyRoleListItemResponse | undefined) => void }): JSX.Element {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div className={classNames('card', styles.container)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h5 className="heading5">Role details</h5>
          <p className="body" style={{ color: colourString('text_1') }}>
            Define job roles, assign essential skills, and set performance standards.
          </p>
        </div>

        <div className="card action-card">
          <AstronautsIcon />

          <div className="action-card-content">
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <h3 className="subtitle">Search for a role</h3>
            </div>
            <p className="body2">Search global competency frameworks to find and import a role.</p>
          </div>

          <Button type="button" onClick={() => setOpen(true)}>
            Search
          </Button>
        </div>
      </div>

      <Panel open={open} onClose={() => setOpen(false)}>
        <SelectRoleLayout
          onBack={() => setOpen(false)}
          setSelectedRole={(role) => {
            setSelectedRole(role);
            setOpen(false);
          }}
          selectedRole={selectedRole}
        />
      </Panel>
    </Fragment>
  );
}

