import React, { useEffect, useState } from 'react';
import { IEntitiesLayoutProps } from './entities.models';
import { PagePath } from '../../../navigation/navigation.enums';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { GridItemLayout, GridLayout, PageHeader, Pager, capitalCase } from '@keplerco/core';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import classNames from 'classnames';
import styles from './entities.module.css';
import { NavLink } from 'react-router-dom';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { EntitySkillScoresCard } from '../../../components/cards/entity-skill-scores-card/entity-skill-scores-card';
import { OrganizationLevelType, SkillType } from '../../../enums';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { CompanyAveragesWidget } from '../../../widgets/analytics/company-averages/company-averages.widget';
import { ResponseGroup } from '../../../enums/analytics';
import { PermissionType } from '../../../enums/permission-type';
import { SkillScoresResponse, SkillScoresRequest } from '../../../models/overmind/analytics';
import { SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { useAppActions, useAppState } from '../../../overmind';
import { extractPermission } from '../../../library/helpers/permissions/extract-permission';
import { extractOrganizationLevel } from '../../../library/helpers/permissions/extract-organization-level';

export function EntitiesLayout(props: IEntitiesLayoutProps): JSX.Element {
  const pageSize = 6;

  const actions = useAppActions();
  const { user, permissions, dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [showCompanyAverages, setShowCompanyAverages] = useState<boolean>(false);
  const [data, setData] = useState<SkillScoresResponse[]>();
  const [pagedData, setPagedData] = useState<SkillScoresResponse[]>([]);

  const [activePageNumber, setActivePageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);

  const [entity, setEntity] = useState<PermissionsEntity>();

  useEffect(() => {
    if (user?.isSystemAdmin) return void setShowCompanyAverages(true);

    const analyticsPermission = extractPermission(permissions, PermissionType.Analytics);
    if (!analyticsPermission) return void setShowCompanyAverages(false);

    const companyOrganizationLevel = extractOrganizationLevel(analyticsPermission.organizationLevels, OrganizationLevelType.Company);
    setShowCompanyAverages(!!companyOrganizationLevel);
  }, [user, permissions]);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const searchParams: SkillScoresSearchParams = {
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        organizationLevel: props.type === 'departments' ? OrganizationLevelType.Department : OrganizationLevelType.Team,
        companySlug: companyVariables.slug,
        departmentSlug: props.type === 'teams' && entity?.entity.slug !== companyVariables.slug ? entity?.entity.slug : undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: companyVariables.scoreComparison,
      };
      const request: SkillScoresRequest = {
        responseGroup: ResponseGroup.SkillType,
        searchParams,
      };
      const temp = await actions.analyticsGetSkillScores(request);
      setData(temp);
      setPageCount(!!temp?.length ? Math.ceil(temp.length / pageSize) : 0);
      setPagedData(!!temp?.length ? temp.slice(0, pageSize) : []);
      setActivePageNumber(1);

      setLoading(false);
    }

    getData();
  }, [props.path, props.type, companyVariables.slug, dateRange, entity]);

  function onPageChangeHandler(pageNumber: number) {
    const end = pageNumber * pageSize;
    const start = end - pageSize;
    const tempPagedData = data?.slice(start, end);
    setPagedData(tempPagedData ?? []);
    setActivePageNumber(pageNumber);
  }

  return (
    <div className={classNames('pageWrapper wrapper', [styles.container])}>
      {props.type === 'teams' ? (
        <PageHeader
          breadcrumbs={[
            { name: 'Analytics', url: PagePath.analyticsBase },
            { name: 'Teams', url: window.location.pathname },
          ]}
          title={capitalCase(props.type)}
          type="actions"
          divider
        >
          {props.type === 'teams' && (
            <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
              <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />
            </div>
          )}
        </PageHeader>
      ) : (
        <PageHeader
          breadcrumbs={[
            { name: 'Analytics', url: PagePath.analyticsBase },
            { name: 'Departments', url: window.location.pathname },
          ]}
          title={capitalCase(props.type)}
          divider
        />
      )}

      {showCompanyAverages && <CompanyAveragesWidget />}

      {loading && (
        <div className={styles.skeletonLoaderContainer}>
          <div style={{ display: 'flex', gap: 15, marginBottom: 15 }}>
            <SkeletonLoader height="210" />

            <SkeletonLoader height="210" />

            <SkeletonLoader height="210" />
          </div>

          <div style={{ display: 'flex', gap: 15 }}>
            <SkeletonLoader height="210" />

            <SkeletonLoader height="210" />

            <SkeletonLoader height="210" />
          </div>
        </div>
      )}

      {!loading && !pagedData?.length && <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={450} title={`No ${props.type} found`} />}

      {!loading && !!pagedData?.length && (
        <React.Fragment>
          <GridLayout columnCount={3}>
            {pagedData.map(entity => {
              const to = `${PagePath.analyticsBase}${
                props.type === 'departments' ? PagePath.analyticsDepartment.replace(':companySlug', companyVariables.slug!).replace(':departmentSlug', entity.slug) : PagePath.analyticsTeam.replace(':companySlug', companyVariables.slug!).replace(':teamSlug', entity.slug)
              }`;
              const overallScore = entity.score;
              const technicalScore = entity.skillTypes?.find(skillType => skillType.type === SkillType.Technical)?.score;
              const behaviouralScore = entity.skillTypes?.find(skillType => skillType.type === SkillType.Behavioural)?.score;

              return (
                <GridItemLayout key={entity.slug}>
                  <NavLink className={styles.navLink} to={to}>
                    <EntitySkillScoresCard name={entity.name} path={PagePath.analyticsDepartments} overallScore={overallScore} technicalScore={technicalScore} behaviouralScore={behaviouralScore} />
                  </NavLink>
                </GridItemLayout>
              );
            })}
          </GridLayout>

          {pageCount > 1 && <Pager activePageNumber={activePageNumber} pageCount={pageCount} onPageChange={onPageChangeHandler} />}
        </React.Fragment>
      )}
    </div>
  );
}
