import React from 'react';
import styles from './tags.module.css';
import { Textfield } from '@keplerco/core';

interface InputWithButtonProps {
  inputValue: string;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick: () => void;
  buttonText: string;
  inputLabel: string;
}

/**
 * @deprecated Do not implement this on new features, existing implementations will be replaced
 */
function InputWithButton({ inputValue, onInputChange, onButtonClick, buttonText, inputLabel }: InputWithButtonProps) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    onButtonClick();
  }
  return (
    <div className={styles.container}>
      <Textfield className={styles.input} responsive label={inputLabel} name="text" type="text" value={inputValue} onChange={onInputChange} />

      <button className={styles.button} onClick={handleClick}>
        {buttonText}
      </button>
    </div>
  );
}

export default InputWithButton;
