import React, { Fragment } from 'react';
import spaceBackground from '../space-background.svg';
import astronaut from '../astronaut.svg';
import { Anchor, Button, EditIcon, ListItemLayout, ListLayout, SkillLevel, useMatchScreenWidth } from '@keplerco/core';
import styles from './summary-step.module.css';
import classNames from 'classnames';
import { ISummaryStepProps } from './summary-step.models';
import { useAppActions, useAppState } from '../../../../../overmind';
import { useKeplerNavigate } from '../../../../../navigation/guards/use-kepler-navigate';
import { PagePath } from '../../../../../navigation/navigation.enums';
import { FetchType } from '../../../../../enums';

export default function SummaryStepLayout({ path, role, skills, people, goToStep, completeStep }: ISummaryStepProps) {
  const keplerNavigate = useKeplerNavigate();

  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  return (
    <Fragment>
      <div className={classNames('card', styles.container)}>
        {!role?.active && (
          <div className={styles.header}>
            <img src={spaceBackground} alt="Space Background" className={styles.background} />

            <div className={styles.headerContent}>
              <div className={styles.textSection}>
                <h1 className="heading2" style={{ color: 'var(--contrast-text)' }}>
                  Hooray! You are nearly there!
                </h1>

                <span className={classNames('body2', styles.caption)} style={{ color: 'var(--contrast-text)' }}>
                  Please confirm that all the information is correct before activating your role.
                  <br />
                  Activating your role will add it to your company profile.
                </span>
              </div>

              <div className={styles.astronaut}>
                <img src={astronaut} alt="Astronaut" className={styles.astronaut} />
              </div>
            </div>
          </div>
        )}

        <div className={styles.cardContent}>
          <div>
            <div className="card" style={{ marginBottom: 20 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                <h2 className="heading5">Details</h2>

                <Button fullWidthMobile type="button" square theme="background" onClick={() => goToStep(1)} className={classNames({ invisible: path === PagePath.roleSkillManagementWizardKeplerAI })}>
                  <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                    <EditIcon />
                    Edit
                  </div>
                </Button>
              </div>

              <ListLayout>
                <ListItemLayout>
                  <div className="caption2" style={{ color: 'var(--accent-2)' }}>
                    Role Name
                  </div>
                  {role?.name}
                </ListItemLayout>

                <ListItemLayout>
                  <div className="caption2" style={{ color: 'var(--accent-2)' }}>
                    Role Description
                  </div>
                  {role?.description}
                </ListItemLayout>
              </ListLayout>
            </div>

            <div className="card" style={{ marginBottom: 20 }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                <h2 className="heading5">Skills ({skills.length})</h2>

                <Button fullWidthMobile type="button" square theme="background" onClick={() => goToStep(2)}>
                  <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                    <EditIcon />
                    Edit
                  </div>
                </Button>
              </div>

              <div className={styles.list}>
                <ListLayout>
                  {skills.map(skill => (
                    <ListItemLayout key={skill.slug}>
                      <div className={classNames(styles.cardContent, styles.skill, 'card')}>
                        <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                          {skill.name}
                          <div className="caption1">{skill.description}</div>
                        </div>

                        <div>
                          <SkillLevel title="Proficiency" level={skill.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!skill.level && skill.level !== 0} />
                        </div>
                      </div>
                    </ListItemLayout>
                  ))}
                </ListLayout>
              </div>
            </div>

            <div className="card">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                <h2 className="heading5">People ({people?.length})</h2>

                <Button fullWidthMobile type="button" square theme="background" onClick={() => goToStep(3)}>
                  <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                    <EditIcon />
                    Edit
                  </div>
                </Button>
              </div>

              <div className={styles.list}>
                <ListLayout>
                  {people?.map(person => (
                    <ListItemLayout key={person.entitySlug}>
                      <div style={{ display: 'grid', gridTemplateColumns: isMobile ? 'auto' : '1fr 1fr' }} className={classNames(styles.cardContent, 'card')}>
                        <div>
                          <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                            {person.name}
                          </div>

                          <div className="caption2">
                            {person.department} | {person.team}
                          </div>
                        </div>

                        <div className="caption1">{person.email}</div>
                      </div>
                    </ListItemLayout>
                  ))}
                </ListLayout>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="card" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 30, background: 'var(--background)' }}>
        <Anchor arrow reverse onClick={() => goToStep(3)}>
          Back
        </Anchor>

        <div className={classNames({ invisible: role?.active })} style={{ display: 'flex', alignItems: 'center', gap: 30 }}>
          <Anchor onClick={() => keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementAllRoles.replace(':companySlug', companyVariables.slug!)}`)}>Save draft</Anchor>

          <Button
            type="button"
            filled
            arrow={false}
            onClick={async () => {
              if (!role?.slug) return;

              actions.startLoader({ path, type: FetchType.Sending });

              completeStep();
              await actions.activateCompanyRole({ companySlug: companyVariables.slug!, roleSlug: role.slug });
              keplerNavigate(`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementAllRoles.replace(':companySlug', companyVariables.slug!)}`);

              actions.stopLoader(path);
            }}
          >
            Activate
          </Button>
        </div>
      </footer>
    </Fragment>
  );
}
