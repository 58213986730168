import React, { Fragment, useEffect, useLayoutEffect, useReducer, useState } from 'react';
import { EmptyState } from '../../components/general/empty-state/empty-state';
import { FetchType } from '../../enums';
import { useAppActions, useAppState } from '../../overmind';
import { ReportType } from '../../models/overmind/report-type';
import { PagePath } from '../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { PagerConnector } from '../../components/general/pager-connector/pager-connector';
import { ReportManagementFocusPanel } from './report-management-focus-panel';
import * as store from './report-management.cms.store';
import { ChevronIcon, Chip, ListLayout, PageHeader, Pager, Panel, Searchfield, Toggle, kebabCase, search, useMatchScreenWidth } from '@keplerco/core';
import reportManagementStyles from './report-management.module.css';
import classNames from 'classnames';

type ReportEntity = {
  [key: string]: boolean;
};

export function ReportManagementCMSPage(): JSX.Element {
  const actions = useAppActions();
  const { companyVariables } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const isMobile = useMatchScreenWidth('mobile');

  const [loadingStates, setLoadingStates] = useState<ReportEntity>({});
  const [tempValues, setTempValues] = useState<ReportEntity>({});

  async function getData() {
    actions.startLoader({ path: PagePath.reportManagement, type: FetchType.PageFetching });

    const data = await actions.getAdministrationReportTemplates(companyVariables.slug!);
    if (!!data) {
      dispatch({ type: store.ReportManagementCMSActionTypes.SetReports, payload: data });
      dispatch({ type: store.ReportManagementCMSActionTypes.SetPaging, payload: { ...state.paging, pageSize: Math.ceil(data.length / 5) ?? 1 } });
    }

    actions.stopLoader(PagePath.reportManagement);
  }
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (state.reports !== undefined) {
      onClickPagerHandler(state.paging.currentPage);
    }
  }, [state.reports]);

  useLayoutEffect(() => {
    if (isMobile) getData();
  }, [isMobile]);

  async function handleOnChange(report: ReportType) {
    if (!report || !companyVariables.slug) return;

    const reportType = report.reportType;
    const params = { reportType: reportType, companySlug: companyVariables.slug };

    const removeReport = tempValues[reportType] ?? report.isVisibleToCompany;

    setLoadingStates(prevStates => ({ ...prevStates, [report.reportType]: true }));

    setTempValues(prevStates => ({ ...prevStates, [reportType]: !removeReport }));

    const result = removeReport ? await actions.removeReportTemplate(params) : await actions.addReportTemplate(params);

    if (!!result && !!result.error) {
      setLoadingStates(prevStates => ({ ...prevStates, [reportType]: false }));
      setTempValues(prevStates => ({ ...prevStates, [reportType]: removeReport }));
    }

    setLoadingStates(prevStates => ({ ...prevStates, [report.reportType]: false }));
  }

  function onClickOpen(report: ReportType) {
    dispatch({ type: store.ReportManagementCMSActionTypes.SetReport, payload: report });
  }

  function onInputSearch(searchValue: string) {
    if (!state.reports) return;
    const searchResult = search(state.reports, searchValue, 'name', 'reportType');

    dispatch({
      type: store.ReportManagementCMSActionTypes.SetPaging,
      payload: {
        pageData: searchResult?.slice(0, 5),
        pageSize: Math.ceil(searchResult?.length / 5) ?? 1,
        currentPage: 1,
      },
    });
  }

  function onClickPagerHandler(pageNumber: number) {
    const startIndex = (pageNumber - 1) * 5;
    const endIndex = startIndex + 5;

    dispatch({
      type: store.ReportManagementCMSActionTypes.SetPaging,
      payload: {
        currentPage: pageNumber,
        pageData: state.reports?.slice(startIndex, endIndex) ?? [],
        pageSize: state.paging.pageSize,
      },
    });
  }

  function handleSubmit() {
    getData();
    dispatch({ type: store.ReportManagementCMSActionTypes.SetReport, payload: undefined });
  }

  function handleCancel() {
    dispatch({ type: store.ReportManagementCMSActionTypes.SetReport, payload: undefined });
  }

  function getLabel(report: ReportType) {
    return tempValues[report.reportType] !== undefined ? (tempValues[report.reportType] ? 'Active' : 'Inactive') : report.isVisibleToCompany ? 'Active' : 'Inactive';
  }

  return (
    <>
      <PageLoaderLayer path={PagePath.reportManagement}>
        <div className="pageWrapper wrapper" style={{ display: 'flex', flexDirection: 'column', gap: 30, alignItems: 'flex-start' }}>
          <PageHeader
            breadcrumbs={[
              {
                name: 'Report management',
                url: window.location.pathname,
              },
            ]}
            title="Report management"
            divider
          />

          <div>
            <Searchfield label="Search" responsive={isMobile} onInput={onInputSearch} />
          </div>

          {!!state.paging.pageData && state.paging.pageData.length > 0 ? (
            <React.Fragment>
              <ListLayout gap={10}>
                {state.paging.pageData.map(report => (
                  <div key={report.name} className={classNames('card', reportManagementStyles.card)} onClick={() => onClickOpen(report)}>
                    {isMobile ? (
                      <div style={{ width: '100%' }}>
                        <div>
                          <Chip label={getLabel(report)} variant="tiny" backgroundColour={report.isVisibleToCompany ? 'apple' : 'default'} colour="contrast-text" />
                        </div>

                        <div className="subtitle">{report.name}</div>

                        <p className="caption1">{report.description}</p>
                      </div>
                    ) : (
                      <Fragment>
                        <div className={reportManagementStyles.toggle} onClick={event => event.stopPropagation()}>
                          OFF
                          <Toggle
                            id={`${kebabCase(report.name)}-report`}
                            name="Report"
                            value={tempValues[report.reportType] !== undefined ? tempValues[report.reportType] : report.isVisibleToCompany}
                            isLoading={loadingStates[report.reportType]}
                            clickableArea="toggle"
                            toggleBackgroundColour="primary"
                            toggleForegroundColour="white"
                            size="large"
                            onChange={() => handleOnChange(report)}
                          />
                          ON
                        </div>

                        <div style={{ width: '100%' }}>
                          <div className="subtitle">{report.name}</div>

                          <p className="caption1">{report.description}</p>
                        </div>
                      </Fragment>
                    )}

                    <ChevronIcon tone="primary" />
                  </div>
                ))}
              </ListLayout>

              <PagerConnector pageCount={state.paging.pageSize} onPageChange={onClickPagerHandler} defaultPageNumber={state.paging.currentPage}>
                {connector => {
                  return <Pager {...connector} />;
                }}
              </PagerConnector>
            </React.Fragment>
          ) : (
            <EmptyState title="No reports found" />
          )}
        </div>
      </PageLoaderLayer>
      {!!state.report && (
        <Panel open={!!state.report} onClose={handleCancel}>
          {!!state.report && <ReportManagementFocusPanel report={state.report!} isLoading={loadingStates[state.report?.reportType]} tempValue={tempValues[state.report.reportType]} handleToggle={() => handleOnChange(state.report!)} onSubmit={handleSubmit} onCancel={handleCancel} />}
        </Panel>
      )}
    </>
  );
}
