import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Day } from './datepicker.helpers';
import { IDatepickerProps } from './datepicker.models';
import { useDateRange } from './use-date-range';
import { Button, ChevronIcon, IconButton, safeCallback, useMatchScreenWidth } from '@keplerco/core';
import styles from './datepicker.module.css';
import { CalendarIcon } from './calendar.icon';

// TODO: refactor to <dialog>
export function Datepicker(props: IDatepickerProps): JSX.Element {
  const { range, previous, next, selected, selectDay } = useDateRange(props.defaultDate);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const datepickerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMatchScreenWidth('mobile');

  useEffect(() => {
    function clickWatcher(event: MouseEvent) {
      if (datepickerRef.current?.contains(event.target as any)) return;
      setIsOpen(false);
    }

    window.addEventListener('click', clickWatcher);

    return () => {
      window.removeEventListener('click', clickWatcher);
    };
  }, []);

  function isLessThanMax(day: Day): boolean {
    let isLessThanMax = true;
    if (!!props.maxDate) isLessThanMax = new Date(props.maxDate) > day.toDate();
    return isLessThanMax;
  }

  function isGreaterThanMin(day: Day): boolean {
    let isGreaterThanMin = true;
    if (!!props.minDate) isGreaterThanMin = new Date(props.minDate) < day.toDate();
    return isGreaterThanMin;
  }

  function isValidDay(day: Day): boolean {
    return isLessThanMax(day) && isGreaterThanMin(day);
  }

  function open(target: EventTarget) {
    setIsOpen(true);
    if (!props.fixed) (target as HTMLElement).scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    const selectedDate = selected.toDate();

    if (!!props.maxDate && selectedDate > new Date(props.maxDate)) {
      selectDay(Day.from(props.maxDate));
    }

    if (!!props.minDate && selectedDate < new Date(props.minDate)) {
      selectDay(Day.from(props.minDate));
    }
  }, [props.maxDate, props.minDate]);

  return (
    <React.Fragment>
      {isOpen && isMobile && !props.fixed && <div className={styles.background} />}

      <div ref={datepickerRef} className={classNames(styles.input, { [styles.hasError]: props.haserror })}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} onClick={({ target }) => open(target)}>
          <label className={styles.label}>{props.label ?? 'Date'}</label>
          <output className={styles.output}>{selected.printDate}</output>
        </div>

        <div style={{ display: 'grid', placeItems: 'center' }} onClick={({ target }) => open(target)}>
          <CalendarIcon />
        </div>

        {isOpen && (
          <div className={classNames(styles.dialog, { [styles.fixed]: props.fixed, [styles.autoHeight]: props.autoHeight, [styles.openAbove]: props.openAbove })}>
            <header className={styles.header}>
              <IconButton iconType="stroke" disabled={!isGreaterThanMin(range.firstDayOfRange)} onClick={() => isValidDay(range.firstDayOfRange) && previous()}>
                <ChevronIcon rotation={3} tone="primary" />
              </IconButton>

              <label>
                {range.currentMonthName} {range.year}
              </label>

              <IconButton iconType="stroke" disabled={!isLessThanMax(range.lastDayOfRange)} onClick={() => isValidDay(range.lastDayOfRange) && next()}>
                <ChevronIcon tone="primary" />
              </IconButton>
            </header>

            <ul className={styles.list}>
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(weekday => (
                <li key={weekday} className={styles.listItem}>
                  {weekday}
                </li>
              ))}
            </ul>

            {range.dateRows.map((row, rowIndex) => {
              return (
                <div key={rowIndex} className={styles.week}>
                  {row.map((day, dayIndex) => {
                    const isValid = isValidDay(day);

                    return (
                      <div
                        className={classNames(styles.day, { [styles.overflow]: day.month !== range.currentMonth, [styles.today]: day.isCurrentDay && isValid, [styles.selected]: day.matches(selected), [styles.disabled]: !isValid })}
                        key={`${rowIndex}-${dayIndex}`}
                        onClick={() => {
                          if (isValidDay(day)) {
                            selectDay(day);
                            setIsOpen(false);
                            safeCallback(props.onDateSelected, day.toDate());
                          }
                        }}
                      >
                        {day.day}
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <footer className={styles.footer}>
              <Button type="button" arrow={false} onClick={() => setIsOpen(false)}>
                cancel
              </Button>
            </footer>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
