import React, { useEffect, useReducer, useState } from 'react';
import { AssessmentsSearchRequest, BaseSearchRequest } from '../../../models/overmind/search-request';
import { ColumnConfiguration } from '../../../models/column-configuration';
import { ColumnConfiguratorWidget, ConfirmationModalLayout } from '../../../widgets/layouts';
import { CompletionStatus, FetchStatus, FetchType } from '../../../enums';
import { PagePath } from '../../../navigation/navigation.enums';
import { SkillAssessment } from '../../../models/view/skill-assessment';
import { useAppActions, useAppState } from '../../../overmind';
import { useNavigate, useParams } from 'react-router-dom';
import * as store from './all-assessments.store';
import { PageHeader, Searchfield, Tabs, Tab, Modal, Panel } from '@keplerco/core';
import { SkillAssessmentView } from '../skill-assessment-view/skill-assessment-view.page';
import { AssessmentsOverviewTabType } from './all-assessments.models';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { AssessmentType } from '../../../enums/assessment-type';

function generateCompletionStatusFromTabType(tabType: AssessmentsOverviewTabType): CompletionStatus | undefined {
  switch (tabType) {
    case AssessmentsOverviewTabType.Active: {
      return CompletionStatus.InProgress;
    }

    case AssessmentsOverviewTabType.Draft: {
      return CompletionStatus.NotStarted;
    }

    case AssessmentsOverviewTabType.Closed: {
      return CompletionStatus.Completed;
    }

    default:
      return undefined;
  }
}

export function AllAssessmentsPage(): JSX.Element {
  const params = useParams();
  const navigate = useNavigate();

  const actions = useAppActions();
  const { fetchState, companyVariables } = useAppState();

  const [state, dispatch] = useReducer(store.reducer, store.initialState);

  const [loadingSkillAssessments, setLoadingSkillAssessments] = React.useState<string[]>([]);

  const [activeTabType, setActiveTabType] = useState<AssessmentsOverviewTabType>(AssessmentsOverviewTabType.All);

  async function updateData(request: BaseSearchRequest) {
    const data = await actions.getSkillAssessments(request);
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetData, payload: data });

    actions.stopLoader(PagePath.assessmentManagementAllAssessments);
  }

  useEffect(() => {
    async function initPage() {
      actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.PageFetching });

      const partialRequest: Partial<AssessmentsSearchRequest> = {
        completionStatus: generateCompletionStatusFromTabType(activeTabType),
        isArchived: activeTabType === AssessmentsOverviewTabType.Archived,
        page: 1,
      };

      dispatch({ type: store.AssessmentsOverviewActionTypes.SetHeader, payload: { companySlug: companyVariables.slug!, entityName: companyVariables.name } });
      const request: AssessmentsSearchRequest = { ...state.request, companySlug: params.companySlug!, isScheduled: false, ...partialRequest };
      dispatch({ type: store.AssessmentsOverviewActionTypes.SetRequest, payload: request });

      await updateData(request);
    }

    initPage();
  }, [activeTabType]);

  useEffect(() => {
    if (!state.data) return;
    updateData(state.request);
  }, [state.request]);

  async function onInputHandler(value: string) {
    actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.Custom });
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetRequest, payload: { ...state.request, search: value, page: 1 } });
  }

  async function onPageChangeHandler(page: number) {
    if (!!page && page === state.request?.page) return;

    actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.PageFetching });
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetRequest, payload: { ...state.request, page } });
  }

  async function onClickManageAssessmentDraftHandler(data: SkillAssessment | undefined) {
    if (!data || typeof data.slug === 'undefined') return;

    if (data.assessmentType === AssessmentType.PeerEndorsement) {
      navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizardPeerUpdate.replace(':companySlug', params.companySlug!).replace(':assessmentSlug', data.slug)}`);
      return;
    }

    navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementUpdate.replace(':companySlug', params.companySlug!).replace(':assessmentSlug', data.slug)}`);
  }

  async function onClickActivateHandler(data: SkillAssessment | undefined) {
    if (!data || typeof data.id === 'undefined') return;

    setLoadingSkillAssessments(skillAssessment => [...skillAssessment, String(data.id)]);

    await actions.activateSkillAssessment({ companySlug: params.companySlug!, assessmentSlug: data.slug! });
    updateData(state.request);
    setLoadingSkillAssessments(skillAssessments => [...skillAssessments.filter(sid => String(data.id) !== sid)]);
  }

  async function onClickArchiveDraftAssessmentHandler(data: SkillAssessment | undefined) {
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetArchiveConfirmationModalOpen, payload: { open: true, data: data } });
  }

  async function onClickCompleteHandler(data: SkillAssessment | undefined) {
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetCloseConfirmationModalOpen, payload: { open: true, data: data } });
  }

  async function onClickViewAssigneesHandler(data: SkillAssessment | undefined) {
    if (!data || typeof data.slug === 'undefined') return;

    navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementAssignees.replace(':companySlug', params.companySlug!).replace(':assessmentSlug', data.slug)}`);
  }

  function onClickCancelManagingColumns() {
    dispatch({ type: store.AssessmentsOverviewActionTypes.ManageColumns, payload: { open: false, reset: false } });
  }

  function onClickCancelArchiveModal() {
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetArchiveConfirmationModalOpen, payload: { open: false } });
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetCloseConfirmationModalOpen, payload: { open: false } });
  }

  function onClickCancelCloseModal() {
    dispatch({ type: store.AssessmentsOverviewActionTypes.SetCloseConfirmationModalOpen, payload: { open: false } });
  }
  function onClickResetManagingColumns() {
    dispatch({ type: store.AssessmentsOverviewActionTypes.ManageColumns, payload: { open: true, reset: true } });
  }

  function onClickSubmitManagingColumns(selection: ColumnConfiguration[]) {
    dispatch({ type: store.AssessmentsOverviewActionTypes.EditColumnConfiguration, payload: selection });
    dispatch({ type: store.AssessmentsOverviewActionTypes.ManageColumns, payload: { open: false } });
  }

  async function onSortHandler(column: any) {
    const isCurrentlyAscending = state.request?.sortAscending;
    const currentSortField = state.request?.sortField;

    const newSortDirection = currentSortField === column ? !isCurrentlyAscending : true;

    dispatch({
      type: store.AssessmentsOverviewActionTypes.SetRequest,
      payload: {
        ...state.request,
        sortField: column,
        sortAscending: newSortDirection,
      },
    });
  }

  return (
    <React.Fragment>
      <PageLoaderLayer path={PagePath.assessmentManagementAllAssessments}>
        <div className="wrapper stack" style={{ gap: 30 }}>
          <PageHeader breadcrumbs={state.crumbs} title="All Assessments" />

          <div style={{ width: '100%' }}>
            <Tabs static>
              <Tab id={AssessmentsOverviewTabType.All} name={AssessmentsOverviewTabType.All} active={activeTabType === AssessmentsOverviewTabType.All} onClick={() => setActiveTabType(AssessmentsOverviewTabType.All)} />
              <Tab id={AssessmentsOverviewTabType.Active} name={AssessmentsOverviewTabType.Active} active={activeTabType === AssessmentsOverviewTabType.Active} onClick={() => setActiveTabType(AssessmentsOverviewTabType.Active)} />
              <Tab id={AssessmentsOverviewTabType.Draft} name={AssessmentsOverviewTabType.Draft} active={activeTabType === AssessmentsOverviewTabType.Draft} onClick={() => setActiveTabType(AssessmentsOverviewTabType.Draft)} />
              <Tab id={AssessmentsOverviewTabType.Closed} name={AssessmentsOverviewTabType.Closed} active={activeTabType === AssessmentsOverviewTabType.Closed} onClick={() => setActiveTabType(AssessmentsOverviewTabType.Closed)} />
              <Tab id={AssessmentsOverviewTabType.Archived} name={AssessmentsOverviewTabType.Archived} active={activeTabType === AssessmentsOverviewTabType.Archived} onClick={() => setActiveTabType(AssessmentsOverviewTabType.Archived)} />
            </Tabs>
          </div>

          <div style={{ width: '100%' }}>
            <Searchfield loading={fetchState[PagePath.assessmentManagementAllAssessments].status === FetchStatus.Active && fetchState[PagePath.assessmentManagementAllAssessments].type === FetchType.Custom} onInput={onInputHandler} />
          </div>

          <div style={{ width: '100%' }}>
            {state.data?.assessments && state.data.assessments.length > 0 ? (
              <SkillAssessmentView
                totalPages={state.data?.totalPages ?? 1}
                loadingSkillAssessments={loadingSkillAssessments}
                skillAssessments={state.data?.assessments}
                onConfigClicked={() => dispatch({ type: store.AssessmentsOverviewActionTypes.ManageColumns, payload: { open: true } })}
                request={state.request}
                onSortHandler={onSortHandler}
                onPageChangeHandler={onPageChangeHandler}
                columnConfiguration={state.columnConfiguration}
                onClickManageAssessmentDraftHandler={onClickManageAssessmentDraftHandler}
                onClickActivateHandler={onClickActivateHandler}
                onClickArchiveDraftAssessmentHandler={onClickArchiveDraftAssessmentHandler}
                onClickCompleteHandler={onClickCompleteHandler}
                onClickViewAssigneesHandler={onClickViewAssigneesHandler}
              />
            ) : (
              <EmptyState title="There are no skill assessments yet" buttons={[{ text: 'Create One', onClick: () => navigate(`${PagePath.assessmentManagementBase}${PagePath.assessmentManagementWizard.replace(':companySlug', companyVariables.slug!)}`) }]} />
            )}
          </div>
        </div>
      </PageLoaderLayer>

      <Panel open={!!state.managingColumns} onClose={onClickCancelManagingColumns}>
        <ColumnConfiguratorWidget onReset={onClickResetManagingColumns} onCancel={onClickCancelManagingColumns} onSave={onClickSubmitManagingColumns} columns={state.columnConfiguration} />
      </Panel>

      <Modal open={!!state.isArchiveModalOpen} onClose={onClickCancelArchiveModal}>
        <ConfirmationModalLayout
          titleSize="medium"
          onClickCancel={onClickCancelArchiveModal}
          onClickSubmit={async () => {
            if (state.archiveSkillAssessmentData?.slug) {
              setLoadingSkillAssessments(skillAssessment => [...skillAssessment, String(state.archiveSkillAssessmentData?.id)]);
              await actions.archiveAssessment({ assessmentSlug: state.archiveSkillAssessmentData?.slug });
              dispatch({ type: store.AssessmentsOverviewActionTypes.SetArchiveConfirmationModalOpen, payload: { open: false, data: undefined } });
              updateData(state.request);
              setLoadingSkillAssessments(skillAssessments => [...skillAssessments.filter(sid => String(state.archiveSkillAssessmentData?.id) !== sid)]);
            }
          }}
          title="Are you sure you want to archive this assessment?"
          cancelButtonText="Cancel"
          submitButtonText="Yes, archive"
        />
      </Modal>

      <Modal open={!!state.isCloseModalOpen} onClose={onClickCancelCloseModal}>
        <ConfirmationModalLayout
          onClickCancel={onClickCancelCloseModal}
          onClickSubmit={async () => {
            if (state.closeSkillAssessmentData?.slug) {
              setLoadingSkillAssessments(skillAssessment => [...skillAssessment, String(state.closeSkillAssessmentData?.id)]);
              actions.startLoader({ path: PagePath.assessmentManagementAllAssessments, type: FetchType.Sending });
              await actions.completeSkillAssessment({ companySlug: params.companySlug!, skillAssessmentSlug: state.closeSkillAssessmentData?.slug });
              dispatch({ type: store.AssessmentsOverviewActionTypes.SetCloseConfirmationModalOpen, payload: { open: false, data: undefined } });
              updateData(state.request);
              setLoadingSkillAssessments(skillAssessments => [...skillAssessments.filter(sid => String(state.closeSkillAssessmentData?.id) !== sid)]);
            }
          }}
          title="Are you sure you want to close this assessment?"
          subtitle="This will close the active assessment. People who have not completed the assessment will no longer be able to complete it. Scores will not be affected."
          cancelButtonText="Cancel"
          submitButtonText="Yes, close"
        />
      </Modal>
    </React.Fragment>
  );
}
