import React, { useReducer, useState } from 'react';
import { FetchStatus, FetchType } from '../../enums';
import { IQuestionnaireProps } from './questionnaire.models';
import { KeplerState } from '../../models/kepler-state';
import { MultipleChoiceQuestion } from './multiple-choice-question/multiple-choice-question';
import { useAppActions, useAppState } from '../../overmind';
import classNames from 'classnames';
import { QuestionSetType } from '../../enums/question-set-type';
import { PagePath } from '../../navigation/navigation.enums';
import { NotificationPriority, NotificationType } from '../../notifications/notifications.models';
import { Button, HelpIcon, IconButton, ProgressBar, safeCallback } from '@keplerco/core';
import styles from './questionnaire.module.css';
import { firstUnfinishedOrLast, setQuestionSelectionStatus, sumAllSubQuestions } from './questionnaire.helpers';
import { CircleCheckTwoToneIcon } from '../../design/icons/circle-check-two-tone.icon';

export function QuestionnaireWidget(questionnaire: IQuestionnaireProps): JSX.Element {
  const [_, forceUpdate] = useReducer(num => num + 1, 0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const actions = useAppActions();
  const [completeCount, totalCount, incompleteSections] = sumAllSubQuestions(questionnaire.quesionnaireSkills);
  const firstUnfinished = firstUnfinishedOrLast(questionnaire.quesionnaireSkills);
  const [activeQuestionSection, setActiveQuestionSection] = useState<number>(firstUnfinished);
  const section = questionnaire.quesionnaireSkills[activeQuestionSection];

  let jumpIndex = section.questions.findIndex(i => i.answers.every(t => !t.selected));
  if (jumpIndex === -1) jumpIndex = section.questions.length - 1;

  const [activeSubSection, setActiveSubSection] = useState<number>(jumpIndex);
  const subSection = section.questions[activeSubSection];
  const { fetchState } = useAppState<KeplerState>();

  function NextButtonPartial({ onClick, active, loading }: { onClick: any; active: boolean; loading: boolean }) {
    let text = 'Save & Continue';

    if (activeSubSection >= section.questions.length - 1) {
      if (incompleteSections === 1) {
        text = 'Complete';
      }
    }

    return (
      <Button type="button" filled onClick={onClick} arrow={false} disabled={!active} isLoading={loading}>
        {text}
      </Button>
    );
  }

  return (
    <div className={classNames(styles.container, { [styles.questionnaire]: questionnaire.variation === 'questionnaire' })}>
      <nav id="questionnaire-sidenav" className={styles.nav}>
        <ul className={styles.navList}>
          {questionnaire.quesionnaireSkills.map((skillType, index) => {
            return (
              <li
                onClick={() => {
                  if (!skillType.isComplete && index !== activeQuestionSection) {
                    setActiveQuestionSection(index);
                    setActiveSubSection(0);
                  }
                }}
                className={classNames(styles.navListItem, { [styles.activeSection]: index === activeQuestionSection, [styles.done]: skillType.isComplete })}
                key={skillType.skillId}
              >
                {skillType.skillName}

                <div className={styles.navIcon}>
                  <CircleCheckTwoToneIcon />
                </div>
              </li>
            );
          })}
        </ul>

        {questionnaire.variation !== 'questionnaire' && (
          <div className={styles.progressBar}>
            <ProgressBar round theme="primary" value={completeCount + 1} max={totalCount} />
          </div>
        )}
      </nav>

      <div className={styles.question}>
        <div id="questionnaire-skillname" className={styles.title}>
          <h3 className="heading3">{section.skillName}</h3>

          {!!questionnaire.onSkillQuery && (
            <IconButton
              iconType="fill"
              hoverTone="accent-3"
              onClick={() => {
                safeCallback(questionnaire.onSkillQuery, section);
              }}
            >
              <HelpIcon tone="link-text" />
            </IconButton>
          )}
        </div>

        <ul className={styles.answers}>
          {section.questions.map(question => {
            return (
              <li key={question.id} className={classNames('block', styles.answer, { [styles.active]: question.id === subSection.id })}>
                <MultipleChoiceQuestion
                  question={question.description}
                  answers={question.answers}
                  isSaving={isLoading}
                  onSelection={async (sel, clearSelection) => {
                    const index = question.answers.findIndex(i => i.id === sel.id);
                    const newValue = setQuestionSelectionStatus(question, index, true);

                    setIsLoading(true);
                    forceUpdate(); // TODO: See if this can be removed

                    try {
                      if (questionnaire.variation === 'questionnaire') {
                        await actions.answerQuestionSetQuestion({ questionSetType: questionnaire.questionnaireType ?? QuestionSetType.PostCourseAssessment, questionAnswers: { questionnaireSlug: questionnaire.slug, answerId: sel.id } });
                      } else {
                        await actions.answerQuestion({ questionnaireSlug: questionnaire.slug, answerId: sel.id });
                      }

                      if (process.env.NODE_ENV !== 'production') console.log('Question answer saved', { renderId: _, ...newValue });
                    } catch (error) {
                      setQuestionSelectionStatus(question, index, false);
                      clearSelection();

                      actions.addNotification({
                        title: 'Error saving answer',
                        message: `We couldn't save your answer. Please check your network connect. If this problem persists, please contact support`,
                        active: true,
                        id: `failure_${sel.id}`,
                        type: NotificationType.Error,
                        priority: NotificationPriority.Toast,
                      });
                    }

                    setIsLoading(false);
                  }}
                />
              </li>
            );
          })}
        </ul>

        <footer className={styles.footer}>
          <NextButtonPartial
            loading={fetchState[PagePath.analysisBehaviouralQuestionnaire].status === FetchStatus.Active}
            active={subSection.answers.some(i => i.selected) && !isLoading}
            onClick={async () => {
              // FIXME: Remove this when @kepler/core v3.0.34 is installed
              const active = subSection.answers.some(i => i.selected) && !isLoading;
              if (!active) return;

              if (activeSubSection < section.questions.length - 1) setActiveSubSection(activeSubSection + 1);
              else if (fetchState[PagePath.analysisBehaviouralQuestionnaire].status === FetchStatus.Inactive) {
                actions.startLoader({ path: PagePath.analysisBehaviouralQuestionnaire, type: FetchType.Custom });

                if (questionnaire.variation !== 'questionnaire') {
                  await actions.completeQuestionnaireSection({ questionnaireSlug: questionnaire.slug, answerId: section.skillId });
                }

                if (incompleteSections > 1) {
                  setActiveSubSection(0);

                  const nextIndex = questionnaire.quesionnaireSkills.findIndex((r, i) => !r.isComplete && i > activeQuestionSection);
                  const undoneIndex = questionnaire.quesionnaireSkills.findIndex(r => !r.isComplete);

                  setActiveQuestionSection(nextIndex === -1 ? undoneIndex : nextIndex);

                  section.isComplete = true;
                } else {
                  if (questionnaire.variation !== 'questionnaire') await actions.completeQuestionnaire(questionnaire.slug);
                  safeCallback(questionnaire.onCompleteAll);
                }

                actions.stopLoader(PagePath.analysisBehaviouralQuestionnaire);
              }

              window.document.body.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
            }}
          />
        </footer>
      </div>
    </div>
  );
}
