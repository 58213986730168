import { colourString, IconTwoToneProps } from '@keplerco/core';
import React from 'react';

export function CircleCheckTwoToneIcon({ size = 20, primaryTone = 'secondary', secondaryTone = 'background' }: IconTwoToneProps): JSX.Element {
  return (
    <svg fill="none" viewBox="0 0 23 23" width={size} height={size}>
      <circle cx="11.6572" cy="11.0098" r="10.5" fill={colourString(primaryTone)} stroke={colourString(primaryTone)} />
      <path
        fill={colourString(secondaryTone)}
        fillRule="evenodd"
        d="M16.494 5.48537 9.63794 12.2783 6.50133 9.15075c-.62792-.62642-1.79363-.62642-2.50868 0-.1701.16102-.3056.35503-.39819.57018-.0926.21515-.14036.44697-.14036.68117 0 .2342.04776.466.14036.6811.09259.2152.22809.4092.39819.5702l4.38944 4.3804c.32436.315.75867.4912 1.21078.4912.45213 0 .88643-.1762 1.21073-.4912l8.1119-8.09082c.1702-.16102.3056-.35504.3982-.57019.0926-.21515.1404-.44692.1404-.68115 0-.23423-.0478-.466-.1404-.68115-.0926-.21515-.228-.40917-.3982-.57019-.6714-.58135-1.7921-.58135-2.4185.04507"
        clipRule="evenodd"
      />
    </svg>
  );
}
