import React, { useEffect, useState } from 'react';
import { SkeletonLoader } from '../../../components/general/loading-state/loaders/skeleton-loader/skeleton-loader';
import { useAppActions, useAppState } from '../../../overmind';
import { OrganizationLevelType, SkillType } from '../../../enums';
import { ResponseGroup } from '../../../enums/analytics';
import { SkillScoresSearchParams } from '../../../models/overmind/search-params';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { SkillScoresResponse } from '../../../models/overmind/analytics';
import { SkillLevel, SkillPercentage, scorePercentageRAGColour, scoreLevelRAGColour } from '@keplerco/core';
import styles from './company-averages.module.css';
import { ScoreComparison } from '../../../enums/score-comparison';

export function CompanyAveragesWidget(): JSX.Element {
  const actions = useAppActions();
  const { dateRange, companyVariables } = useAppState();

  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<SkillScoresResponse>();

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const request: SkillScoresSearchParams = {
        startDate: dateRange?.from?.toJSON(),
        endDate: dateRange?.to?.toJSON(),
        organizationLevel: OrganizationLevelType.Company,
        companySlug: companyVariables.slug,
        departmentSlug: undefined,
        teamSlug: undefined,
        learnerSlug: undefined,
        skillSlug: undefined,
        skillSubTypeSlug: undefined,
        includeLearnerCount: false,
        scoreComparison: ScoreComparison.None,
      };

      const temp = await actions.analyticsGetSkillScores({ responseGroup: ResponseGroup.SkillType, searchParams: request });
      setData(!temp?.length ? undefined : temp[0]);

      setLoading(false);
    }

    getData();
  }, []);

  if (loading) return <SkeletonLoader height="195px" />;

  const technicalScore = data?.skillTypes?.find(skillType => skillType.type === SkillType.Technical)?.score;
  const behaviouralScore = data?.skillTypes?.find(skillType => skillType.type === SkillType.Behavioural)?.score;

  return (
    <div className="card">
      {!data ? (
        <EmptyState badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')} badgeIconWidth={90} title="No company averages found" />
      ) : (
        <React.Fragment>
          <h3 className="heading3">Company</h3>

          <div className={styles.averages}>
            <div className={styles.average}>
              <h5 className="heading5">Overall</h5>

              {companyVariables.useLevels ? (
                <SkillLevel level={data.score?.level ?? companyVariables.minLevel} minLevel={companyVariables.minLevel} maxLevel={companyVariables.maxLevel} noLevel={!data.score} dotColour={scoreLevelRAGColour(data.score?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel)} />
              ) : (
                <SkillPercentage percentage={data.score?.percentage ?? 0} noPercentage={!data.score} barColour={scorePercentageRAGColour(data.score?.percentage ?? 0)} />
              )}
            </div>

            <div>
              <h5 className="heading5">Role-based skills</h5>

              {companyVariables.useLevels ? (
                <SkillLevel
                  level={technicalScore?.level ?? companyVariables.minLevel}
                  minLevel={companyVariables.minLevel}
                  maxLevel={companyVariables.maxLevel}
                  noLevel={!technicalScore}
                  dotColour={scoreLevelRAGColour(technicalScore?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel)}
                />
              ) : (
                <SkillPercentage percentage={technicalScore?.percentage ?? 0} noPercentage={!technicalScore} barColour={scorePercentageRAGColour(technicalScore?.percentage ?? 0)} />
              )}
            </div>

            <div>
              <h5 className="heading5">Behavioural skills</h5>

              {companyVariables.useLevels ? (
                <SkillLevel
                  level={behaviouralScore?.level ?? companyVariables.minLevel}
                  minLevel={companyVariables.minLevel}
                  maxLevel={companyVariables.maxLevel}
                  noLevel={!behaviouralScore}
                  dotColour={scoreLevelRAGColour(behaviouralScore?.level ?? 0, companyVariables.minLevel, companyVariables.maxLevel)}
                />
              ) : (
                <SkillPercentage percentage={behaviouralScore?.percentage ?? 0} noPercentage={!behaviouralScore} barColour={scorePercentageRAGColour(behaviouralScore?.percentage ?? 0)} />
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
