import React, { useState, PropsWithChildren, useRef, useEffect } from 'react';
import { Loader } from '../../loaders/loader/loader';
import { FetchStatus } from '../../../../../enums';
import { KeplerState } from '../../../../../models/kepler-state';
import { useAppState } from '../../../../../overmind';
import { PagePath } from '../../../../../navigation/navigation.enums';
import styles from './account-page-loader-layer.module.css';
import classNames from 'classnames';

export function AccountPageLoaderLayer({
  path,
  children,
}: PropsWithChildren<{ path: PagePath.accountActivate | PagePath.accountVerifyEmail | PagePath.accountForgotPassword | PagePath.accountLogin | PagePath.accountRegister | PagePath.accountResetPassword | PagePath.accountSetPassword }>): JSX.Element {
  const contentRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  const { fetchState } = useAppState<KeplerState>();

  const [status, setStatus] = useState<FetchStatus>(FetchStatus.Inactive);

  const time = useRef<NodeJS.Timeout | undefined>(undefined);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const init = useRef<NodeJS.Timeout | undefined>(undefined);

  function showLoader(content: HTMLDivElement, loader: HTMLDivElement) {
    loader.classList.add(styles.loaderLoading);
    content.classList.add(styles.contentLoading);
    content.style.height = content.getBoundingClientRect().height + 'px';
  }

  function hideLoader(content: HTMLDivElement, loader: HTMLDivElement) {
    content.classList.remove(styles.contentLoading);
    content.style.height = '';
    loader.classList.remove(styles.loaderLoading);
  }

  // If the initial status is inactive, hide the loader after a certain amount of time.
  // Because the "styles.loaderLoading" and "styles.contentLoading" classes are added by default, without this an infinite loader would occur if no action is triggered by the page parent to stop the loader.
  useEffect(() => {
    if (status === FetchStatus.Inactive)
      init.current = setTimeout(() => {
        const content = contentRef.current;
        const loader = loaderRef.current;

        if (!!parent && !!content && !!loader) hideLoader(content, loader);
      }, 1000);
  }, []);

  // If an init timeout exists and the status changes because and action was triggered by the parent component to start the loader, cancel hiding the loader.
  useEffect(() => {
    if (!!init.current && status === FetchStatus.Active) clearTimeout(init.current);
  }, [status]);

  useEffect(() => {
    if ((status === undefined || status === FetchStatus.Inactive) && fetchState[path].status === FetchStatus.Active) {
      time.current = setTimeout(() => {
        time.current = undefined;
        setForceUpdate(latest => latest + 1);
      }, 1000);
      setForceUpdate(latest => latest + 1);

      const content = contentRef.current;
      const loader = loaderRef.current;

      if (!!content && !!loader) showLoader(content, loader);
    }

    setStatus(fetchState[path].status);
  }, [fetchState[path]]);

  useEffect(() => {
    if (status === FetchStatus.Inactive && !time.current) {
      const content = contentRef.current;
      const loader = loaderRef.current;

      if (!!content && !!loader) hideLoader(content, loader);
    }
  }, [status, time, forceUpdate]);

  return (
    <React.Fragment>
      <div ref={contentRef} className={classNames(styles.content, styles.contentLoading)}>
        {children}
      </div>

      <div ref={loaderRef} className={classNames(styles.loader, styles.loaderLoading)}>
        <Loader />
      </div>
    </React.Fragment>
  );
}
