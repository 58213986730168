import React, { useState } from 'react';
import { IRaterProps } from '../raters.models';
import classNames from 'classnames';
import { RaterRadioButton } from '../rater-radio-button/rater-radio-button';
import { ExplainerFocusPanelLayout } from '../../../../widgets/layouts';
import { Checkbox, HelpIcon, IconButton, Panel, capitalCase, safeCallback } from '@keplerco/core';
import { Counter } from './counter/counter';
import styles from './rater.module.css';
import raterRadioButtonStyles from '../rater-radio-button/rater-radio-button.module.css';

const DONT_KNOW_VALUE = 0;

export function Rater(props: IRaterProps): JSX.Element {
  const { colour = 'primary' } = props;

  const maxWidth = props.scale.length * 70 + (props.scale.length - 1) * 45;

  const [open, setOpen] = useState<boolean>();

  function onChangeHandler(target: EventTarget & HTMLInputElement) {
    const input = target as HTMLInputElement;
    const isChecked = input.checked;
    safeCallback(props.onChange, isChecked ? DONT_KNOW_VALUE : void 0);
  }

  return (
    <React.Fragment>
      <div className={classNames(styles.rater, { [styles.fade]: props.fadeOnComplete && props.data.rating !== null && props.data.rating !== undefined })}>
        <header className={classNames(styles.raterHeader, { [styles.disabled]: props.data.rating === DONT_KNOW_VALUE && props.data.rating !== 0 })}>
          <h4 className="heading4">{props.data.label}</h4>

          {!!props.data.description && (
            <IconButton iconType="fill" onClick={() => setOpen(true)}>
              <HelpIcon />
            </IconButton>
          )}
        </header>

        <div className={styles.raterButtons}>
          <ul style={{ maxWidth }} className={classNames(raterRadioButtonStyles.list, { [raterRadioButtonStyles.faded]: props.data.rating === DONT_KNOW_VALUE && props.data.rating !== 0 })}>
            {props.scale.map(point => {
              return <RaterRadioButton key={point.label} colour={colour} details={{ id: props.data.id, label: props.data.label, rating: props.data.rating }} point={point} onChange={() => safeCallback(props.onChange, point.rating)} />;
            })}
          </ul>

          {!!props.extraRating && (
            <div className={styles.raterExtraRating}>
              {props.extraRating.count !== undefined ? (
                <div className={styles.raterExtraRatingCounter}>
                  <span className={classNames('caption2', styles.raterExtraRatingCounterText)}>{props.extraRating.label}</span>
                  <Counter max={props.extraRating.maxValue} count={props.extraRating.count ?? 0} onChange={value => safeCallback(props.extraRating?.onChange, value)} />
                </div>
              ) : (
                <Checkbox id={props.data.label} name="" value="" checked={props.data.rating === DONT_KNOW_VALUE} onChange={({ target }) => onChangeHandler(target)}>
                  {props.extraRating.label}
                </Checkbox>
              )}
            </div>
          )}
        </div>
      </div>

      {open && (
        <Panel open={open} onClose={() => setOpen(false)}>
          {open && <ExplainerFocusPanelLayout title={capitalCase(props.data.label)} description={props.data.description ?? ''} onClose={() => setOpen(false)} />}
        </Panel>
      )}
    </React.Fragment>
  );
}
