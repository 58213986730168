import React, { useState } from 'react';
import { useAppActions, useAppState } from '../../../overmind';
import { PermissionsEntity } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.models';
import { PermissionsEntityDropdownWidget } from '../../../widgets/analytics/permissions-entity-dropdown/permissions-entity-dropdown.widget';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageHeader } from '@keplerco/core';
import { SkillsBreakdownWidget, SkillScoreOverTimeWidget } from '../../../widgets';
import { Daterange } from '../../../components/inputs/daterange/daterange.component';

export function SkillsPage(): JSX.Element {
  const actions = useAppActions();
  const { dateRangeOutput } = useAppState();

  const [entity, setEntity] = useState<PermissionsEntity>();

  return (
    <div className="pageWrapper wrapper" style={{ display: 'flex', flexDirection: 'column', gap: 30, paddingBottom: 30 }}>
      <PageHeader
        breadcrumbs={[
          { name: 'Analytics', url: PagePath.analyticsBase },
          { name: 'Skills', url: window.location.pathname },
        ]}
        title="Skills"
        type="actions"
        divider
      >
        <div className="headerActions" style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
          <PermissionsEntityDropdownWidget entity={entity} onClick={setEntity} />

          <Daterange defaultValue={dateRangeOutput} onDaterangeChange={actions.setGlobalDateRange} />
        </div>
      </PageHeader>

      <SkillsBreakdownWidget entity={entity} path={PagePath.analyticsSkills} />

      <SkillScoreOverTimeWidget entity={entity} />
    </div>
  );
}
