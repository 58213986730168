import React from 'react';
import { ISSOButtonProps } from './sso-button.models';
import styles from './sso-button.module.css';
import classNames from 'classnames';
import { getSSOIcon } from './sso-button.helpers';

export function SSOButton({ ssoType, onClick }: ISSOButtonProps): JSX.Element {
  return (
    <button className={classNames(ssoType, styles.ssoButton)} onClick={onClick}>
      <img src={getSSOIcon(ssoType)} alt={ssoType} />
    </button>
  );
}
