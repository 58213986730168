import React from 'react';
import { TickItem } from 'recharts/types/util/types';
import { DESKTOP_FONT_SIZE, MOBILE_FONT_SIZE } from '../../../../library/consts/recharts';

interface IPolarAngleAxisTickProps {
  x: number;
  y: number;
  payload: TickItem;
  radius: number;
  stroke: string;
  textAnchor: 'middle' | 'start' | 'end' | 'inherit' | 'initial' | 'unset';
  isMobile: boolean;
}

export function PolarAngleAxisTick(props: IPolarAngleAxisTickProps): JSX.Element {
  const { x, y, payload, radius, stroke, textAnchor, isMobile } = props;

  return (
    <g>
      <text
        fill='var(--text)'
        fontSize={isMobile ? MOBILE_FONT_SIZE : DESKTOP_FONT_SIZE}
        radius={radius}
        stroke={stroke}
        x={x}
        y={Math.abs(payload.coordinate - 90) <= 1 ? y - 25 : y}
        textAnchor={textAnchor}
      >
        {payload.value.split(' ').reduce((acc: string[], word: string) => {
          const lastLine = acc[acc.length - 1];
          if (lastLine && (lastLine.length + word.length) <= 15) {
            acc[acc.length - 1] = `${lastLine} ${word}`;
          } else {
            acc.push(word);
          }
          return acc;
        }, []).map((line: string, index: number) => (
          <tspan x={x} dy={`${index === 0 ? 0 : 1.2}rem`} key={index}>
            {line}
          </tspan>
        ))}
      </text>
    </g>
  );
}