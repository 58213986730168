import { DeveloperConsole } from '@keplerco/core';
import { track } from '../../library/helpers/segment';

export function apiURL(...paths: string[]): string {
  return url((process.env.REACT_APP_KEPLER_API_ENDPOINT as string).trim(), ...paths);
}

export function aiURL(...paths: string[]): string {
  return url((process.env.REACT_APP_KEPLER_AI_ENDPOINT as string).trim(), ...paths);
}

/**
 * @param paths A list of strings to join as a path
 * @returns A fully formed api URL path with the base URL and configs populated
 */
function url(BASE_ENDPOINT_URL: string, ...paths: string[]): string {
  const mappedPaths = paths.map(path => {
    if (!path) return '';
    let trimmedPath = path.trim();
    trimmedPath = trimmedPath.startsWith('/') ? trimmedPath.substring(1) : trimmedPath;
    trimmedPath = trimmedPath.endsWith('/') ? trimmedPath.substring(0, trimmedPath.length - 1) : trimmedPath;
    return trimmedPath;
  });

  const baseUrl = BASE_ENDPOINT_URL.endsWith('/') ? BASE_ENDPOINT_URL.substring(0, BASE_ENDPOINT_URL.length - 1) : BASE_ENDPOINT_URL;
  return `${baseUrl}/api/${mappedPaths.join('/')}`;
}

export function params(currentObject: {}): string {
  const urlSearchParams = new URLSearchParams();
  Object.entries(currentObject)
    .filter(entry => entry[1] !== undefined)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(value => urlSearchParams.append(key, value.toString()));
      } else {
        urlSearchParams.append(key, String(value));
      }
    });
  const urlSearchParamsString = urlSearchParams.toString();
  return !!urlSearchParamsString ? `?${urlSearchParamsString}` : '';
}

export class DefaultRequestParameters implements RequestInit {
  public headers = { 'Content-Type': 'application/json' };
  public method = 'GET';

  constructor(init?: RequestInit) {
    Object.assign(this, init);
  }

  public toObject(): RequestInit {
    return this as any;
  }
}

export class DefaultAuthenticatedParameters extends DefaultRequestParameters {
  public credentials = 'include';
}

export interface IRequest {
  url: string;

  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
  authenticated?: boolean;
  body?: any;
  headers?: any;
}

interface IBackendError {
  type: string;
  status: number;
  title?: string;
  detail?: string;
}

export interface IError extends IBackendError {
  requestError: 'API' | 'JSON' | 'OTHER';
  requestURL: string;
  requestMethod: 'GET' | 'POST' | 'PUT' | 'DELETE' | string;
}

export interface IResponse<T> {
  data?: T;
  error?: IError;
}

export async function request<T = any>(request: IRequest): Promise<IResponse<T>> {
  try {
    const {
      url,
      authenticated = true,

      method = 'GET',
      body = typeof request.body === 'object' ? JSON.stringify(request.body) : request.body,
      headers = { 'Content-Type': 'application/json' },
    } = request;

    const params = authenticated ? new DefaultAuthenticatedParameters({ method, body, headers }) : new DefaultRequestParameters({ method, body, headers });

    const response = await fetch(url, params.toObject());

    if (!response.ok || response.status === 500) {
      const apiError: IError = {
        requestError: 'API',
        requestURL: url,
        requestMethod: method,

        type: response.type ?? response.statusText,
        status: response.status,
        title: (response as any).title,
        detail: (response as any).detail,
      };

      try {
        const message = await response.json();
        apiError.title = message.title || 'Sorry, something went wrong';
        apiError.detail = message.detail;

        if (response.status === 401 && message.RedirectToLogin === true) {
          apiError.type = apiError.type || 'Unknown error';
          window.location.reload();
          throw apiError;
        }

        apiError.type = apiError.type || 'Unknown error';
      } catch (error) {
        apiError.type = apiError.type || `Unknown error`;
      }

      throw apiError;
    }

    if (response.headers.get('content-type') === null) return {};

    if (!response.headers.get('content-type')?.includes('json')) {
      try {
        const data = await response.text();
        return { data: data as T };
      } catch (error: any) {
        const otherError: IError = {
          requestError: 'OTHER',
          requestURL: url,
          requestMethod: method,

          type: 'Unexpected Error',
          status: response.status,
        };

        track('KPLR-ERROR-EVENT', otherError);
        new DeveloperConsole('OTHER', ['#E5145F', '#FFFFFF']).error(otherError);
        return { error: otherError };
      }
    }

    try {
      const data = await response.json();
      return { data };
    } catch (error: any) {
      const jsonError: IError = {
        requestError: 'JSON',
        requestURL: url,
        requestMethod: method,

        type: 'Unexpected end of JSON input',
        status: response.status,
      };
      track('KPLR-ERROR-EVENT', jsonError);
      new DeveloperConsole('JSON', ['#E5145F', '#FFFFFF']).error(jsonError);
      return { error: jsonError };
    }
  } catch (apiError: any) {
    if (!apiError.type) return { error: undefined };

    track('KPLR-ERROR-EVENT', apiError);
    new DeveloperConsole('API', ['#E5145F', '#FFFFFF']).error(apiError);
    return { error: apiError };
  }
}
