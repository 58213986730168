import React, { useState } from 'react';
import { useAppState } from '../../../../../../../overmind';
import { FetchStatus, FetchType } from '../../../../../../../enums';
import { UniqueEntity } from '../../../../../../../models';
import { IAssignEntitiesProps } from './assign-entities.models';
import { PagePath } from '../../../../../../../navigation/navigation.enums';
import { AddIcon, PillButton, XIcon } from '@keplerco/core';
import { FuzzySearch } from '../../../../../../../components/inputs/fuzzy-search/fuzzy-search';

export function AssignEntities(props: IAssignEntitiesProps): JSX.Element {
  const { fetchState } = useAppState();

  const [availableEntities, setAvailableEntities] = useState<UniqueEntity[]>();

  return (
    <div style={{ marginTop: 15 }}>
      <FuzzySearch
        boxedResults
        disableEmptyState
        fullWidthSearch={true}
        label={`Search for ${props.type}s`}
        loading={fetchState[PagePath.administrationPeople].status === FetchStatus.Active && fetchState[PagePath.administrationPeople].type === FetchType.Custom}
        errorMessage={props.error ? `Please select a ${props.type}` : undefined}
        onFuzzySearch={async searchParams => {
          if (!searchParams.query) return setAvailableEntities(undefined);
          const entities = await props.onFuzzySearch({ type: props.type, query: searchParams.query.toLowerCase() });
          setAvailableEntities(entities);
        }}
      >
        {!!availableEntities &&
          availableEntities.length > 0 &&
          availableEntities
            .filter(availableEntity => !props.assignedEntities.some(assignedEntity => assignedEntity.slug === availableEntity.slug))
            .map(availableEntity => <PillButton key={availableEntity.slug} label={availableEntity.name} icon={<AddIcon />} active backgroundColour="light" activeTextColour="black" animate={false} onClick={() => props.onClickAdd(availableEntity)} />)}
      </FuzzySearch>

      {props.assignedEntities.length > 0 && (
        <React.Fragment>
          <p className="body1" style={{ marginTop: 15, marginBottom: 15 }}>
            Assigned {props.type}s:
          </p>

          <div className="pillButtonsContainer">
            {props.assignedEntities.map(assignedEntity => (
              <PillButton key={assignedEntity.slug} label={assignedEntity.name} icon={<XIcon />} backgroundColour="baby-blue" animate={false} onClick={() => props.onClickRemove(assignedEntity)} />
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
