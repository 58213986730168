import React from 'react';
import classNames from 'classnames';
import { useAppState } from '../../overmind';
import { TopNavMenu } from './top-nav-menu/top-nav-menu';
import { themedAssetUrl } from '../../library/helpers/themed-asset-url';
import { PagePath } from '../navigation.enums';
import { useHasScrolled } from '@keplerco/core';
import styles from './top-nav.module.css';

export function TopNav(props: { isMobile: boolean; isTablet: boolean; onOpenSideNavDialog: () => void }): JSX.Element {
  const { isMobile, isTablet, onOpenSideNavDialog } = props;

  const { mayViewSideNav, sideNavVisible } = useAppState();

  const hasScrolled = useHasScrolled(5);

  return (
    <nav className={classNames(styles.nav, { [styles.scrolled]: hasScrolled })}>
      <a href={PagePath.root} className={styles.logoContainer}>
        {(isMobile || isTablet || !mayViewSideNav || !sideNavVisible) && <React.Fragment>{isMobile ? <img className={styles.logo} src={themedAssetUrl('logos/logo.icon.svg')} alt="Logo Icon" /> : <img className={styles.logo} src={themedAssetUrl('logos/logo.svg')} alt="Logo" />}</React.Fragment>}
      </a>

      <div className={styles.content}>
        <TopNavMenu isMobile={isMobile} isTablet={isTablet} onOpenSideNavDialog={onOpenSideNavDialog} />
      </div>

      <div id="hubspot-bot" />
    </nav>
  );
}
