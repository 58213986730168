import { Context } from '../..';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { RatingScale } from '../../../models/rating-scale';
import * as base from '../base';
import { CompanySearchResponse } from '../../../models/view/company-search-response';
import { Company } from '../../../models/view/company';
import { UniqueEntity } from '../../../models/view/unique-entity';
import { BaseSearchRequest } from '../../../models/overmind/search-request';
import { CompanyVariables, defaultCompanyVariables } from '../../../models/view/company-variables';
import { NotificationPriority, NotificationType } from '../../../notifications/notifications.models';
import { Country } from '../../../pages/onboarding/onboarding.models';

const CONTROLLER = `company`;

// TODO: apply error handling
export async function setSubDomain(): Promise<void> {
  const init = new base.DefaultAuthenticatedParameters({ method: 'GET' });
  try {
    const domainParts = window.location.host.split('.');
    if (domainParts.length >= 3) {
      const data = await fetch(base.apiURL(CONTROLLER, `get-subdomain`), init.toObject());
      const response = await data.text();

      let subDomainSplit = '-sg-';
      if (domainParts[0].indexOf('-qa-') !== -1) {
        subDomainSplit = '-qa-';
      }
      if (domainParts[0].indexOf('-dev-') !== -1) {
        subDomainSplit = '-dev-';
      }

      const subdomain = domainParts[0].split(subDomainSplit)[0];
      if (subdomain !== response) {
        window.location.assign(window.location.toString().replace(subdomain, response));
      }
    }
  } catch (ex) {
    console.log(ex);
  }
}

// CMS Endpoints
export async function searchCompanies(context: Context, SearchRequest: BaseSearchRequest): Promise<CompanySearchResponse | undefined> {
  const queryParams = new URLSearchParams(SearchRequest as any).toString();

  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `get-companies?${queryParams}`), authenticated: true };
  const response: base.IResponse<CompanySearchResponse> = await base.request(request);

  return response.data;
}

export async function getCountries(context: Context, payload?: string): Promise<Country[] | undefined> {
  const url = base.apiURL(CONTROLLER, `get-countries`);
  if (!!payload) url.concat(`?countryName=${payload}`);

  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<Country[]> = await base.request(request);

  return response.data;
}

export async function getIndustries(context: Context, industryName?: string): Promise<UniqueEntity[] | undefined> {
  const url = base.apiURL(CONTROLLER, `get-industries`);
  if (!!industryName) url.concat(`?industryName=${industryName}`);

  const request: base.IRequest = { url, authenticated: true };
  const response: base.IResponse<UniqueEntity[]> = await base.request(request);

  return response.data;
}

export async function getCompany(context: Context, companySlug: string): Promise<Company | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `get-company`, companySlug), authenticated: true };
  const response: base.IResponse<Company> = await base.request(request);

  return response.data;
}

export async function saveCompany(context: Context, company: Company): Promise<Company | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `save-company`), authenticated: true, method: 'POST', body: JSON.stringify(company) };
  const response: base.IResponse<Company> = await base.request(request);

  if (response.error) {
    context.actions.addNotification({
      active: true,
      id: crypto.randomUUID(),
      type: NotificationType.Error,
      priority: NotificationPriority.Toast,
      title: response.error.detail,
    });
  }

  return response.data;
}

export async function getCompanyRatingScales(): Promise<RatingScale | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `get-company-rating-scale`), authenticated: true };
  const response: base.IResponse<RatingScale> = await base.request(request);
  return response.data;
}

export async function getUserCompanyVariables(context: Context): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `variables`), authenticated: true, method: 'GET' };
  const response: base.IResponse<CompanyVariables> = await base.request(request);
  context.state.companyVariables = !!response.data
    ? {
      ...response.data,
      slug: context.state.companyVariables.slug,
    }
    : {
      ...defaultCompanyVariables,
      slug: context.state.companyVariables.slug,
    };
}

export async function getCompanyVariables(context: Context, slug: string): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `variables`, slug), authenticated: true, method: 'GET' };
  const response: base.IResponse<CompanyVariables> = await base.request(request);
  context.state.companyVariables = !!response.data
    ? {
      ...response.data,
      slug,
    }
    : {
      ...defaultCompanyVariables,
      slug,
    };
}

// refactored
export async function getAllowCreateAccount(): Promise<boolean | undefined> {
  const subdomain = getSubdomain();
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `get-allow-create-account`, subdomain) };
  const response: base.IResponse<boolean> = await base.request(request);
  return response.data;
}

export async function getSamlUrl(context: Context, payload: string): Promise<string | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `get-saml-url`, payload) };
  const response: base.IResponse<string> = await base.request(request);
  return response.data;
}