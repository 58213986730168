import { colourString, IconToneProps } from '@keplerco/core';
import React from 'react';

export function CalendarIcon({ size = 20, tone = 'accent-3' }: IconToneProps): JSX.Element {
  return (
    <svg fill="none" viewBox="0 0 19 21" width={size} height={size}>
      <path fill={colourString(tone)} d="M4.176 10.021h2v2h-2v-2ZM4.176 14.021h2v2h-2v-2ZM10.176 10.021h-2v2h2v-2ZM8.176 14.021h2v2h-2v-2ZM14.176 10.021h-2v2h2v-2ZM12.176 14.021h2v2h-2v-2Z" />
      <path fill={colourString(tone)} fillRule="evenodd" d="M16.176 20.021h-14c-1.103 0-2-.896-2-2v-14c0-1.102.897-2 2-2h2v-2h2v2h6v-2h2v2h2c1.103 0 2 .898 2 2v14c0 1.104-.897 2-2 2Zm0-14v-2h-14v2h14Zm0 2v10h-14v-10h14Z" clipRule="evenodd" />
    </svg>
  );
}
