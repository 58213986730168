import React from 'react';
import { NotificationPriority } from './notifications.models';
import { useAppState } from '../overmind';
import { ModalHub } from './modal-hub/modal-hub';
import { ToastHub } from './toast-hub/toast-hub';

export function Notifications(): JSX.Element {
  const { notifications } = useAppState();
  const allActive = notifications.filter(q => q.active);
  const toasts = allActive.filter(notification => notification.priority === NotificationPriority.Toast);
  const modal = allActive.find(notification => notification.priority === NotificationPriority.Modal);

  return (
    <div style={{ width: 0, height: 0, zIndex: 100 }}>
      {!!toasts.length && <ToastHub toasts={toasts} />}

      {!!modal && <ModalHub {...modal} />}
    </div>
  );
}
