import React, { Fragment } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Label, LabelProps, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { ThemeColours, useMatchScreenWidth } from '@keplerco/core';
import { useAppState } from '../../../../overmind';
import { fillColour, generateTicks } from './horizontal-bar-chart.helpers';
import { RechartsTooltip } from '../../../../components/charts/recharts-tooltip/recharts-tooltip';
import { RechartsLegend } from '../../../../components/charts/recharts-legend/recharts-legend';
import { DataPoint } from '../../../../components/charts/recharts.models';
import { IHorizontalBarChartProps } from './horizontal-bar-chart.models';
import { FONT_COLOUR, MOBILE_FONT_SIZE, DESKTOP_FONT_SIZE } from '../../../../library/consts/recharts';

function NotAssessedLabel(props: LabelProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');

  const { x, y, value, fill } = props;

  if (!!value || value === 0) {
    return <Fragment />;
  }

  const match = fill?.match(/--([a-zA-Z0-9_]+)/);
  const patternId = `hatch-${match && match[1]}`;

  return (
    <g>
      <svg>
        <defs>
          <pattern id={patternId} patternUnits="userSpaceOnUse" width="10" height="10">
            <path d="M0,0 L10,10" stroke={fill} strokeWidth="4 " />
          </pattern>
        </defs>

        <rect x={x} y={y} width={20} height={10} fill={`url(#${patternId})`} />
      </svg>

      <text x={x ?? 0 + 5} y={y} dy={10} dx={25} fill={FONT_COLOUR} fontSize={isMobile ? MOBILE_FONT_SIZE : DESKTOP_FONT_SIZE}>
        Not assessed
      </text>
    </g>
  );
}

function generateBars(dataPoints: DataPoint[], fill?: ThemeColours | ThemeColours[]): JSX.Element[] {
  return (
    dataPoints[0] &&
    Object.keys(dataPoints[0])
      .filter(key => key !== 'name')
      .map((key, index) => {
        return (
          <Bar
            key={`bar-${dataPoints[0].name}-${key}`}
            name={key}
            dataKey={key}
            type="monotone"
            minPointSize={5}
            fill={fillColour(index, fill)}
            animationDuration={1000}
            label={<NotAssessedLabel fill={fillColour(index, fill)} />}
            shape={<Rectangle radius={[0, 10, 10, 0]} height={10} />}
          >
            {dataPoints.map(dataPoint => (
              <Cell key={`cell-${dataPoint.name}`} />
            ))}
          </Bar>
        );
      })
  );
}

function MobileHorizontalBarChart({ dataPoints, fill, showLegend }: IHorizontalBarChartProps): JSX.Element {
  const { companyVariables } = useAppState();
  let height = 125;
  dataPoints.forEach(dataPoint => {
    const keys = Object.keys(dataPoint);
    if (keys.length > 2) {
      keys.forEach(() => (height += 20));
      return;
    }

    height += 40;
  });
  if (height < 200) height = 200;

  const bars = generateBars(dataPoints, fill);

  return (
    <ResponsiveContainer height={height} width="100%">
      <BarChart data={dataPoints} margin={{ top: 20, right: 10, left: 10, bottom: 20 }} layout="vertical" barCategoryGap={10} maxBarSize={10}>
        <CartesianGrid horizontal={false} syncWithTicks stroke="var(--default)" strokeWidth={0.3} strokeDasharray="3 3" />
        <XAxis
          type="number"
          stroke="var(--borders)"
          domain={companyVariables.useLevels ? [0, companyVariables.maxLevel] : [0, 100]}
          ticks={companyVariables.useLevels ? generateTicks(0, companyVariables.maxLevel) : generateTicks(0, 100)}
          strokeWidth={0.5}
          tickMargin={10}
          height={50}
          tick={{ fill: FONT_COLOUR, fontSize: MOBILE_FONT_SIZE }}
        >
          <Label value={companyVariables.useLevels ? 'Level' : 'Percentage'} position="insideBottom" offset={-10} fontSize={MOBILE_FONT_SIZE} fill={FONT_COLOUR} />
        </XAxis>
        <YAxis type="category" dataKey="name" width={80} tickLine={false} tickMargin={80} axisLine={false} tick={{ fill: FONT_COLOUR, fontSize: MOBILE_FONT_SIZE, textAnchor: 'left' }} />
        <Tooltip cursor={false} filterNull={false} content={<RechartsTooltip />} />
        {showLegend && <Legend layout="horizontal" verticalAlign="top" align="left" content={<RechartsLegend />} />}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}

function DesktopHorizontalBarChart({ dataPoints, fill, showLegend }: IHorizontalBarChartProps): JSX.Element {
  const { companyVariables } = useAppState();
  let height = 125;
  dataPoints.forEach(dataPoint => {
    const keys = Object.keys(dataPoint);
    if (keys.length > 2) {
      keys.forEach(() => (height += 20));
      return;
    }

    height += 40;
  });
  if (height < 200) height = 200;

  const bars = generateBars(dataPoints, fill);

  return (
    <ResponsiveContainer height={height} width="100%">
      <BarChart data={dataPoints} margin={{ top: 20, right: 20, left: 20, bottom: 20 }} layout="vertical" barCategoryGap={10} maxBarSize={10}>
        <CartesianGrid horizontal={false} syncWithTicks stroke="var(--default)" strokeWidth={0.3} strokeDasharray="3 3" />
        <XAxis
          type="number"
          stroke="var(--borders)"
          domain={companyVariables.useLevels ? [0, companyVariables.maxLevel] : [0, 100]}
          ticks={companyVariables.useLevels ? generateTicks(0, companyVariables.maxLevel) : generateTicks(0, 100)}
          strokeWidth={0.5}
          tickMargin={10}
          height={50}
          tick={{ fill: 'var(--text)', fontSize: DESKTOP_FONT_SIZE }}
        >
          <Label value={companyVariables.useLevels ? 'Level' : 'Percentage'} position="insideBottom" offset={-10} fontSize={DESKTOP_FONT_SIZE} fill={FONT_COLOUR} />
        </XAxis>
        <YAxis type="category" dataKey="name" width={150} tickLine={false} tickMargin={160} axisLine={false} tick={{ fill: FONT_COLOUR, fontSize: DESKTOP_FONT_SIZE, textAnchor: 'left' }} />
        <Tooltip cursor={false} filterNull={false} content={<RechartsTooltip />} />
        {showLegend && <Legend layout="horizontal" verticalAlign="top" align="left" content={<RechartsLegend />} />}
        {bars}
      </BarChart>
    </ResponsiveContainer>
  );
}

export function HorizontalBarChart(props: IHorizontalBarChartProps): JSX.Element {
  const isMobile = useMatchScreenWidth('mobile');
  return isMobile ? <MobileHorizontalBarChart {...props} /> : <DesktopHorizontalBarChart {...props} />;
}
