import { AuthenticationStatus } from '../enums/authentication-status';
import { KeplerState } from '../models/kepler-state';
import { derived } from 'overmind';
import { derivedDateRange } from './derived-props/date-range';
import { derivedMayViewSideNav } from './derived-props/may-view-side-nav';
import { FetchStatus, SyncStatus } from '../enums';
import { FetchStateItem } from '../models/fetch-state';
import { PagePath } from '../navigation/navigation.enums';
import { Mode } from '../navigation/navigation.enums';
import { defaultCompanyVariables } from '../models/view/company-variables';
import { LearningJourneyView } from '../enums/learning-journey-view';
import { DaterangeDateOptionTypes } from '../components/inputs/daterange/daterange.models';

export function generateInitialFetchState() {
  return Object.values(PagePath).reduce((previousValue, currentValue) => {
    return { ...previousValue, [currentValue]: { status: FetchStatus.Inactive } };
  }, {} as { [path in PagePath]: FetchStateItem });
}

export const state: KeplerState = {
  // init
  isInitialising: true,

  // auth
  authenticationStatus: AuthenticationStatus.Unknown,

  // side-nav
  mayViewSideNav: derived(derivedMayViewSideNav),
  sideNavCollapsed: false,
  sideNavVisible: true,

  // sync
  courseSyncStatus: SyncStatus.NotRun,
  lastSynced: undefined,

  // user
  user: undefined,
  permissions: undefined,
  mode: Mode.LearningDashboard,
  learningJourneyView: LearningJourneyView.List,
  assignedCourses: [],
  assessmentYearCourses: [],

  // company
  companyVariables: defaultCompanyVariables,
  skillLevelConfig: [],

  // system
  fetchState: generateInitialFetchState(),
  notifications: [],
  skillAssessmentConfig: undefined,
  dateRange: derived(derivedDateRange),
  dateRangeOutput: { type: DaterangeDateOptionTypes.UpToToday },

  // TODO: remove
  passwordErrors: undefined,
};
