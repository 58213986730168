import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmptyState } from '../../../components/general/empty-state/empty-state';
import { FetchStatus, FetchType } from '../../../enums';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import { LearnerCertificateSearchResponse } from '../../../models/view/learner-certificate-search-response';
import { useAppActions, useAppState } from '../../../overmind';
import { PagePath } from '../../../navigation/navigation.enums';
import { PageLoaderLayer } from '../../../components/general/loading-state/loader-layers/page-loader-layer/page-loader-layer';
import { Anchor, Searchfield, colourString, kebabCase, PageHeader } from '@keplerco/core';
import { useDataSyncReaction } from '../../../library/hooks/useDataSyncReaction';
import layoutStyles from '../../../design/layout.module.css';
import styles from './certificates.module.css';
import classNames from 'classnames';

export function CertificatesPage(): JSX.Element {
  const navigate = useNavigate();

  const { fetchState } = useAppState();
  const actions = useAppActions();

  const [response, setResponse] = useState<LearnerCertificateSearchResponse>();

  async function getData(query: string) {
    const result = await actions.getLearnerCertificateList({ page: 1, pageSize: 9999, sortAscending: true, search: query });
    setResponse(result);

    actions.stopLoader(PagePath.learningJourneyCertificates);
  }

  useDataSyncReaction(async () => {
    getData('');
  });

  useEffect(() => {
    actions.startLoader({ path: PagePath.learningJourneyCertificates, type: FetchType.PageFetching });
    getData('');
  }, []);

  return (
    <PageLoaderLayer path={PagePath.learningJourneyCertificates}>
      <div className="wrapper" style={{ display: 'block' }}>
        <PageHeader>
          <PageHeader
            breadcrumbs={[
              {
                name: 'Learning Journey',
                url: PagePath.learningJourneyBase,
              },
              {
                name: 'Certificates',
                url: window.location.pathname,
              },
            ]}
            title={'Download Certificates'}
            divider
          />
        </PageHeader>

        <React.Fragment>
          <div className={layoutStyles.filtersGroup} style={{ marginTop: 30 }}>
            <Searchfield
              loading={fetchState[PagePath.learningJourneyCertificates].status === FetchStatus.Active && fetchState[PagePath.learningJourneyCertificates].type === FetchType.Custom}
              onInput={query => {
                actions.startLoader({ path: PagePath.learningJourneyCertificates, type: FetchType.Custom });
                getData(query);
              }}
            />
          </div>

          {!!response?.learnerCertificatesListView?.length ? (
            <div className={styles.cards}>
              {response.learnerCertificatesListView.map(card => {
                return (
                  <div className="card arrow" key={card.name}>
                    <div className={styles.cardLayout}>
                      <span className={classNames('caption1', styles.title)}>
                        <b>{card.name}</b>
                      </span>

                      <span className={classNames('caption1', styles.date)} style={{ color: colourString('accent-2') }}>
                        {new Date(card.dateCompleted).toDateString()}
                      </span>

                      <Anchor className={styles.action} href={card.url} block target={'_blank'} rel="noopener noreferrer" download={`${kebabCase(card.name)}.pdf`}>
                        Download
                      </Anchor>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div style={{ padding: '5vmin 0' }}>
              <EmptyState
                badgeIconWidth={450}
                badgeUrl={themedAssetUrl('graphics/empty-state-cow.graphic.svg')}
                title="You don't have any certificates yet"
                subtitle="Certificates are awarded when you complete lessons in your learning journey"
                buttons={[
                  {
                    text: 'View Learning Journey',
                    onClick: () => navigate(PagePath.learningJourneyBase),
                  },
                ]}
              />
            </div>
          )}
        </React.Fragment>
      </div>
    </PageLoaderLayer>
  );
}
