import React from 'react';
import styles from './skeleton-loader.module.css';

export function SkeletonLoader(props: { height?: string; width?: string; borderRadius?: string }): JSX.Element {
  const { height = '50px', width = '100%', borderRadius = '20px' } = props;

  function validateSize(size: string) {
    return /^[0-9]*$/.test(size) ? `${size}px` : size;
  }

  return (
    <div style={{ height: validateSize(height), width: validateSize(width) }}>
      <div className={styles.loader} style={{ borderRadius }} />
    </div>
  );
}
