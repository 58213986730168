import React from 'react';
import { SkeletonLoader } from '../../loaders/skeleton-loader/skeleton-loader';

export function PageSkeletonLoader(): JSX.Element {
  return (
    <div className="skeleton-loader-container">
      <SkeletonLoader height="130px" />

      <SkeletonLoader height="calc(100vh - 290px)" />
    </div>
  );
}
