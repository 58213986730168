import { Context } from '../..';
import { CompanySkillListItemResponse } from '../../../models/overmind/company-entity';
import * as base from '../base';
import { AIGenerateRoleRequest } from './ai-roles';

const CONTROLLER = 'skills';

export async function aiGenerateSkills(context: Context, payload: AIGenerateRoleRequest): Promise<CompanySkillListItemResponse[] | undefined> {
  const url: string = base.aiURL(CONTROLLER);
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify({ ...payload, skillLevelMappings: context.state.skillLevelConfig }) };
  const response: base.IResponse<CompanySkillListItemResponse[]> = await base.request(request);
  return response.data;
}

interface AIGenerateProficienciesRequest {
  role: AIGenerateRoleRequest;
  skills: CompanySkillListItemResponse[];
}

export async function aiGenerateProficiencies(context: Context, payload: AIGenerateProficienciesRequest): Promise<CompanySkillListItemResponse[] | undefined> {
  const url: string = base.aiURL(CONTROLLER, 'suggest-proficiency');
  const request: base.IRequest = { url, authenticated: true, method: 'POST', body: JSON.stringify({ ...payload, skillLevelMappings: context.state.skillLevelConfig }) };
  const response: base.IResponse<CompanySkillListItemResponse[]> = await base.request(request);
  return response.data;
}
