import { Context } from '../..';
import * as base from '../base';
import { LearnerSkillAssessment, SkillAssessmentConfig } from '../../../models/skill-assessment-config';
import { AssessmentsSearchRequest } from '../../../models/overmind/search-request';

const CONTROLLER = `learnerassessment`;

export async function getForcedSkillAssessmentConfiguration(context: Context): Promise<void> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `forced`) };
  const response: base.IResponse<SkillAssessmentConfig> = await base.request(request);

  context.state.skillAssessmentConfig = response.data;
}

export async function getSkillAssessmentConfiguration(context: Context, payload: string): Promise<SkillAssessmentConfig | undefined> {
  const request: base.IRequest = { url: base.apiURL(CONTROLLER, `${payload}`) };
  const response: base.IResponse<SkillAssessmentConfig> = await base.request(request);

  context.state.skillAssessmentConfig = response.data;
  return response.data;
}

export async function getLearnerAssessments(_: Context, payload: AssessmentsSearchRequest): Promise<LearnerSkillAssessment[] | undefined> {
  const params = base.params(payload);
  const url = base.apiURL(`${CONTROLLER}${params}`);
  const response: base.IResponse<LearnerSkillAssessment[]> = await base.request({ url });
  return response.data;
}

export function clearSkillAssessmentConfig({ state }: Context) {
  state.skillAssessmentConfig = undefined;
}
