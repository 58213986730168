import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppActions } from '../../../overmind';
import { useNavigate } from 'react-router-dom';
import { SSOButton } from '../sso-button/sso-button';
import { AccountPageLoaderLayer } from '../../../components/general/loading-state/loader-layers/account-page-loader-layer/account-page-loader-layer';
import { UserRegistration } from '../../../models/user';
import { FetchType } from '../../../enums';
import { getSubdomain } from '../../../library/helpers/get-subdomain';
import { PagePath } from '../../../navigation/navigation.enums';
import WeglotLanguageSwitcher from '../../../components/general/weglot/weglot-switcher';
import { Button, ConfirmationPasswordValidator, EmailValidator, FormControl, RequiredValidator, Textfield, useMatchScreenWidth } from '@keplerco/core';
import { PasswordStrength } from '../password-strength/password-strength';
import accountStyles from '../account.module.css';
import { themedAssetUrl } from '../../../library/helpers/themed-asset-url';
import classNames from 'classnames';
import { TertiaryButton } from '../../../components/general/tertiary-button/tertiary-button';

export function RegisterPage(): JSX.Element {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);

  const actions = useAppActions();

  const { control, handleSubmit, getValues, watch } = useForm<any>({ mode: 'onBlur', reValidateMode: 'onChange' });

  const isMobile = useMatchScreenWidth('mobile');

  const [fieldValues, setFieldValues] = useState<{ [key: string]: any }>();
  const [samlUrl, setSamlUrl] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>();

  watch(value => setFieldValues(value));

  useEffect(() => {
    async function getData() {
      const allowCreateAccount = await actions.getAllowCreateAccount();
      if (!allowCreateAccount) navigate(`${PagePath.accountBase}${PagePath.accountLogin}`);
      setSamlUrl(await actions.getSamlUrl(getSubdomain()));
    }

    getData();
  }, []);

  return (
    <>
      <div className={accountStyles.accountBackground} style={{ backgroundImage: `url(${themedAssetUrl('backgrounds/pattern.background.svg')})` }}>
        <div className={accountStyles.accountPage}>
          <div className={classNames('card', accountStyles.accountCard)}>
            <AccountPageLoaderLayer path={PagePath.accountRegister}>
              {!isMobile && (
                <div className={accountStyles.accountNavigationToggler}>
                  <span className="caption1">Already have an account?</span>
                  {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                  <TertiaryButton type="button" onClick={() => navigate(`${PagePath.accountBase}${PagePath.accountLogin}`)}>
                    Sign in
                  </TertiaryButton>
                </div>
              )}

              <h1 className="heading1">Create an account</h1>

              <form
                id="register"
                className={accountStyles.accountForm}
                autoComplete="off"
                onSubmit={handleSubmit(async (value: UserRegistration) => {
                  actions.startLoader({ path: PagePath.accountRegister, type: FetchType.PageFetching });

                  const result = await actions.registerUser(value);

                  if (result && result.message === 'An email has been sent to your address to confirm your account.') {
                    navigate(`${PagePath.accountBase}${PagePath.accountVerifyEmail}?email=${value.email}`);
                  } else setErrorMessage(result?.message);

                  actions.stopLoader(PagePath.accountRegister);
                })}
              >
                {!!errorMessage && (
                  <div className="field">
                    <div style={{ padding: '0 0 20px 0' }}>
                      <div className="formErrorMessage">{errorMessage}</div>
                    </div>
                  </div>
                )}

                <FormControl paddingBottom={0} control={control} name="email" rules={new EmailValidator('Enter your email')} render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Email" responsive />} />

                <FormControl
                  paddingBottom={0}
                  control={control}
                  name="password"
                  rules={new RequiredValidator('Enter your password')}
                  render={({ field, fieldState }) => (
                    <Fragment>
                      <Textfield {...field} haserror={!!fieldState.error} label="Password" type="password" responsive />

                      {!!fieldValues?.password && (
                        <PasswordStrength
                          password={fieldValues.password}
                          onPercentage={percent => {
                            if (percent < 100) return;
                            actions.clearPasswordErrors();
                          }}
                        />
                      )}
                    </Fragment>
                  )}
                />

                <FormControl
                  paddingBottom={0}
                  control={control}
                  name="confirmPassword"
                  rules={new ConfirmationPasswordValidator('Enter your confirmation password', getValues('password'))}
                  render={({ field, fieldState }) => <Textfield {...field} haserror={!!fieldState.error} label="Confirm Password" type="password" responsive />}
                />

                <span className="caption1" style={{ textAlign: 'start' }}>
                  By signing up, you agree to our {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                  <TertiaryButton type="button" onClick={() => window.open('https://legal.keplerco.io/documents/terms-of-use/', '_blank')} style={{ height: 'fit-content' }}>
                    terms of service
                  </TertiaryButton>{' '}
                  and {/* TODO: replace with Anchor once it's refactored to have NavLink functionality as well */}
                  <TertiaryButton type="button" onClick={() => window.open('https://legal.keplerco.io/documents/privacy-policy/', '_blank')} style={{ height: 'fit-content' }}>
                    privacy policy
                  </TertiaryButton>
                </span>

                <Button chubby filled arrow={false} type="button">
                  Register
                </Button>
              </form>

              <div className={accountStyles.accountDivider}>Or register using</div>

              {!!samlUrl && <SSOButton ssoType="saml" onClick={async () => (window.location.href = `${samlUrl}?returnUrl=${params.get('kt')},,,${params.get('code')}`)} />}

              <SSOButton ssoType="google" onClick={() => actions.externalLogin({ provider: 'Google', kt: params.get('kt'), code: params.get('code') })} />

              <SSOButton ssoType="microsoft" onClick={() => actions.externalLogin({ provider: 'Microsoft', kt: params.get('kt'), code: params.get('code') })} />

              <SSOButton ssoType="linkedin" onClick={() => actions.externalLogin({ provider: 'LinkedIn', kt: params.get('kt'), code: params.get('code') })} />
            </AccountPageLoaderLayer>
          </div>
        </div>
      </div>

      <WeglotLanguageSwitcher position="bottomLeft" />
    </>
  );
}
