import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PagePath } from '../navigation.enums';
import { OrganizationLevelType } from '../../enums';
import { PermissionType } from '../../enums/permission-type';
import { PageGuard } from '../guards/page.guard';
import { ActiveCompanySlugRedirect } from './routers.helpers';
import { useCompanySlugParamChange } from '../../library/hooks/useCompanySlugParamChange';
import { GlobalRolePage } from '../../pages/administration/roles/view-role/global-role.page';
import { CompanyRolePage } from '../../pages/administration/roles/view-role/company-role.page';
import { CreateRolePage } from '../../pages/administration/roles/manage-role/create-role.page';
import { EditRolePage } from '../../pages/administration/roles/manage-role/edit-role.page';
import { ImportRolePage } from '../../pages/administration/roles/manage-role/import-role.page';
import { AllRoles } from '../../pages/role-skill-management/roles/all-roles.page';
import { RolesWizardPage } from '../../pages/role-skill-management/roles/wizard/roles-wizard.page';
import { CustomRoleStepperPage } from '../../pages/role-skill-management/roles/wizard/custom-role/custom-role-stepper.page';
import { ImportRoleStepperPage } from '../../pages/role-skill-management/roles/wizard/import-role/import-role-stepper.page';
import { KeplerAIStepperPage } from '../../pages/role-skill-management/roles/wizard/kepler-ai-role/kepler-ai-stepper.page';

export function RoleSkillManagementRouter(): JSX.Element {
  useCompanySlugParamChange(PagePath.roleSkillManagementBase);

  return (
    <Routes>
      <Route
        path={PagePath.roleSkillManagementAllRoles}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <AllRoles />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementWizard}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <RolesWizardPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementWizardKeplerAI}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <KeplerAIStepperPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementWizardCustomRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <CustomRoleStepperPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementWizardImportRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <ImportRoleStepperPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementWizardUpdate}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <CustomRoleStepperPage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementCreateRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <CreateRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementEditRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <EditRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementImportRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <ImportRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementCompanyRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <CompanyRolePage />
          </PageGuard>
        }
      />
      <Route
        path={PagePath.roleSkillManagementGlobalRole}
        element={
          <PageGuard
            organizationLevelTypesRequired={{
              permissionType: PermissionType.RoleManagement,
              organizationLevels: [{ organizationLevelType: OrganizationLevelType.Company, multipleEntities: false }],
            }}
          >
            <GlobalRolePage />
          </PageGuard>
        }
      />

      <Route path={PagePath.rootWildcard} element={<ActiveCompanySlugRedirect landingRedirectTo={`${PagePath.roleSkillManagementBase}${PagePath.roleSkillManagementAllRoles}`} />} />
    </Routes>
  );
}
