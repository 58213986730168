import React, { useEffect, useState } from 'react';
import { SearchList } from '../../../../components/lists/search-list';
import { ListItem } from '../../../../components/lists/list-item';
import { useAppActions, useAppState } from '../../../../overmind';
import { SortField, OrganizationLevelType } from '../../../../enums';
import { extractHighestOrganizationLevel } from '../../../../library/helpers/permissions/extract-highest-organization-level';
import { IRoleComparisonListProps, Role } from './role-comparison.models';
import { PagingSearchParams, SearchingSearchParams } from '../../../../models/overmind/search-params';
import { useMatchScreenWidth } from '@keplerco/core';

export function RoleComparisonList({ pendingRoleSlug, setPendingRole }: IRoleComparisonListProps): JSX.Element {
  const actions = useAppActions();
  const { companyVariables, permissions } = useAppState();

  const isMobile = useMatchScreenWidth('mobile');

  const organizationLevel = extractHighestOrganizationLevel(permissions?.roleManagement.organizationLevels);

  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchingSearchParams & PagingSearchParams>({
    search: undefined,
    page: 1,
    pageSize: 5,
  });
  const [roles, setRoles] = useState<Role[]>();
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    async function getData() {
      setLoading(true);

      const response = await actions.getCompanyRoles({
        ...searchParams,
        sortAscending: true,
        sortField: SortField.Name,
        departmentSlug: undefined,
        learnerSlug: undefined,
        teamSlug: undefined,
        organizationLevel: organizationLevel?.organizationLevel ?? OrganizationLevelType.Learner,
        companySlug: companyVariables.slug,
        searchGlobally: false,
      });
      setRoles(
        response?.entities.map(role => ({
          name: role.name,
          slug: role.slug!,
          description: role.description,
        }))
      );
      setTotalPages(response?.totalPages ?? 0);

      setLoading(false);
    }

    getData();
  }, [searchParams]);

  return (
    <div className="card">
      <SearchList
        loading={loading}
        onInput={search => setSearchParams(currentState => ({ ...currentState, search }))}
        paging={{
          activePageNumber: searchParams.page ?? 1,
          pageCount: totalPages,
          onPageChange: page => setSearchParams(currentState => ({ ...currentState, page })),
        }}
      >
        {roles?.map(role => {
          const selected = role.slug === pendingRoleSlug;
          return (
            <ListItem key={role.slug} selected={selected} onClick={() => setPendingRole(selected ? undefined : role)}>
              <div style={{ display: 'grid', gap: 15, alignItems: 'center', gridTemplateColumns: isMobile ? '1fr' : '1fr 3fr' }}>
                <div className="caption1" style={{ color: 'var(--accent-2)' }}>
                  {role.name}
                </div>
                <div className="caption2">{role.description ?? 'No description provided'}</div>
              </div>
            </ListItem>
          );
        })}
      </SearchList>
    </div>
  );
}
